import { render, staticRenderFns } from "./PartnerWillAddOn.vue?vue&type=template&id=200c85b2"
import script from "./PartnerWillAddOn.vue?vue&type=script&lang=js"
export * from "./PartnerWillAddOn.vue?vue&type=script&lang=js"
import style0 from "./PartnerWillAddOn.vue?vue&type=style&index=0&id=200c85b2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppWillProductAddOn: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/ProductAddOn.vue').default,AppImage: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Image.vue').default,RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppBox: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Box.vue').default,AppAccordionPanel: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/AccordionPanel.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Modal.vue').default})
