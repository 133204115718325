
import { mapActions } from 'vuex';
import UPDATE_WILL_NOTIFY_CHARITY_MUTATION from '@/graphql/mutations/UpdateNotifyCharityOfWill';
import { will } from '@/mixins/apollo';

export default {
  name: 'AppWillCharitiesConsentModal',
  mixins: [will],
  data() {
    return {
      options: [
        {
          label: this.$t('components.charitiesConsentModal.no'),
          value: false,
          level: 'tertiary',
          theme: 'light',
        },
        {
          label: this.$t('components.charitiesConsentModal.yes'),
          value: true,
          level: 'secondary',
          theme: 'wills',
        },
      ],
      notify_charities: true,
      isDirty: false,
    };
  },
  computed: {
    showOptions() {
      return !this.isDirty || this.notify_charities === true;
    },
  },
  mounted() {
    this.$nuxt.$emit('sendTrackingEvent', {
      event: 'confirmation_prompt',
      props: {
        topic: 'Share contact details with charities',
      },
    });
  },
  methods: {
    ...mapActions('directory-person', ['updatePartnershipConsent']),
    async savePreference(preference) {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: preference ? 'accept_prompt' : 'reject_prompt',
        props: {
          topic: 'Share contact details with charities',
        },
      });

      await this.$apollo.mutate({
        mutation: UPDATE_WILL_NOTIFY_CHARITY_MUTATION,
        variables: {
          userId: this.userId,
          value: preference,
        },
      });
      await this.updatePartnershipConsent(preference);
      await this.refetchWill();
      await this.$updateShouldShowCharityConsent();
    },
    optionSelected(preference) {
      this.notify_charities = preference;
      this.isDirty = true;
      if (this.notify_charities === true) {
        this.savePreference(preference);
      }
    },
    reset() {
      this.isDirty = false;
      this.notify_charities = true;
    },
  },
};
