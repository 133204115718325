import { render, staticRenderFns } from "./Charity.vue?vue&type=template&id=2d34143a"
import script from "./Charity.vue?vue&type=script&lang=js"
export * from "./Charity.vue?vue&type=script&lang=js"
import style0 from "./Charity.vue?vue&type=style&index=0&id=2d34143a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppTooltip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Tooltip.vue').default,RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default})
