
export default {
  name: 'AppFlagCheckbox',
  model: {
    event: 'change',
  },
  props: {
    willId: {
      type: String,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    value: {
      required: true,
      type: [String, Boolean],
    },
  },
  data() {
    return {
      open: false,
      flagged: this.value,
      reasons: this.$t('components.flagCheckbox.reasons'),
    };
  },
  computed: {
    classes() {
      return {
        checkbox: {
          'border-red-300': this.flagged,
        },
        icon: {
          'text-red-300 group-hover:text-red-300': this.flagged,
        },
        label: {
          'border-red-300 hover:border-red-300 bg-red-100': this.flagged,
          'cursor-not-allowed opacity-50': this.disabled,
          'group cursor-pointer': !this.disabled,
        },
      };
    },
  },
  watch: {
    value(newValue) {
      this.flagged = newValue;
    },
  },
  methods: {
    handleChange(reason) {
      this.$emit('change', reason);
    },
  },
};
