
export default {
  name: 'AppSignature',
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    signed: {
      default: false,
      type: Boolean,
    },
    label: {
      default: null,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    small: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    sizeStyle() {
      if (this.small) {
        return '0.66';
      }
      return null;
    },
    cursorStyle() {
      if (this.disabled) {
        return 'not-allowed';
      }
      return null;
    },
    styles() {
      return {
        '--cursor': this.cursorStyle,
        '--size-modifier': this.sizeStyle,
        '--is-signed': this.signed ? 1 : 0,
        '--is-disabled': this.disabled ? 1 : 0,
      };
    },
  },
  methods: {
    click() {
      if (!this.disabled) {
        this.$emit('input', !this.signed);
      }
    },
  },
};
