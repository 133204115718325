
import { mapActions } from 'vuex';
export default {
  name: 'PagesAffiliateCheckout',
  layout: 'ruach',
  mounted() {
    this.setNavigationVariant('none');
  },
  beforeDestroy() {
    this.resetUI();
  },
  methods: {
    ...mapActions('ruach', ['setNavigationVariant', 'resetUI']),
  },
};
