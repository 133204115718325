
export default {
  name: 'AppTabTitle',
  props: {
    isSelected: {
      required: true,
      type: Boolean,
    },
    variant: {
      default: 'pill',
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      default: null,
      type: [String, Number],
    },
  },
  computed: {
    isSelectedClass() {
      return {
        '--selected': this.isSelected,
      };
    },
  },
  methods: {
    selectTab({ target }) {
      target.blur();
      this.$emit('click');
    },
  },
};
