
import { mapGetters } from 'vuex';
import {
  isAdminOrHigher,
  isPartnershipsAdminOrHigher,
  isSuperAdmin,
} from '@/utilities';

export default {
  name: 'AppSnowflakesNavigationAdmin',
  computed: {
    ...mapGetters(['email', 'role']),
    isAdminOrHigher() {
      return isAdminOrHigher(this.role);
    },
    isPartnershipsAdminOrHigher() {
      return isPartnershipsAdminOrHigher(this.role);
    },
    isSuperAdmin() {
      return isSuperAdmin(this.role);
    },
    splitEmail() {
      return this.email.replaceAll('@', ' @​');
    },
  },
};
