
export default {
  name: 'AppTooltip',
  props: {
    content: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      default: null,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    placement: {
      default: null,
      type: String,
    },
  },
  computed: {
    tooltipOptions() {
      return {
        value: this.tooltipContent,
        disabled: this.disabled,
      };
    },
    tooltipContent() {
      let html = '';
      if (this.header) {
        html += `${this.header}\n`;
      }
      html += this.content;
      return html;
    },
  },
};
