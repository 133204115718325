
import { mapGetters, mapActions } from 'vuex';

import CREATE_POA from '@/graphql/mutations/CreatePoa';
import { error, user } from '@/mixins/apollo';
import { formatPrice } from '@/utilities';

export default {
  name: 'PagesAffiliateOnboardingPoaIndex',
  mixins: [user],
  layout: 'ruach',
  middleware: ['redirect-if-poa'],
  data() {
    return {
      wishes: [
        {
          iconId: 'wallet',
          title: this.$t('pages.onboarding.poa.wishes.financialDecisions'),
          description: this.$t(
            'pages.onboarding.poa.wishes.financialDecisionsDesc'
          ),
        },
        {
          iconId: 'legal',
          title: this.$t('pages.onboarding.poa.wishes.legalAffairs'),
          description: this.$t('pages.onboarding.poa.wishes.legalAffairsDesc'),
        },
        {
          iconId: 'medical',
          title: this.$t('pages.onboarding.poa.wishes.medicalTreatment'),
          description: this.$t(
            'pages.onboarding.poa.wishes.medicalTreatmentDesc'
          ),
        },
        {
          iconId: 'home',
          title: this.$t('pages.onboarding.poa.wishes.lifestyleDecisions'),
          description: this.$t(
            'pages.onboarding.poa.wishes.lifestyleDecisionsDesc'
          ),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('directory-person', [
      'userDetails',
      'userIsAustralian',
      'userState',
    ]),
    ...mapGetters('account-interests', ['hasPoaInterest']),
    ...mapGetters('product-prices', ['poaPrice']),
    price() {
      return this.formatPrice(this.poaPrice);
    },
  },
  mounted() {
    this.getAccountInterests(this.userId);
  },
  methods: {
    ...mapActions('account-interests', [
      'getAccountInterests',
      'createInterest',
    ]),
    ...mapActions('poa', ['setPoaId', 'getStatus']),
    ...mapActions('cart', ['addToCart']),
    formatPrice,
    createPoaInterests() {
      this.createInterest({
        userId: this.userId,
        country: this.userDetails.residentialAddress.country,
        region: this.userIsAustralian
          ? this.userDetails.residentialAddress.region
          : null,
        product: 'POA',
      });
    },
    async createPoa() {
      const {
        data: { createPoa: poa },
      } = await this.$apollo.mutate({
        mutation: CREATE_POA,
        variables: {
          userId: this.userId,
        },
        error,
      });
      await this.setPoaId(poa.id);
      await this.getStatus();
      await this.addToCart({ codes: ['POA'], showSnackbar: false });
      await this.$router.push(this.localePath('/poa'));
    },
  },
};
