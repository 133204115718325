
import VDismiss from 'vue-dismiss';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AppFlagsDropdown',
  mixins: [VDismiss],
  props: {
    flagsAreDisabled: {
      default: false,
      type: Boolean,
    },
    module: {
      required: true,
      type: String,
    },
    willId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters('flag', ['flags', 'planFlags']),
    moduleFlags() {
      const moduleFlags = this.flags.filter(
        ({ planSegments }) =>
          !planSegments ||
          planSegments.some(({ module }) => module === this.module)
      );

      return moduleFlags.sort((a, b) =>
        a.internalName.localeCompare(b.internalName)
      );
    },
  },
  mounted() {},
  methods: {
    ...mapActions('flag', ['createPlanFlag']),
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    addFlag(flag) {
      this.createPlanFlag({
        flagId: flag.id,
        planId: this.willId,
        segment: {
          module: this.module,
        },
      });
      this.isOpen = false;
    },
  },
};
