
export default {
  name: 'AppModuleCard',
  props: {
    description: {
      type: String,
      required: true,
    },
    duration: {
      type: Array,
      required: true,
    },
    flagged: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    number: {
      type: Number,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    ready: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    customButtonText: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      circleRadius: 16,
    };
  },
  computed: {
    dashOffset() {
      return this.circleDiameter - this.progress * this.circleDiameter;
    },
    buttonText() {
      if (this.customButtonText) {
        return this.customButtonText;
      } else if (this.unstarted) {
        return this.$t('components.moduleCard.start');
      } else if (this.incomplete) {
        return this.$t('components.moduleCard.continue');
      } else if (this.complete && this.status === 'IN_PROGRESS') {
        return this.$t('components.moduleCard.edit');
      } else if (
        this.complete &&
        ['FLAGGED', 'APPROVED'].includes(this.status)
      ) {
        return this.$t('components.moduleCard.edit');
      }
      return this.$t('components.moduleCard.continue');
    },
    unstarted() {
      return this.progress === 0;
    },
    incomplete() {
      return this.progress > 0 && this.progress < 1;
    },
    complete() {
      return this.progress === 1;
    },
    locked() {
      return this.complete && ['FLAGGED', 'APPROVED'].includes(this.status);
    },
    tooltip() {
      if (this.flagged) {
        return this.$t('components.moduleCard.tooltip.flaggedWithIssues');
      }

      switch (this.status) {
        case 'IN_PROGRESS':
          if (this.complete) {
            return this.$t('components.moduleCard.tooltip.inProgress');
          }
          return this.$t('components.moduleCard.tooltip.default');
        case 'NOT_STARTED':
          return this.$t('components.moduleCard.tooltip.notStarted');
        case 'AWAITING_APPROVAL':
          return this.$t('components.moduleCard.tooltip.awaitingApproval');
        case 'APPROVED':
          return this.$t('components.moduleCard.tooltip.approved');
        default:
          return this.$t('components.moduleCard.tooltip.default');
      }
    },
  },
  created() {
    this.circleDiameter = 2 * Math.PI * this.circleRadius;
  },
};
