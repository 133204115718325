
export default {
  name: 'AppLoadingRing',
  computed: {
    dimensions() {
      switch (this.size) {
        case 'huge':
          return 40;
        case 'large':
          return 32;
        case 'small':
          return 20;
        case 'tiny':
          return 12;
        default:
          return 24;
      }
    },
  },
};
