
export default {
  name: 'AppLoading',
  props: {
    type: {
      default: 'ring',
      type: String,
    },
    theme: {
      default: '',
      type: String,
    },
  },
  computed: {
    width() {
      switch (this.type) {
        case 'dots':
          return 64;
        default:
          return 24;
      }
    },
  },
};
