
import kebabCase from 'lodash/kebabCase';
import { mapActions, mapGetters } from 'vuex';
import { error, poa, user, will } from '@/mixins/apollo';
import SUBMIT_POA from '@/graphql/queries/SubmitPoa';
import GET_POA_STATUS from '@/graphql/queries/GetPoaStatus';
import VERSION_POA from '@/graphql/queries/VersionPoa';

export default {
  name: 'PagesAffiliatePoaIndex',
  mixins: [poa, user, will],
  apollo: {
    poaStatus: {
      query: GET_POA_STATUS,
      fetchPolicy: 'network-only',
      update: (data) => {
        return data.poa !== null ? data.poa.status : null;
      },
      variables() {
        return { id: this.poaId };
      },
      result({ data }) {
        if (data?.poa?.status === 'AWAITING_APPROVAL') {
          if (!this.poaStatusPollTimer) {
            this.$apollo.queries.poaStatus.startPolling(2000);
            this.poaStatusPollTimer = setTimeout(() => {
              this.$apollo.queries.poaStatus.stopPolling();
              this.displayErrorMessage();
            }, 30000);
          }
        } else {
          clearTimeout(this.poaStatusPollTimer);
          this.poaStatusPollTimer = null;
          this.$apollo.queries.poaStatus.stopPolling();
        }
      },
      error,
    },
  },
  data() {
    return {
      poaStatus: null,
      submittingPoa: false,
      unlockingPoa: false,
      errorMessage: null,
      poaStatusPollTimer: null,
    };
  },
  computed: {
    ...mapGetters('modules', ['poaModules']),
    ...mapGetters(['userId']),
    ...mapGetters('poa', { poaData: 'data', poaId: 'poaId' }),
    ...mapGetters('orders', ['isProductPurchased']),
    ...mapGetters('directory-person', ['userDetails']),
    ...mapGetters('cart', [
      'cartItems',
      'cartFinalPrice',
      'cartItemFinalPrice',
    ]),
    ...mapGetters('progress', { poaProgress: 'poa' }),
    documentsUrl() {
      return `${process.env.SAFEWILL_BACKEND_API}/documents/poa`;
    },
    poaIsSubmitted() {
      return ['AWAITING_APPROVAL', 'APPROVED'].includes(this.poaStatus);
    },
    poaIsApproved() {
      return this.poaStatus === 'APPROVED';
    },
    poaIsPurchased() {
      return this.isProductPurchased('POA');
    },
    statusText() {
      if (this.submittingPoa) {
        return this.$t('components.genericStatusText.beingProcessed');
      }
      switch (this.poaStatus) {
        case 'IN_PROGRESS':
          return this.poaProgress.complete
            ? this.$t('components.genericStatusText.readyToSubmit')
            : this.$t('components.genericStatusText.inProgress');
        case 'AWAITING_APPROVAL':
          return this.$t('components.genericStatusText.beingReviewed');
        case 'FLAGGED':
          return this.$t('components.genericStatusText.needsAttention');
        case 'APPROVED':
          return this.$t('components.genericStatusText.isApproved');
        default:
          return '';
      }
    },
    tooltipText() {
      switch (this.poaStatus) {
        case 'IN_PROGRESS':
          return this.poaProgress.complete
            ? this.$t(
                'components.genericStatusText.tooltipText.inProgress.isReady',
                { product: 'POA' }
              )
            : this.$t(
                'components.genericStatusText.tooltipText.inProgress.notReady',
                { product: 'POA' }
              );
        case 'AWAITING_APPROVAL':
          return this.$t(
            'components.genericStatusText.tooltipText.awaitingApproval',
            { product: 'POA' }
          );
        case 'FLAGGED':
          return this.$t('components.genericStatusText.tooltipText.flagged');
        case 'APPROVED':
          return this.$t('components.genericStatusText.tooltipText.approved', {
            product: 'POA',
          });
        default:
          return '';
      }
    },
    to() {
      const to = {};
      this.poaModules.forEach((module) => {
        let uri = `/poa/${kebabCase(module.name)}`;
        const step = module.steps[0];
        if (step?.length) {
          uri += `/${step}`;
        }
        to[module.name] = this.localePath(uri);
      });
      return to;
    },
    showDownloadMedical() {
      return this.poaData.meta.medical.has_attorney === true;
    },
    showDownloadFinancial() {
      return this.poaData.meta.financial.has_attorney === true;
    },
    displaySubmitButton() {
      return (
        this.showDownloadMedical === true || this.showDownloadFinancial === true
      );
    },
    displayEditButton() {
      return ['APPROVED', 'FLAGGED'].includes(this.poaStatus);
    },
  },
  methods: {
    ...mapActions('cart', ['addToCart']),
    ...mapActions('poa', ['getStatus']),
    async checkoutPoa() {
      const items = this.cartItems.map((item) => {
        return {
          item_id: item.product.code,
          price: this.cartItemFinalPrice(item.product.code) / 100,
          quantity: 1,
        };
      });

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'begin_checkout',
        props: {
          checkout_trigger: 'POA complete',
          currency: this.$t('texts.words.currency'),
          value: this.cartFinalPrice / 100,
          items,
        },
      });
      await this.addToCart({ codes: ['POA'], showSnackbar: false });
      await this.$router.push(this.localePath('/checkout/cart'));
    },
    async submitPoa() {
      this.submittingPoa = true;
      if (!this.poaIsPurchased) {
        await this.checkoutPoa();
        return;
      }
      try {
        await this.$apollo.mutate({
          mutation: SUBMIT_POA,
          variables: {
            id: this.poaId,
          },
          result() {
            this.getStatus();
          },
          error,
        });
      } catch (error) {
        this.displayErrorMessage();
      }
      this.submittingPoa = false;
    },
    displayErrorMessage() {
      this.errorMessage = this.$t('texts.sentences.somethingWentWrong');
    },
    async unlockPoa() {
      this.unlockingPoa = true;
      await this.$apollo.mutate({
        mutation: VERSION_POA,
        variables: {
          id: this.poaId,
        },
        error,
      });
      this.unlockingPoa = false;
    },
    async downloadPoa(poaCategory) {
      const downloadUrlEndpoint = [
        this.documentsUrl,
        poaCategory,
        this.userId,
      ].join('/');
      const data = await fetch(downloadUrlEndpoint, {
        method: 'GET',
        headers: {
          Authorization: this.$auth.strategy.token.get(),
        },
      });

      if (data.ok) {
        const result = await data.json();
        window.location.assign(result.downloadUrl);
      }
    },
  },
};
