
import { mapGetters, mapActions } from 'vuex';
import { will } from '@/mixins/apollo';

export default {
  name: 'AppWillLifeInsuranceUpsell',
  mixins: [will],
  data() {
    return {
      product: 'LIFE_INSURANCE',
      isProcessing: false,
      failureMessageIsVisible: false,
    };
  },
  computed: {
    ...mapGetters('directory-person', ['userDetails', 'userIsAustralian']),
    ...mapGetters('account-interests', ['hasInterest']),
    ...mapGetters('product', ['products']),
    upsellIsVisible() {
      return this.upsellProductCode !== null;
    },
    userHasLeadInterest() {
      return this.hasInterest(this.product);
    },
    upsellProductCode() {
      if (this.$ff.lifeInsuranceBrandUpsellEnabled()) {
        return (
          this.products.find((product) => product.category === this.product)
            ?.code || null
        );
      }

      return null;
    },
  },
  mounted() {
    this.getAccountInterests(this.userId);
  },
  methods: {
    ...mapActions('account-interests', [
      'getAccountInterests',
      'createInterest',
    ]),
    async createLifeInsuranceInterest() {
      this.isProcessing = true;
      try {
        await this.createInterest({
          userId: this.userId,
          country: this.userDetails.residentialAddress.country,
          region: this.userDetails.residentialAddress.region,
          product: this.product,
          brand: this.$ff.lifeInsuranceBrandUpsellEnabled()
            ? this.upsellProductCode
            : undefined,
        });

        await this.updateUpsellInterest(true);
      } catch (err) {
        this.failureMessageIsVisible = true;
        console.error(err);
      }
      // Refetch account interests
      this.getAccountInterests(this.userId);
      this.isProcessing = false;
    },
    async updateUpsellInterest(interested) {
      this.willMeta.life_insurance_upsell = interested
        ? 'interested'
        : 'disinterested';
      await this.updateWillMeta();
    },
  },
};
