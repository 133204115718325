
export default {
  name: 'AppCheckoutExitModal',
  computed: {
    alternativeContent() {
      return this.$ff.combinedFreeWillCheckoutSubmission();
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    exitCheckout() {
      this.$emit('confirmLeave');
      this.$emit('closeModal');
      this.$router.push({ path: this.localePath('/') });
    },
  },
};
