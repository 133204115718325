
export default {
  name: 'AppBaseRadio',
  model: {
    event: 'change',
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      // [{ label, value }, ...]
      type: Array,
      required: true,
    },
    value: {
      default: null,
      type: [Boolean, String],
    },
    label: {
      default: '',
      type: String,
    },
    error: {
      default: '',
      type: String,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  computed: {
    labelClasses() {
      return this.options.map((option) => {
        const selected = option.value === this.modelValue;
        return {
          'group-hover:btn-white-hover': !selected,
          'text-lg': !this.compact,
        };
      });
    },
    spanClasses() {
      return this.compact ? 'text-base mt-0 mb-3' : 'mb-6';
    },
    svgSize() {
      return this.compact ? '20px' : '30px';
    },
    checkedColor() {
      return this.options.map((option) => {
        const selected = option.value === this.modelValue;
        if (!selected) {
          return '#EDF0EF';
        } else if (this.error) {
          return '#E65B4A';
        }
        return '#00DABF';
      });
    },
    ids() {
      return this.options.map((option) => `${this.id}_${option.value}`);
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.modelValue, this);
    },
  },
};
