import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2029d84f"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppDataTableCell: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/DataTableCell.vue').default,AppDataTableRow: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/DataTableRow.vue').default,AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,AppDataTableButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/DataTableButton.vue').default,AppDataTable: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/DataTable.vue').default,AppCard: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/Card.vue').default,AppNewEndOfLifeCategoryModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/NewEndOfLifeCategoryModal.vue').default})
