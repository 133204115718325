
export default {
  name: 'AppModuleCardText',
  props: {
    icon: {
      default: null,
      type: String,
    },
    light: {
      default: false,
      type: Boolean,
    },
    percentage: {
      default: null,
      type: String,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
