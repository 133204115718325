
export default {
  name: 'AppSelectable',
  props: {
    isSelected: {
      default: false,
      type: Boolean,
    },
    isSelectable: {
      default: true,
      type: Boolean,
    },
    isWarning: {
      default: false,
      type: Boolean,
    },
    data: {
      default: null,
      type: Object,
    },
    id: {
      default: null,
      type: String,
    },
    align: {
      default: 'middle',
      type: String,
      validator: (value) => {
        return ['middle', 'top'].includes(value);
      },
    },
    inputType: {
      default: 'checkbox',
      type: String,
      validator: (value) => ['checkbox', 'radio'].includes(value),
    },
    grouped: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    modelValue: {
      get() {
        return Boolean(this.isSelected);
      },
      set() {},
    },
    background() {
      return this.isWarning ? 'pink' : this.isSelected ? 'mint' : 'white';
    },
  },
  methods: {
    change() {
      if (this.id) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'click',
          props: {
            element_id: `selectable_${this.id}`,
            page_path: this.$route.path,
          },
        });
      }
      this.$emit('selectionChange', !this.isSelected, this.data);
    },
  },
};
