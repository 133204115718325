
export default {
  name: 'AppVerticalSelectButton',
  props: {
    id: {
      default: null,
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      default: null,
      type: [Boolean, String, Array],
    },
    multiple: {
      default: false,
      type: Boolean,
    },
    align: {
      default: 'middle',
      type: String,
      validator: (value) => {
        return ['middle', 'top'].includes(value);
      },
    },
    desktopAlign: {
      default: 'middle',
      type: String,
      validator: (value) => {
        return ['middle', 'top'].includes(value);
      },
    },
    mobileAlign: {
      default: 'middle',
      type: String,
      validator: (value) => {
        return ['middle', 'top'].includes(value);
      },
    },
    desktopAbsoluteCheckbox: {
      default: false,
      type: Boolean,
    },
    grouped: {
      default: false,
      type: Boolean,
    },
    keyBy: {
      default: 'value',
      type: String,
    },
    emphasiseFirstItem: {
      default: false,
      type: Boolean,
    },
    twoColumns: {
      default: false,
      type: Boolean,
    },
  },
  emits: ['input'],
  computed: {
    desktopAlignValue() {
      return this.desktopAlign === 'top' ? 'flex-start' : 'center';
    },
    mobileAlignValue() {
      return this.mobileAlign === 'top' ? 'flex-start' : 'center';
    },
  },
  methods: {
    isOptionSelected(option) {
      if (this.multiple) {
        return this.value.includes(option[this.keyBy]);
      }
      return option[this.keyBy] === this.value;
    },
    selectOption(option) {
      return () => {
        if (this.multiple) {
          const selected = this.value.includes(option[this.keyBy]);
          if (selected) {
            this.$emit(
              'input',
              this.value.filter((value) => value !== option[this.keyBy])
            );
          } else {
            this.$emit('input', [...this.value, option[this.keyBy]]);
          }
        } else {
          this.$emit('input', option[this.keyBy]);
        }
      };
    },
  },
};
