
import {
  isDateLegacyFormat,
  convertLegacyDateToIsoFormat,
  isDateIsoDateTimeFormat,
  convertIsoDateTimeToIsoDate,
  isDateEpochFormat,
  convertEpochDateToIsoFormat,
  isDateIsoFormat,
} from '@/utilities';

export default {
  name: 'AppDateControl',
  props: {
    required: {
      type: Boolean,
      default: true,
    },
    rules: {
      default: null,
      type: String,
    },
    value: {
      default: '',
      type: [String, Boolean, Number],
    },
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      identifier: this.id + '__' + Math.random().toString(36).slice(2),
      pseudoDate: {
        day: '',
        month: '',
        year: '',
      },
    };
  },
  computed: {
    dateIsComplete() {
      return !!(
        this.pseudoDate.year.length === 4 &&
        this.pseudoDate.month &&
        this.pseudoDate.day
      );
    },
    modelValue: {
      get() {
        if (this.value) {
          if (isDateIsoFormat(this.value)) {
            return this.value;
          } else if (isDateLegacyFormat(this.value)) {
            return convertLegacyDateToIsoFormat(this.value);
          } else if (isDateIsoDateTimeFormat(this.value)) {
            return convertIsoDateTimeToIsoDate(this.value);
          } else if (isDateEpochFormat(this.value)) {
            return convertEpochDateToIsoFormat(this.value);
          }
        }
        return null;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    if (!this.value) {
      this.unWatchDate = this.$watch(
        () => {
          return this.value;
        },
        this.parseDate,
        { immediate: true }
      );
    } else {
      this.parseDate();
    }
  },
  methods: {
    parseDate() {
      let date;
      if (this.value) {
        if (isDateIsoFormat(this.value)) {
          date = this.value;
        } else if (isDateLegacyFormat(this.value)) {
          date = convertLegacyDateToIsoFormat(this.value);
        } else if (isDateIsoDateTimeFormat(this.value)) {
          date = convertIsoDateTimeToIsoDate(this.value);
        } else if (isDateEpochFormat(this.value)) {
          date = convertEpochDateToIsoFormat(this.value);
        }
        if (date) {
          this.modelValue = date;
          date = date?.split('-');
          this.pseudoDate.year = date[0];
          this.pseudoDate.month = date[1];
          this.pseudoDate.day = date[2];
          if (this.unWatchDate) {
            this.unWatchDate();
          }
        }
      }
    },
    updateDate(field, event) {
      let value = this.pseudoDate[field].replace(/\D/g, '');
      let $nextField = null;
      const shouldPadStart = value && event.type === 'blur' && field !== 'year';
      if (isNaN(+value)) {
        this.pseudoDate[field] = '';
      }
      if (shouldPadStart) {
        value = value.padStart(2, '0');
      } else if (field === 'day' && (value.length === 2 || +value > 3)) {
        $nextField = this.$refs.month.$el.querySelector('input');
      } else if (field === 'month' && (value.length === 2 || +value > 1)) {
        $nextField = this.$refs.year.$el.querySelector('input');
      }
      this.pseudoDate[field] = value;
      if ($nextField) {
        $nextField.focus();
        $nextField.select();
      }
      if (this.dateIsComplete) {
        this.modelValue = [
          this.pseudoDate.year,
          this.pseudoDate.month.padStart(2, '0'),
          this.pseudoDate.day.padStart(2, '0'),
        ]
          .filter(Boolean)
          .join('-');
      } else {
        this.modelValue = null;
      }
    },
  },
};
