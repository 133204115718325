
export default {
  name: 'AppCardSection',
  props: {
    title: {
      default: null,
      type: String,
    },
  },
};
