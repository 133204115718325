
import { mapGetters } from 'vuex';
import { user } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateGifts',
  mixins: [user],
  layout: 'ruach',
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    ...mapGetters('directory-person', ['userDetails', 'userIsAustralian']),
    ...mapGetters('product-prices', ['priceOf']),
    ...mapGetters('cart', [
      'cartItems',
      'cartFinalPrice',
      'cartItemFinalPrice',
    ]),
    giftItemsWithPrices() {
      return [
        !this.isFreeWillWeek ? 'WILL_GIFT' : null,
        this.userIsAustralian ? 'POA_GIFT' : null,
      ]
        .filter(Boolean)
        .map((code) => ({
          product: code,
          numerable: true,
          finalPrice: this.priceOf(code),
        }));
    },
  },
  methods: {
    click() {
      const items = this.cartItems.map((item) => {
        return {
          item_id: item.product.code,
          price: this.cartItemFinalPrice(item.product.code) / 100,
          quantity: 1,
        };
      });

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'begin_checkout',
        props: {
          checkout_trigger: 'Gifts',
          currency: this.$t('texts.words.currency'),
          value: this.cartFinalPrice / 100,
          items,
        },
      });
      this.$router.push({
        path: this.localePath('/checkout/cart'),
      });
    },
  },
};
