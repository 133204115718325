
export default {
  name: 'AppRadioOptions',
  props: {
    id: {
      default: null,
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      default: null,
      type: [Boolean, String],
    },
    layout: {
      default: 'end',
      type: String,
    },
    theme: {
      default: null,
      type: String,
    },
  },
};
