
import capitalize from 'lodash/capitalize';
import { mapActions } from 'vuex';
import { PLAN_FLAG_STATUS } from '@/utilities/constants';

export default {
  name: 'AppPlanFlagsListing',
  props: {
    flagsAreDisabled: {
      default: false,
      type: Boolean,
    },
    planFlags: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      PLAN_FLAG_STATUS,
    };
  },
  methods: {
    ...mapActions('flag', ['deletePlanFlag']),
    getStatusName(status) {
      return capitalize(status.toLowerCase());
    },
    deleteFlag(id) {
      this.deletePlanFlag(id);
    },
  },
};
