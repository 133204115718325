
import { mapGetters } from 'vuex';

export default {
  name: 'AppComponentOrganismsPurchasedProducts',
  props: {
    isWillCompleted: {
      type: Boolean,
      default: false,
    },
    isPoaCompleted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    ...mapGetters('directory-person', ['userPartner']),
    ...mapGetters('orders', ['isLatestProductPurchased']),
    ...mapGetters('progress', ['willFirstIncompleteSectionPath']),
    ...mapGetters('will-tiers', [
      'hasPurchasedWillProduct',
      'hasPurchasedWillTierTwo',
    ]),
    isUnlockPurchased() {
      return (
        this.isLatestProductPurchased('UNLOCK') ||
        this.isLatestProductPurchased('RECENT_UNLOCK')
      );
    },
    isPoaPurchased() {
      return this.isLatestProductPurchased('POA');
    },
    isGiftPurchased() {
      return ['WILL_GIFT', 'POA_GIFT'].some((product) =>
        this.isLatestProductPurchased(product)
      );
    },
    isPartnerWillPurchased() {
      return this.isLatestProductPurchased('PARTNER_WILL');
    },
    productState() {
      if (this.isUnlockPurchased) {
        return 'UNLOCKED_WILL';
      }
      if (this.hasPurchasedWillProduct && this.isWillCompleted) {
        return 'FINISHED_WILL';
      }
      if (this.hasPurchasedWillProduct) {
        return 'UNFINISHED_WILL';
      }
      if (this.isPoaPurchased && this.isPoaCompleted) {
        return 'FINISHED_POA';
      }
      if (this.isPoaPurchased) {
        return 'UNFINISHED_POA';
      }
      return null;
    },
    partnerWillHeader() {
      if (this.$ff.combinedFreeWillCheckoutSubmission()) {
        return this.$t(
          'components.purchasedProductHighlight.partnerWill.alternativeTitle'
        );
      }
      return this.$t('components.purchasedProductHighlight.partnerWill.title');
    },
    partnerWillText() {
      if (!this.userPartner?.defaultEmailAddress) {
        return this.$t(
          'components.purchasedProductHighlight.partnerWill.noEmail'
        );
      } else if (this.$ff.combinedFreeWillCheckoutSubmission()) {
        return this.$t(
          'components.purchasedProductHighlight.partnerWill.hasEmailAlternative'
        );
      }
      return this.$t(
        'components.purchasedProductHighlight.partnerWill.hasEmail'
      );
    },
    finishedWillTitle() {
      if (this.$ff.combinedFreeWillCheckoutSubmission()) {
        return this.$t('components.purchasedProductHighlight.yourWill');
      }
      return this.$t('components.purchasedProductHighlight.finaliseWill');
    },
    finishedWillSteps() {
      switch (true) {
        case this.$ff.combinedFreeWillCheckoutSubmission():
          return this.$t(
            `components.purchasedProductHighlight.finishedWill.${this.willProduct}.submitAndCheckoutSteps`
          );
        case this.isFreeWillWeek:
          return this.$t(
            `components.purchasedProductHighlight.finishedWill.${this.willProduct}.fwwSteps`
          );
        default:
          return this.$t(
            `components.purchasedProductHighlight.finishedWill.${this.willProduct}.steps`
          );
      }
    },
    willProduct() {
      return this.hasPurchasedWillTierTwo ? 'WILL_TIER_TWO' : 'WILL';
    },
    unfinishedWillSteps() {
      switch (true) {
        case this.$ff.combinedFreeWillCheckoutSubmission():
          return this.$t(
            `components.purchasedProductHighlight.unfinishedWill.${this.willProduct}.submitAndCheckoutSteps`
          );
        case this.isFreeWillWeek:
          return this.$t(
            `components.purchasedProductHighlight.unfinishedWill.${this.willProduct}.fwwSteps`
          );
        default:
          return this.$t(
            `components.purchasedProductHighlight.unfinishedWill.${this.willProduct}.steps`
          );
      }
    },
    lastFinishedSectionPath() {
      return this.$ff.combinedFreeWillCheckoutSubmission()
        ? this.localePath(this.willFirstIncompleteSectionPath)
        : this.localePath('/will');
    },
  },
};
