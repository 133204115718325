
import { mapActions, mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/financial';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaFinancialPrimaryDecisions',
  mixins: [user, will],
  apollo: {
    poaFinancialMeta: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setData(data.poa);
      },
    },
  },
  data() {
    return {
      poaFinancialMeta: {
        attorney_decisions: undefined,
        attorney_decisions_other: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('directory-person', ['userState']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
      });
    },
    isComplete() {
      const completedMainQuestion =
        this.poaFinancialMeta && this.poaFinancialMeta.attorney_decisions;
      const completedOtherQuestion =
        !this.eligibleForOtherDecision ||
        this.poaFinancialMeta.attorney_decisions_other;
      return completedMainQuestion && completedOtherQuestion;
    },
    eligibleForOtherDecision() {
      return (
        ['nsw', 'sa', 'nt'].includes(this.userState) &&
        this.poaFinancialMeta.attorney_decisions === 'jointly'
      );
    },
  },
  methods: {
    ...mapActions('poa', ['setData']),
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMetas(this.poaId, [
          {
            key: 'attorney_decisions',
            value: this.poaFinancialMeta.attorney_decisions,
            category: 'FINANCIAL',
          },
          {
            key: 'attorney_decisions_other',
            value: this.poaFinancialMeta.attorney_decisions_other,
            category: 'FINANCIAL',
          },
        ])
      );

      const path = this.localePath(this.next.path);
      await this.$router.push({ path });
    },
  },
};
