
import { mapGetters } from 'vuex';
import { executors, user } from '@/mixins/apollo';
import { RELATIONSHIP_TYPE } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateWillExecutorsPrimaryContact',
  mixins: [executors, user],
  data() {
    return {
      RELATIONSHIP_TYPE,
      selectedContacts: [],
    };
  },
  computed: {
    ...mapGetters('directory-person', ['contacts']),
    isComplete() {
      return !!this.selectedContacts.length;
    },
    filteredContacts() {
      return this.contacts.filter((contact) => {
        const contactIsAlreadySelected = this.selectedContacts.includes(
          contact.id
        );
        const isBackupExecutor = this.isBackupExecutor(contact);
        return contactIsAlreadySelected || !isBackupExecutor;
      });
    },
  },
  created() {
    this.updateSelectedPrimaryExecutors();
  },
  methods: {
    updateSelectedPrimaryExecutors() {
      this.selectedContacts = this.contacts
        .filter((contact) => {
          return this.primaryExecutors.find(
            (executor) => contact.id === executor.directoryPerson.id
          );
        })
        .map((contact) => contact.id);
    },
    async submit() {
      try {
        await Promise.all(
          this.contacts.map((contact) => {
            const isPrimaryExecutor = this.isPrimaryExecutor(contact);
            const isSelected = this.selectedContacts.includes(contact.id);
            if (!isSelected && isPrimaryExecutor) {
              return this.removeExecutor(contact);
            }
            return Promise.resolve();
          })
        );
        await Promise.all(
          this.contacts.map((contact) => {
            const isPrimaryExecutor = this.isPrimaryExecutor(contact);
            const isSelected = this.selectedContacts.includes(contact.id);
            if (isSelected && !isPrimaryExecutor) {
              return this.addExecutor(contact.id, 'primary');
            }
            return Promise.resolve();
          })
        );

        this.updateSelectedPrimaryExecutors();
        this.$router.push({
          path: this.localePath('/will/executors/professional-terms'),
        });
      } catch (error) {
        console.error(error);
        this.$refs.form.setGenericError('assigningContacts');
      }
    },
  },
};
