
export default {
  name: 'PagesAffiliatePoa',
  layout: 'ruach',
  middleware: ['redirect-if-no-poa'],
  computed: {
    pageIsProductRoot() {
      return (
        this.$route.fullPath.endsWith('/poa') ||
        this.$route.fullPath.endsWith('/poa/')
      );
    },
  },
};
