
export default {
  name: 'ChangeRelationshipModal',
  props: {
    role: {
      type: String,
      required: true,
    },
  },
};
