
import { v4 as uuid } from 'uuid';
import { decodePayloadFromUrl, signJwt } from '@/utilities';

export default {
  name: 'PagesAffiliateCheckoutGenericIndex',
  data() {
    return {
      checkoutData: {
        customProducts: [],
        productsWithCustomPricesToken: null,
      },
      checkoutItems: [],
    };
  },
  created() {
    this.checkoutData = decodePayloadFromUrl(this.$route.query.checkoutData);
    if (!this.checkoutData) {
      this.$router.push({
        path: this.localePath('/checkout/cart'),
      });
    }
    this.validateProducts(this.checkoutData);
    this.checkoutData.productsWithCustomPricesToken = signJwt({
      id: uuid(),
      productPricePairs: this.checkoutData.customProducts,
    });
    this.checkoutItems = this.checkoutData.customProducts.map(
      ({ name, price }) => ({
        product: name,
        finalPrice: price,
        type: 'DEFAULT',
      })
    );
  },
  methods: {
    validateProducts({ customProducts }) {
      if (Array.isArray(customProducts) && customProducts.length > 0) {
        return;
      }
      throw new Error('Invalid products passed');
    },
    ordersCreationSuccessfulHandler() {
      this.$router.push({ path: this.localePath('/checkout/generic/thanks') });
    },
  },
};
