
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { user } from '@/mixins/apollo';

export default {
  name: 'AppWillPetPanel',
  mixins: [user],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapFields('user/pets', ['pet.name', 'pet.type']),
  },
  methods: {
    ...mapActions('application/ui', ['hidePetPanel']),
    ...mapActions('user/pets', ['savePet']),
    async submit() {
      this.loading = true;
      await this.savePet();
      this.loading = false;
      this.hidePetPanel();
    },
  },
};
