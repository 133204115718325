
import { mapGetters } from 'vuex';

export default {
  name: 'AppHelp',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    vaultItemKey: {
      type: String,
      required: true,
    },
    vaultItemType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      vaultItem: null,
    };
  },
  computed: {
    ...mapGetters('vault-item', ['willHelpAssessmentVaultItems']),
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    message() {
      const type = this.vaultItemType || 'DEFAULT';
      return this.$t(`components.help.message.${this.vaultItemKey}.${type}`);
    },
  },
  mounted() {
    this.vaultItem = this.willHelpAssessmentVaultItems.find(
      (item) =>
        item.key === this.vaultItemKey && item.type === this.vaultItemType
    );
    if (this.vaultItem) {
      this.modelValue = this.vaultItem?.helpRequired;
    }
  },
  methods: {
    async toggleChecked() {
      const vaultItemKeyAndtype = `${this.vaultItemKey}${this.vaultItemType ? '-' + this.vaultItemType : ''}`;
      this.modelValue = !this.value;
      await this.$nextTick();
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'click',
        props: {
          element_id: `help__${vaultItemKeyAndtype}`,
          page_path: this.$route.path,
          click_value: this.value,
        },
      });
    },
  },
};
