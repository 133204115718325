
import { fileIcon, shortFileName } from '@/utilities';

export default {
  name: 'AppFileThumbnail',
  props: {
    fileId: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    unlinkable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    shortFileName,
    fileIcon,
  },
};
