
import { hexToRgb } from '@/utilities';
export default {
  name: 'AppChip',
  props: {
    background: {
      type: String,
      default: null,
    },
  },
  computed: {
    backgroundStyle() {
      if (this.background) {
        if (this.background.includes('#')) {
          return hexToRgb(this.background);
        }
        return `var(--${this.background})`;
      }
      return null;
    },
    styles() {
      return {
        '--background': this.backgroundStyle,
      };
    },
  },
};
