
import { ValidationObserver } from 'vee-validate';
export default {
  name: 'AppFormWrapper',
  components: {
    ValidationObserver,
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.validationObserver.errors;
      },
      this.validateAndEmit,
      { immediate: true }
    );
  },
  methods: {
    async validateForm() {
      await this.$refs.validationObserver.validate();
    },
    validateAndEmit() {
      this.$emit('input', this.$refs.validationObserver.flags.invalid);
    },
    onSubmit() {
      if (!this.$refs.validationObserver.flags.invalid) {
        this.$emit('submit');
      }
    },
  },
};
