
import { mapActions } from 'vuex';
import { IDENTIFIER_TYPE, RELATIONSHIP_TYPE } from '@/utilities/constants';
import { joinTextWithAnd } from '@/utilities';

export default {
  name: 'AppContactList',
  props: {
    contacts: {
      type: Array,
      required: true,
    },
    max: {
      default: Infinity,
      type: Number,
    },
    identifierRequirements: {
      type: Array,
      default: () => [],
    },
    overEighteen: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    removable: {
      type: Boolean,
      default: false,
    },
    emailRequiredForInvite: {
      type: Boolean,
      default: false,
    },
    contactLabel: {
      type: String,
      default: 'contact',
    },
    excludedContacts: {
      type: Array,
      default: () => [],
    },
    excludedContactsLabel: {
      type: String,
      default: null,
    },
    removeRelationshipOnRemove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const listId = new Date().getTime();
    return {
      listId,
      deleteContactModalIsVisible: false,
      contactToDelete: null,
      loading: false,
    };
  },
  computed: {
    sortedContacts() {
      const notDisabledContacts = this.contacts.filter(
        (contact) => !this.isDisabled(contact)
      );
      const disabledContacts = this.contacts.filter((contact) =>
        this.isDisabled(contact)
      );
      return [
        ...notDisabledContacts.sort((a, b) => {
          const lastNameA = a.lastName || 'z';
          const lastNameB = b.lastName || 'z';
          return lastNameA
            .toLowerCase()
            .localeCompare(lastNameB.toLowerCase(), 'en', {
              sensitivity: 'base',
            });
        }),
        ...disabledContacts.sort((a, b) => {
          const lastNameA = a.lastName || 'z';
          const lastNameB = b.lastName || 'z';
          return lastNameA
            .toLowerCase()
            .localeCompare(lastNameB.toLowerCase(), 'en', {
              sensitivity: 'base',
            });
        }),
      ];
    },
    isMultipleSelection() {
      return this.max > 1;
    },
    isReachedMax() {
      return this.value.length >= this.max;
    },
  },
  methods: {
    ...mapActions('directory-person', ['updateDirectoryPerson']),
    removeContact(contact) {
      if (
        [RELATIONSHIP_TYPE.PARTNER, RELATIONSHIP_TYPE.CHILD].includes(
          contact.relationshipWithUser
        ) &&
        this.removeRelationshipOnRemove
      ) {
        this.showDeletePartnerAndChildModal();
        this.contactToDelete = contact;
        return;
      }
      this.$emit(
        'selectionChange',
        this.contacts.filter((c) => c.id !== contact.id)
      );
    },
    editContact(contact) {
      this.$emit('editContact', contact);
    },
    getContactAlert(contact) {
      const missingName = !contact.firstName || !contact.lastName;
      const missingEmail =
        (this.identifierRequirements.includes(IDENTIFIER_TYPE.EMAIL) ||
          this.emailRequiredForInvite) &&
        !contact.defaultEmailAddress;
      const missingDob =
        this.identifierRequirements.includes(IDENTIFIER_TYPE.DOB) &&
        !contact.dateOfBirth;
      const missingAddress =
        this.identifierRequirements.includes(IDENTIFIER_TYPE.ADDRESS) &&
        !contact.residentialAddress.streetAddress;
      const missingPhone =
        this.identifierRequirements.includes(IDENTIFIER_TYPE.PHONE) &&
        !contact.defaultPhoneNumber;

      if (this.overEighteen && !contact.over18) {
        return {
          header: this.$t('components.contactList.alert.underEighteenTitle'),
          content: this.$t('components.contactList.alert.underEighteenContent'),
        };
      }

      if (
        missingName ||
        missingEmail ||
        missingDob ||
        missingAddress ||
        missingPhone
      ) {
        return {
          header: this.$t(
            'components.contactList.alert.missingRequirementTitle'
          ),
          content: this.$t(
            'components.contactList.alert.missingRequirementContent',
            {
              role: this.contactLabel,
              fields: joinTextWithAnd([
                ...(missingName
                  ? [this.$t('components.contactList.name')]
                  : []),
                ...(missingEmail
                  ? [this.$t('components.contactList.email')]
                  : []),
                ...(missingDob ? [this.$t('components.contactList.dob')] : []),
                ...(missingAddress
                  ? [this.$t('components.contactList.address')]
                  : []),
                ...(missingPhone
                  ? [this.$t('components.contactList.phone')]
                  : []),
              ]),
            }
          ),
        };
      }
      if (this.excludedContacts.includes(contact.id)) {
        return {
          header: this.$t('components.contactList.alert.selectedBeforeTitle'),
          content: this.$t(
            'components.contactList.alert.selectedBeforeContent',
            {
              role: this.excludedContactsLabel,
            }
          ),
        };
      }

      if (
        this.isReachedMax &&
        !this.isSelected(contact) &&
        this.isMultipleSelection
      ) {
        return {
          header: this.$t('components.contactList.alert.maxReachedTitle'),
          content: this.$t('components.contactList.alert.maxReachedContent', {
            max: this.max,
          }),
        };
      }

      return null;
    },
    isSelected(contact) {
      return this.value.includes(contact.id);
    },
    isContactExcluded(contactId) {
      return this.excludedContacts.includes(contactId);
    },
    isDisabled(contact) {
      if (this.overEighteen && !contact.over18) {
        return true;
      }
      const isSelected = this.isSelected(contact);

      if (this.isContactExcluded(contact.id) && !isSelected) {
        return true;
      }

      return (
        this.selectable &&
        this.isMultipleSelection &&
        this.isReachedMax &&
        !isSelected
      );
    },
    changeSelection(contact) {
      const contactId = contact.id;
      if (this.isMultipleSelection) {
        if (this.isSelected(contact)) {
          this.$emit(
            'input',
            this.value.filter((id) => id !== contactId)
          );
        } else {
          this.$emit('input', [...this.value, contactId]);
        }
      } else {
        this.$emit('input', [contactId]);
      }
    },
    showDeletePartnerAndChildModal() {
      this.deleteContactModalIsVisible = true;
    },
    hideDeletePartnerAndChildModal() {
      this.deleteContactModalIsVisible = false;
    },
    async removeRelationshipFromContact() {
      this.loading = true;
      await this.updateDirectoryPerson({
        ...this.contactToDelete,
        relationshipWithUser: RELATIONSHIP_TYPE.UNKNOWN,
      });
      this.$emit(
        'selectionChange',
        this.contacts.filter((c) => c.id !== this.contactToDelete.id)
      );
      this.hideDeletePartnerAndChildModal();
      this.contactToDelete = null;
      this.loading = false;
    },
  },
};
