
export default {
  name: 'AppMoleculesCouponInput',
  props: {
    value: {
      default: '',
      type: String,
    },
    layout: {
      default: 'end',
      type: String,
    },
    couponIsValid: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      formIsVisible: false,
      modelValue: this.value,
    };
  },
  computed: {
    alertIsVisible() {
      return this.formIsVisible || this.couponIsValid;
    },
  },
  watch: {
    value(newValue) {
      this.modelValue = newValue;
    },
  },
  methods: {
    toggleFormVisibility() {
      this.formIsVisible = !this.formIsVisible;
    },
    applyCoupon(value) {
      this.$emit('applyCoupon', value);
    },
  },
};
