
export default {
  name: 'AppActionLink',
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    icon: {
      type: String,
      required: true,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    underline: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        icon: {
          'opacity-50 cursor-not-allowed': this.disabled,
        },
        label: {
          'opacity-50 cursor-not-allowed': this.disabled,
          'group-hover:no-underline': !this.disabled,
          'cursor-text no-underline': !this.underline,
        },
      };
    },
  },
};
