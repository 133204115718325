
import REQUEST_UPDATE_PASSWORD_EMAIL from '@/graphql/queries/RequestUpdatePasswordEmail';
import { user } from '@/mixins/apollo';

export default {
  name: 'AppAccountChangePassword',
  mixins: [user],
  data() {
    return {
      emailRequested: false,
      loading: false,
    };
  },
  methods: {
    async requestUpdatePasswordEmail() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.query({
          query: REQUEST_UPDATE_PASSWORD_EMAIL,
          variables: {
            userId: this.userId,
          },
        });
        if (data.requestUpdatePasswordEmail === true) {
          this.emailRequested = true;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
