
import { mapGetters } from 'vuex';
export default {
  name: 'AppCart',
  data: () => ({
    cartPanelIsVisible: false,
    cartBadgeRightOffset: 0,
    isWillTierTwoUpgradeModalVisible: false,
    isWillTierTwoDowngradeModalVisible: false,
  }),
  computed: {
    ...mapGetters('cart', ['productRemoval']),
    itemRemovalModalIsVisible() {
      return !!this.productRemoval.code;
    },
  },
  mounted() {
    this.measureAndAdjust();
    window.addEventListener('resize', this.measureAndAdjust);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.measureAndAdjust);
  },
  methods: {
    measureAndAdjust() {
      const rightOffset = Math.round(
        document.body.getBoundingClientRect().width -
          this.$el.getBoundingClientRect().right +
          this.$el.getBoundingClientRect().width / 2
      );
      if (this.cartBadgeRightOffset !== rightOffset) {
        this.cartBadgeRightOffset = rightOffset;
      }
    },
    toggleCartPanel() {
      if (!this.cartPanelIsVisible) {
        this.measureAndAdjust();
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'view_cart',
        });
      }
      this.cartPanelIsVisible = !this.cartPanelIsVisible;
    },
    async openWillTierTwoUpgradeModal() {
      this.closeCartPanel();
      await this.$nextTick();
      this.isWillTierTwoUpgradeModalVisible = true;
    },
    async openWillTierTwoDowngradeModal() {
      this.closeCartPanel();
      await this.$nextTick();
      this.isWillTierTwoDowngradeModalVisible = true;
    },
    closeCartPanel() {
      this.cartPanelIsVisible = false;
    },
  },
};
