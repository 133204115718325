
import {
  isBeneficiarySplitValid,
  calculateTotalDistribution,
} from '@/utilities';

export default {
  name: 'AppBeneficiaryInputs',
  props: {
    isSplitEvenly: {
      type: Boolean,
      required: false,
    },
    beneficiaries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      values: this.beneficiaries.map((beneficiary) =>
        this.isSplitEvenly
          ? `1/${this.beneficiaries.length}`
          : Number(beneficiary.distribution)
      ),
    };
  },
  computed: {
    visible() {
      return this.beneficiaries?.length;
    },
    valid() {
      return (
        this.isSplitEvenly ||
        (this.total === 100 &&
          this.values.every((value) => isBeneficiarySplitValid(value)))
      );
    },
    total() {
      return calculateTotalDistribution(this.values);
    },
  },
  watch: {
    beneficiaries(beneficiaries) {
      this.values = beneficiaries.map((beneficiary, i) => {
        if (this.isSplitEvenly) {
          return `1/${this.beneficiaries.length}`;
        }
        if (beneficiary.distribution) {
          return Number(beneficiary.distribution);
        }
        return Number.isNaN(Number(this.values[i]))
          ? null
          : Number(this.values[i]);
      });
      this.onInput();
    },
    isSplitEvenly(isSplitEvenly) {
      this.values = this.beneficiaries.map((beneficiary, i) => {
        if (isSplitEvenly) {
          return `1/${this.beneficiaries.length}`;
        }
        if (beneficiary.distribution) {
          return Number(beneficiary.distribution);
        }
        return Number.isNaN(Number(this.values[i]))
          ? null
          : Number(this.values[i]);
      });
      this.onInput();
    },
  },
  mounted() {
    this.onInput();
  },
  methods: {
    onInput() {
      this.$emit('input', this.values);
      this.$emit('valid', this.valid);
    },
    labelText(beneficiary) {
      if (beneficiary.charity) {
        return (
          beneficiary.charity.meta.displayName || beneficiary.charity.meta.name
        );
      } else if (beneficiary.directoryPerson) {
        return beneficiary.directoryPerson.fullName;
      } else if (beneficiary.cause) {
        return beneficiary.cause.displayName;
      }

      return '';
    },
  },
};
