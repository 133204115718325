import { render, staticRenderFns } from "./OrdersDetails.vue?vue&type=template&id=5e4e7080"
import script from "./OrdersDetails.vue?vue&type=script&lang=js"
export * from "./OrdersDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSimpleTable: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/SimpleTable.vue').default,AppCard: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/Card.vue').default})
