
import { mapGetters } from 'vuex';
import { formatPrice } from '@/utilities';

export default {
  name: 'AppRecommendationProduct',
  props: {
    price: {
      type: Number,
      required: true,
    },
    i18nBasePath: {
      type: String,
      required: true,
    },
    product: {
      type: String,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    priceText() {
      return formatPrice(this.price);
    },
    isWillTierTwoProduct() {
      return this.product === 'WILL_TIER_TWO';
    },
  },
};
