
export default {
  name: 'AppAccordion',
  props: {
    iconUp: {
      default: 'chevron-up',
      type: String,
    },
    iconDown: {
      default: 'chevron-down',
      type: String,
    },
    id: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
      if (this.id && this.expanded) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'click',
          props: {
            element_id: `button_${this.id}`,
            page_path: this.$route.path,
          },
        });
      }
    },
  },
};
