
export default {
  name: 'ContactInUseModal',
  props: {
    contact: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  computed: {
    contactName() {
      return this.$contactNameOrDefault(
        this.contact,
        this.$t('components.contactInUse.thisContact')
      );
    },
    roleTexts() {
      return this.roles.map((role) =>
        this.$t(`components.contactInUse.roles.${role}`)
      );
    },
  },
};
