import { render, staticRenderFns } from "./ContactList.vue?vue&type=template&id=068e6634"
import script from "./ContactList.vue?vue&type=script&lang=js"
export * from "./ContactList.vue?vue&type=script&lang=js"
import style0 from "./ContactList.vue?vue&type=style&index=0&id=068e6634&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppContactCard: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/ContactCard.vue').default,AppContactRemove: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/ContactRemove.vue').default})
