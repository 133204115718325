import { render, staticRenderFns } from "./ExecutorFeesPanel.vue?vue&type=template&id=88e0a70a"
import script from "./ExecutorFeesPanel.vue?vue&type=script&lang=js"
export * from "./ExecutorFeesPanel.vue?vue&type=script&lang=js"
import style0 from "./ExecutorFeesPanel.vue?vue&type=style&index=0&id=88e0a70a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppWillExecutorFeesTable: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/ExecutorFeesTable.vue').default,AppTip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Tip.vue').default,AppPanel: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Panel.vue').default})
