
export default {
  name: 'AppWillExecutorTodoListPanel',
  props: {
    displayPanel: {
      type: Boolean,
      default: false,
    },
    toggleDisplayPanel: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      collapseElements: [
        {
          expanded: false,
          estimateLower: 20,
          estimateUpper: 30,
          sectionName: 'importantDocuments',
        },
        {
          expanded: false,
          estimateLower: 5,
          estimateUpper: 10,
          sectionName: 'willReview',
        },
        {
          expanded: false,
          estimateLower: 5,
          estimateUpper: 15,
          sectionName: 'organiseInsurance',
        },
        {
          expanded: false,
          estimateLower: 20,
          estimateUpper: 50,
          sectionName: 'probateGrant',
          splitDescription: true,
        },
        {
          expanded: false,
          estimateLower: 30,
          estimateUpper: 50,
          sectionName: 'contact',
        },
        {
          expanded: false,
          estimateLower: 20,
          estimateUpper: 40,
          sectionName: 'listAssets',
        },
        {
          expanded: false,
          estimateLower: 20,
          estimateUpper: 30,
          sectionName: 'listLiabilities',
        },
        {
          expanded: false,
          estimateLower: 10,
          estimateUpper: 20,
          sectionName: 'estateAccount',
          splitDescription: true,
        },
        {
          expanded: false,
          estimateLower: 15,
          estimateUpper: 30,
          sectionName: 'taxationAdvice',
        },
        {
          expanded: false,
          estimateLower: 15,
          estimateUpper: 30,
          sectionName: 'estateDebts',
        },
        {
          expanded: false,
          estimateLower: 30,
          estimateUpper: 50,
          sectionName: 'distributeAssets',
        },
        {
          expanded: false,
          estimateLower: 15,
          estimateUpper: 30,
          sectionName: 'finaliseEstate',
        },
      ],
    };
  },
  computed: {
    totalLowerBound() {
      return this.collapseElements.reduce(
        (accumulator, current) => accumulator + current.estimateLower,
        0
      );
    },
    totalUpperBound() {
      return this.collapseElements.reduce(
        (accumulator, current) => accumulator + current.estimateUpper,
        0
      );
    },
    totalTasks() {
      return this.collapseElements.reduce((accumulator, current) => {
        return (
          accumulator +
          this.$t(
            `pages.will.executors.index.panel.collapsibleContent.${current.sectionName}.content`
          ).length
        );
      }, 0);
    },
  },
  methods: {
    showHideText(value) {
      return value
        ? 'pages.will.executors.index.panel.hideCollapsible'
        : 'pages.will.executors.index.panel.seeCollapsible';
    },
    toDoListExpand(value) {
      for (const element of this.collapseElements) {
        element.expanded = value;
      }
    },
    toggleCollapse(index) {
      this.collapseElements[index].expanded =
        !this.collapseElements[index].expanded;
    },
  },
};
