
import confirm from '@/mixins/confirm';
import { fileIcon } from '@/utilities';

export default {
  name: 'AppFilesList',
  mixins: [confirm],
  props: {
    files: {
      type: Array,
      required: true,
    },
    confirmUnlink: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    fileIcon,
    fileIsDeleted(fileStatus) {
      return fileStatus === 'DELETED';
    },
    unlinkFile(fileId) {
      if (this.confirmUnlink) {
        this.$confirm(
          {
            title: this.$t('components.fileList.unlinkFile'),
            message: this.$t('components.fileList.unlinkFileMessage'),
          },
          (action) => {
            if (action) {
              this.$emit('unlinkFile', fileId);
            }
          }
        );
      } else {
        this.$emit('unlinkFile', fileId);
      }
    },
  },
};
