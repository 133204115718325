
export default {
  name: 'WpClickTracker',
  props: {
    element: {
      default: 'button',
      type: String,
    },
  },
  methods: {
    trackClickEvent() {
      const id = this.$refs.click.id;
      if (id) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'click',
          props: {
            element_id: `button_${id}`,
            page_path: this.$route.path,
          },
        });
      }
    },
  },
};
