
import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillGuardiansPetCareFund',
  mixins: [user, will],
  computed: {
    submitLabel() {
      return this.willMeta.pet_care_fund
        ? this.$t('forms.labels.continueToEstate')
        : this.$t('forms.labels.skipToEstate');
    },
    isComplete() {
      return (
        !isNaN(this.willMeta.pet_care_fund) || !this.willMeta.pet_care_fund
      );
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'complete_module',
        props: {
          will_id: this.willId,
          module_name: 'Family',
        },
      });
      this.$router.push({
        path: this.localePath('/will/estate'),
      });
    },
  },
};
