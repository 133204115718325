
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppGlossaryPanel',
  computed: {
    ...mapGetters('application/ui', ['glossaryPanelIsOpen']),
    sortedTerms() {
      return Object.keys(this.$t('glossary')).sort((a, b) => a - b);
    },
  },
  methods: {
    ...mapActions('application/ui', ['hideGlossaryPanel']),
  },
};
