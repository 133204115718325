import { render, staticRenderFns } from "./professional-terms.vue?vue&type=template&id=489e4b21"
import script from "./professional-terms.vue?vue&type=script&lang=js"
export * from "./professional-terms.vue?vue&type=script&lang=js"
import style0 from "./professional-terms.vue?vue&type=style&index=0&id=489e4b21&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppBigNumber: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/BigNumber.vue').default,AppAccordion: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Accordion.vue').default,AppWillExecutorFeesTable: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/ExecutorFeesTable.vue').default,WpButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/Button.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppFormControl: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/FormControl.vue').default,AppForm: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Form.vue').default,AppBox: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Box.vue').default,AppPanel: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Panel.vue').default,AppWillExecutorFeesCalculator: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/ExecutorFeesCalculator.vue').default,AppWillExecutorFeesPanel: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/ExecutorFeesPanel.vue').default,AppModuleStep: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/ModuleStep.vue').default})
