
export default {
  name: 'AppUploadFileModal',
  props: {
    filesType: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    fileTypes: {
      type: Array,
      default: () => [],
    },
    renameable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stageHeight: 'auto',
      currentScene: 0,
      files: [],
      fileType: null,
      fileName: null,
    };
  },
  created() {
    this.hasMultipleFileTypesAvailable =
      this.fileTypes && this.fileTypes.length > 1;
    this.fileType = this.hasMultipleFileTypesAvailable
      ? null
      : this.fileTypes[0].value;
  },
  methods: {
    selectFiles(files) {
      this.files = files;
      this.fileName = files[0].name;
      this.nextStep();
    },
    submitFiles() {
      this.$emit('filesChange', this.files, this.fileType, this.fileName);
      this.closeModal();
    },
    closeModal() {
      this.$emit('closeModal');
    },
    setCurrentScene(scene) {
      this.currentScene = scene;
    },
    onBeforeLeave(el) {
      this.$refs.wiggleParent.$el.classList.add('--active');
      this.stageHeight = `${el.offsetHeight}px`;
    },
    onEnter(el) {
      this.stageHeight = `${el.offsetHeight}px`;
    },
    onAfterEnter() {
      this.$refs.wiggleParent.$el.classList.remove('--active');
    },
    nextStep() {
      switch (this.currentScene) {
        case 0:
          if (this.hasMultipleFileTypesAvailable) {
            this.setCurrentScene(1);
          } else if (this.renameable) {
            this.setCurrentScene(2);
          } else {
            this.submitFiles();
          }
          break;
        case 1:
          if (this.renameable) {
            this.setCurrentScene(2);
          } else {
            this.submitFiles();
          }
          break;
        case 2:
          this.submitFiles();
          break;
        default:
          break;
      }
    },
    previousStep() {
      switch (this.currentScene) {
        case 2:
          if (this.hasMultipleFileTypesAvailable) {
            this.setCurrentScene(1);
          } else {
            this.setCurrentScene(0);
          }
          break;
        default:
          this.setCurrentScene(0);
          break;
      }
    },
  },
};
