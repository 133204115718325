
export default {
  name: 'AppAccordionPanel',
  data() {
    return {
      expanded: false,
      icon: 'plus',
    };
  },
  computed: {
    expandedClass() {
      return this.expanded ? '--expanded' : '';
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
      this.icon = this.expanded ? 'plus' : 'minus';
    },
  },
};
