
export default {
  name: 'AppDataTableSortableHeader',
  props: {
    column: {
      type: String,
      required: true,
    },
    currentSortingColumn: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
