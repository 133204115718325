
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { COUNTRY, AU_STATES } from '@/utilities/constants';

export default {
  name: 'PagesAffiliatePoaAboutYourselfAddress',
  data() {
    return {
      COUNTRY,
      AU_STATES,
      poaNeedsUpdatingModalIsVisible: false,
      originalRegion: null,
    };
  },
  computed: {
    ...mapGetters('directory-person', ['userDetails']),
    ...mapFields('directory-person', [
      'user.residentialAddress.streetAddress',
      'user.residentialAddress.locality',
      'user.residentialAddress.postcode',
      'user.residentialAddress.region',
      'user.residentialAddress.country',
    ]),
    isComplete() {
      if (this.country === 'AU' && !this.region) {
        return false;
      }
      return !!(this.streetAddress && this.locality && this.postcode);
    },
  },
  mounted() {
    this.originalRegion = this.userDetails?.residentialAddress?.region;
  },
  methods: {
    ...mapActions('directory-person', ['updateDirectoryPerson']),
    hidePoaNeedsUpdatingModal() {
      this.poaNeedsUpdatingModalIsVisible = false;
      this.$nuxt.$emit('unlockForm');
    },
    async submit() {
      if (this.originalRegion != null && this.region !== this.originalRegion) {
        this.poaNeedsUpdatingModalIsVisible = true;
      } else {
        await this.updateUserAddress();
      }
    },
    async updateUserAddress() {
      await this.updateDirectoryPerson({
        id: this.userDetails.id,
        residentialAddress: {
          id: this.userDetails.residentialAddress.id,
          streetAddress: this.streetAddress,
          locality: this.locality,
          postcode: this.postcode,
          region: this.region,
          country: this.country,
        },
      });

      this.$nuxt.$emit('sendTrackingAttributes', {
        address: this.userDetails.residentialAddress.fullAddress,
      });

      this.$router.push({
        path: this.localePath('/poa/about-yourself/date-of-birth'),
      });
    },
  },
};
