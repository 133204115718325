
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AppCartItemRemovalModal',
  computed: {
    ...mapGetters('cart', [
      'productRemoval',
      'productDependencies',
      'isInCart',
    ]),
    dependentProductsInCart() {
      return this.productDependencies(this.productRemoval.code).filter((code) =>
        this.isInCart(code)
      );
    },
    productState() {
      const codesWithI18n = Object.keys(
        this.$t(`components.cartItemRemovalModal.${this.productRemoval.code}`)
      );
      const dependentCodeWithI18n = codesWithI18n.find((code) =>
        this.dependentProductsInCart.includes(code)
      );

      return `${this.productRemoval.code}.${
        dependentCodeWithI18n ?? 'default'
      }`;
    },
  },
  methods: {
    ...mapActions('cart', ['setProductRemoval']),
    async closeModal() {
      await this.setProductRemoval({ code: null, callback: null });
    },
    async removeCartItem() {
      this.productRemoval.callback &&
        (await this.productRemoval.callback(this.productRemoval.code));
      await this.closeModal();
    },
  },
};
