
import { mapGetters } from 'vuex';
import { error, will } from '@/mixins/apollo';
import REQUEST_PREPAID_CREMATION_QUOTE from '@/graphql/mutations/RequestPrepaidCremationQuote';
import PROCESS_BURIAL_LEAD from '@/graphql/mutations/ProcessBurialLead';
import GET_ACCOUNT_INTERESTS_BY_USER_ID from '@/graphql/queries/GetAccountInterestsByUserId';

export default {
  name: 'AppWillFuneralLeadAddOn',
  mixins: [will],
  apollo: {
    accountInterests: {
      query: GET_ACCOUNT_INTERESTS_BY_USER_ID,
      variables() {
        return {
          userId: this.userId,
        };
      },
      update: (data) => data.getAccountInterestsByUserId,
      skip() {
        return !this.userId;
      },
      error,
    },
  },
  props: {
    product: {
      required: true,
      validator: (value) => ['PREPAID_CREMATION', 'BURIAL'].includes(value),
      type: String,
    },
  },
  data() {
    return {
      isProcessingLead: false,
      accountInterests: [],
    };
  },
  computed: {
    ...mapGetters('directory-person', ['userDetails', 'userIsAustralian']),
    userHasLeadInterest() {
      return !!this.accountInterests.find(
        (interest) => interest.product === this.product
      );
    },
    hasCorrectDataForLead() {
      return (
        !!this.userIsAustralian &&
        !!this.userDetails.residentialAddress.postcode &&
        !!this.userDetails.residentialAddress.region &&
        !!this.userDetails.firstName &&
        !!this.userDetails.lastName
      );
    },
  },
  methods: {
    async processLead() {
      this.isProcessingLead = true;
      // Call requestPrepaidCremationQuote / processBurialLead
      let success = false;
      try {
        success = await this.$apollo.mutate({
          mutation:
            this.product === 'PREPAID_CREMATION'
              ? REQUEST_PREPAID_CREMATION_QUOTE
              : PROCESS_BURIAL_LEAD,
          variables: {
            userId: this.userId,
          },
        });
      } catch (err) {
        success = false;
        console.error(err);
      }
      if (!success) {
        this.$nuxt.$emit('snackbar', {
          text: this.$t(
            'pages.will.funeral.components.addon.hints.failure.requestQuote'
          ),
          type: 'error',
          icon: 'error',
        });
        this.isProcessingLead = false;
        return;
      }
      // Refetch account interests
      await this.$apollo.queries.accountInterests.refetch();
      this.isProcessingLead = false;
    },
  },
};
