
export default {
  name: 'PagesWill',
  layout: 'ruach',
  middleware: ['redirect-if-will-not-in-progress.middleware'],
  computed: {
    pageIsProductRoot() {
      return (
        this.$route.fullPath.endsWith('/will') ||
        this.$route.fullPath.endsWith('/will/')
      );
    },
  },
};
