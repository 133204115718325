
export default {
  name: 'AppLogo',
  props: {
    variant: {
      default: null,
      type: String,
    },
    markColor: {
      default: '#67D7C0',
      type: String,
    },
    textColor: {
      default: '#10132F',
      type: String,
    },
  },
  computed: {
    hideText() {
      return this.variant === 'mark';
    },
    width() {
      switch (this.variant) {
        case 'mark':
          return 74.2;
        case 'legal':
          return 457;
        default:
          return 301;
      }
    },
  },
};
