
export default {
  name: 'AppFixedBox',
  props: {
    layout: {
      default: 'bottom-right',
      validator: (value) => ['bottom-left', 'bottom-right'].includes(value),
      type: String,
    },
    intercomOffset: {
      default() {
        return this.layout === 'bottom-right';
      },
      type: Boolean,
    },
    visibility: {
      default: 'hide-at-top',
      validator: (value) => ['show-always', 'hide-at-top'].includes(value),
      type: String,
    },
  },
  data: () => ({
    windowScrolled: false,
  }),
  computed: {
    classes() {
      return [
        `--${this.layout}`,
        {
          '--intercom-offset': this.intercomOffset,
          '--hide-at-top': this.visibility === 'hide-at-top',
        },
      ];
    },
  },
};
