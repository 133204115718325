
export default {
  name: 'AppFormRow',
  props: {
    halfWidth: {
      default: false,
      type: Boolean,
    },
    inline: {
      deafult: false,
      type: Boolean,
    },
    loose: {
      deafult: false,
      type: Boolean,
    },
  },
};
