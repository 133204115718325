
import { mapGetters } from 'vuex';
import { formatPrice } from '@/utilities';

export default {
  name: 'AppCheckoutSummary',
  props: {
    items: {
      type: Array,
      required: true,
    },
    discountCode: {
      type: String,
      default: null,
    },
    useCart: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('product-prices', ['subscriptionRenewalPrice']),
    ...mapGetters('cart', ['showSubscriptionAutoRenewalInCheckout']),
    isFree() {
      return this.items.length && this.totalFinalPrice === 0;
    },
    totalBasePrice() {
      return this.items.reduce((total, current) => {
        return total + current.basePrice;
      }, 0);
    },
    totalFinalPrice() {
      return this.items.reduce((total, current) => {
        return total + current.finalPrice;
      }, 0);
    },
    isUpdateCardDetails() {
      // Bad code, refactor this later
      return this.items.some(
        (item) => item.product === 'UPDATE_CREDIT_CARD_DETAILS'
      );
    },
    useQuantityLayout() {
      return this.collatedItems.some((item) => item.count > 1);
    },
    collatedItems() {
      const products = [];
      const collatedItems = [];
      this.items.forEach((item) => {
        const product = item.product;
        const productIndex = products.indexOf(product);
        if (productIndex > -1) {
          item.count = collatedItems[productIndex].count + 1;
        } else {
          products.push(product);
          item.count = 1;
          collatedItems.push(item);
        }
      });
      return collatedItems;
    },
  },
  methods: {
    itemIsFirstYearSubscription(item) {
      return (
        item.product === 'SUBSCRIPTION' &&
        item.finalPrice < this.subscriptionRenewalPrice
      );
    },
    removeCoupon() {
      this.$emit('removeCoupon', null);
    },
    discountHasBeenAppliedToProduct(basePrice, finalPrice) {
      return basePrice > finalPrice;
    },
    formatPrice,
  },
};
