
export default {
  name: 'AppVaultItem',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
