
import { mapActions } from 'vuex';

import GENERATE_2FA from '@/graphql/mutations/GenerateTwoFactorSecret';
import ACTIVATE_2FA from '@/graphql/mutations/ActivateTwoFactor';
import ACTIVATE_2FA_LEGACY from '@/graphql/mutations/ActivateTwoFactorCode';
import { parseJwtTokenAndReturnPayload } from '@/utilities';

export default {
  name: 'PagesAffiliateLogIn2faSetup',
  middleware: ['redirect-if-logged-in.middleware'],
  data() {
    return {
      ACTIVATE_2FA,
      ACTIVATE_2FA_LEGACY,
      twoFactorCode: '',
      image: '',
    };
  },
  computed: {
    variables() {
      return {
        twoFactorCode: this.twoFactorCode,
        sessionToken: this.$route.query.sessionToken,
        state: this.$route.query.state,
      };
    },
  },
  async mounted() {
    this.$analytics.reset();

    if (!this.$route.query.sessionToken) {
      this.$router.push({
        path: this.localePath('/log-in'),
      });
    }
    const { data } = await this.$apollo.mutate({
      mutation: GENERATE_2FA,
      variables: {
        sessionToken: this.$route.query.sessionToken,
      },
    });
    this.image = data.generateTwoFactorSecret.image;
  },
  methods: {
    ...mapActions(['setTwoFactorEnabled']),
    done({ data }) {
      if (data.activateTwoFactor?.success) {
        const { sessionToken: modifiedSessionToken } = data.activateTwoFactor;
        try {
          const { continue_uri: continueUri } =
            parseJwtTokenAndReturnPayload(modifiedSessionToken);
          const redirectUrl =
            continueUri +
            '?' +
            new URLSearchParams({
              sessionToken: modifiedSessionToken,
              state: this.$route.query.state,
            }).toString();
          window.location.href = redirectUrl;
        } catch (error) {
          throw new Error('Invalid token passed');
        }
      }
    },
  },
};
