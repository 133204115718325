
export default {
  name: 'AppPromotionCard',
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    newPrice: {
      type: Number,
      required: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    thumbnailStyles() {
      return {
        background: `url(${this.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: this.vertical ? '180px' : '100%',
        borderBottomLeftRadius: this.vertical
          ? 0
          : ' var(--border-radius-small)',
        borderTopRightRadius: this.vertical ? ' var(--border-radius-small)' : 0,
        padding: `var(--base-margin-tiny) var(--base-margin-medium)`,
      };
    },
    newPriceString() {
      return this.newPrice === 0 ? 'Free' : `$${this.newPrice}`;
    },
  },
};
