
import { mapGetters, mapActions } from 'vuex';
import { will } from '@/mixins/apollo';

export default {
  name: 'AppAccountInvite',
  mixins: [will],
  data() {
    return {
      partnerInviteSending: false,
      friendInvites: [],
    };
  },
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    ...mapGetters('checkout', ['invitedByPartner']),
    ...mapGetters('invites', ['invites', 'publicInviteCode']),
    ...mapGetters('directory-person', [
      'userDetails',
      'userPartner',
      'contacts',
    ]),
    hasPurchasedPartnerWill() {
      return (this.purchasedProducts || []).includes('PARTNER_WILL');
    },
    hasInvitedPartner() {
      return !!this.invites.find((i) => i.type === 'PARTNER');
    },
    partnerEmail() {
      return this.userPartner?.defaultEmailAddress;
    },
    partnerInvites() {
      return this.invites.filter((invite) => invite.type === 'PARTNER');
    },
    inviteLink() {
      return `https://safewill.com/i/${this.publicInviteCode}`;
    },
  },
  mounted() {
    this.getPublicInviteCode(!!this.userDetails.firstName);
  },
  methods: {
    ...mapActions('invites', ['getPublicInviteCode']),
  },
};
