
export default {
  name: 'AppDataTable',
  props: {
    currentSortingColumn: {
      type: Array,
      required: false,
      default: () => [],
    },
    sortableColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    rowCount: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      shadow: false,
    };
  },
  computed: {
    classes() {
      return {
        'shadow-sm': this.shadow,
      };
    },
  },
  methods: {
    changeSortDirection(name) {
      this.$emit('sortColumnClicked', name);
    },
  },
};
