import { render, staticRenderFns } from "./ProductGateway.vue?vue&type=template&id=1648a2cc"
import script from "./ProductGateway.vue?vue&type=script&lang=js"
export * from "./ProductGateway.vue?vue&type=script&lang=js"
import style0 from "./ProductGateway.vue?vue&type=style&index=0&id=1648a2cc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppProgressCircle: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/ProgressCircle.vue').default,AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,WpButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/Button.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppTooltip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Tooltip.vue').default})
