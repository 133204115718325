
export default {
  name: 'AppIcon',
  props: {
    id: {
      type: String,
      default: 'blank',
    },
    size: {
      default: null,
      type: String,
    },
    theme: {
      default: null,
      type: String,
    },
    shadow: {
      default: false,
      type: Boolean,
    },
    inline: {
      default: false,
      type: Boolean,
    },
    alignRight: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    dimensions() {
      switch (this.size) {
        case 'enormous':
          return 3;
        case 'huge':
          return 2.5;
        case 'large':
          return 2;
        case 'small':
          return 1.25;
        case 'tiny':
          return 0.75;
        default:
          return 1.5;
      }
    },
    isLoadingIcon() {
      return this.id === 'loading';
    },
  },
};
