
import { Portal } from 'portal-vue';
import VDismiss from 'vue-dismiss';

export default {
  name: 'AppPanel',
  components: {
    Portal,
  },
  mixins: [VDismiss],
  props: {
    isDismissable: {
      default: false,
      type: Boolean,
    },
    title: {
      default: null,
      type: String,
    },
    hasHeader: {
      default: true,
      type: Boolean,
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keyHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyHandler);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    keyHandler(e) {
      switch (e.keyCode) {
        case 27: // ESC
          e.preventDefault();
          this.close();
          break;
        default:
          break;
      }
    },
  },
};
