
import GET_BULK_COUPONS_QUERY from '@/graphql/queries/GetBulkCoupons';
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';
import { saveBlobToFile } from '@/utilities';

export default {
  name: 'AppBulkDownloadCouponModal',
  apollo: {
    partnerships: {
      query: GET_PARTNERSHIPS_QUERY,
      fetchPolicy: 'network-only',
      update: (data) => data?.getPartnerships,
    },
  },
  data: () => ({
    loading: false,
    checkboxProductsMap: {
      WILL: false,
      POA: false,
      PARTNER_WILL: false,
    },
    formData: {
      partnershipSlug: null,
      createdAtMinTime: null,
      createdAtMaxTime: null,
      description: null,
      countryCode: null,
      singleUse: null,
      active: true,
    },
  }),
  computed: {
    partnershipsOptions() {
      return (this.partnerships || []).map((partnership) => {
        return {
          text: partnership.name,
          value: partnership.slug,
        };
      });
    },
  },
  methods: {
    close() {
      this.loading = false;
      this.$emit('closeModal');
    },
    async onSubmit() {
      this.loading = true;
      try {
        const {
          data: { getBulkCoupons },
        } = await this.$apollo.query({
          query: GET_BULK_COUPONS_QUERY,
          variables: {
            ...this.formData,
          },
        });

        const content = 'Code\n' + getBulkCoupons.join('\n');
        const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
        saveBlobToFile(
          blob,
          `${this.formData.partnershipSlug}-codes-${this.formData.createdAtMinTime}-to-${this.formData.createdAtMaxTime}.csv`
        );

        this.close();
      } catch (err) {
        console.error(err);
        this.$emit('failedDownload');
        this.close();
      }
    },
  },
};
