
export default {
  name: 'AppPasswordInput',
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      default: null,
      type: String,
    },
    placeholder: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    icon() {
      return this.showPassword ? 'eye-closed' : 'eye-open';
    },
    tooltipContent() {
      return `${this.showPassword ? 'Hide' : 'Show'} password`;
    },
    type() {
      return this.showPassword ? 'text' : 'password';
    },
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
