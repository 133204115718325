
import { mapActions, mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/medical';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaMedicalIndex',
  mixins: [user, will],
  apollo: {
    poaMedicalMeta: {
      ...queries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setData(data.poa);
      },
    },
  },
  data() {
    return {
      loading: false,
      poaMedicalMeta: {
        has_attorney: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('directory-person', ['userState']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        poaMedicalMeta: this.poaMedicalMeta,
      });
    },
    isComplete() {
      return (
        this.poaMedicalMeta &&
        (this.poaMedicalMeta.has_attorney === true ||
          this.poaMedicalMeta.has_attorney === false)
      );
    },
  },
  methods: {
    ...mapActions('poa', ['setData']),
    async onSubmit() {
      this.loading = true;
      await this.$apollo.mutate(
        mutations.updatePoaMeta(this.poaId, {
          key: 'has_attorney',
          value: this.poaMedicalMeta.has_attorney ? 'true' : 'false',
          category: 'MEDICAL',
        })
      );

      if (!this.poaMedicalMeta.has_attorney) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'skip_module',
          props: {
            poa_id: this.poaId,
            module_name: 'Medical',
          },
        });
      }

      const path = this.localePath(this.next);
      await this.$router.push({ path });
    },
  },
};
