
export default {
  name: 'AppDataTableFilter',
  props: {
    active: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    label: {
      type: String,
      required: true,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        label: {
          'opacity-50 hover:opacity-100': !this.active,
        },
        underline: {
          'translate-filter-indicator': !this.active,
        },
      };
    },
  },
};
