
import { convertIsoDateToLegacyDate } from '@/utilities';

export default {
  name: 'OrganismsContactCard',
  props: {
    contact: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alertMessage: {
      type: Object,
      default: null,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    contactIsInvite() {
      return !this.contact.firstName || !this.contact.lastName;
    },
    alertIsVisible() {
      return this.alertMessage?.content.trim().length > 0 && !this.disabled;
    },
    tooltipHeader() {
      return this.alertMessage?.header || '';
    },
    tooltipContent() {
      return this.alertMessage?.content || '';
    },
    boxBorderColor() {
      return this.alertIsVisible ? 'cherry' : this.selected ? 'slate' : 'iron';
    },
    selectedIcon() {
      if (this.multiple) {
        if (this.selected) {
          return 'pi-check-square';
        }
        return 'pi-stop';
      } else {
        if (this.selected) {
          return 'pi-check-circle';
        }
        return 'pi-circle';
      }
    },
    identifiers() {
      return [
        ...(this.contact.dateOfBirth
          ? [
              {
                icon: 'event',
                text: convertIsoDateToLegacyDate(this.contact.dateOfBirth),
              },
            ]
          : []),

        ...(this.contact.defaultPhoneNumber
          ? [
              {
                icon: 'phone',
                text: this.contact.defaultPhoneNumber.formatted,
              },
            ]
          : []),
        ...(this.contact.defaultEmailAddress
          ? [
              {
                icon: 'email-i',
                text: this.contact.defaultEmailAddress,
              },
            ]
          : []),
        ...(this.contact.residentialAddress?.streetAddress
          ? [
              {
                icon: 'house',
                text: this.contact.residentialAddress.fullAddress,
              },
            ]
          : []),
      ].slice(0, 2);
    },
    relationshipWithUserIsNotUnknown() {
      return (
        this.contact.relationshipWithUser &&
        this.contact.relationshipWithUser?.toLowerCase() !== 'unknown'
      );
    },
    readableRelationship() {
      const convertToReadableText = (inputString) => {
        const words = inputString
          .split('_')
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          );

        const result = words.join(' ');

        return result;
      };
      return convertToReadableText(this.contact.relationshipWithUser);
    },
  },
  methods: {
    removeContact() {
      this.$emit('remove', this.contact);
    },
    editContact() {
      this.$emit('edit', this.contact);
    },
    toggleSelect() {
      if (this.disabled || this.alertIsVisible) {
        return;
      }
      this.$emit('selectionChanged', this.contact.id);
    },
  },
};
