
export default {
  name: 'PagesAffiliateDevPrimeVue',
  data() {
    return {
      premium: false,
      freeThemes: [],
      premiumTheme: null,
      version: null,
      text: null,
      number: null,
      textarea: null,
      stickyToast: false,
      versions: [
        { name: 'PrimeVUE 1.3.2', value: 1 },
        { name: 'PrimeVUE 2.10.4', value: 2 },
        { name: 'PrimeVUE 3.5.0', value: 3 },
        { name: 'PrimeVUE 4.x', value: 4 },
      ],
    };
  },
  methods: {
    clickListener() {
      console.log('clickListener');
    },
    submitListener() {
      console.log('submitListener');
    },
    addToastNotification({ severity, title }) {
      this.$toast.addDefault({
        severity,
        title,
        message: 'Message Content',
        sticky: this.stickyToast,
      });
    },
  },
};
