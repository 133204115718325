
export default {
  name: 'AppProgressBar',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    styles() {
      return {
        '--progress': this.progress,
      };
    },
  },
};
