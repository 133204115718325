
import { debounce } from 'throttle-debounce';
import { isNumeric } from '@/utilities';

const PROGRESSIVE_FEE = [
  {
    min: 0,
    max: 1000000,
    percentage: 0.03,
    baseFee: 0,
  },
  {
    min: 1000000,
    max: 2000000,
    percentage: 0.025,
    baseFee: 30000,
  },
  {
    min: 2000000,
    max: Infinity,
    percentage: 0.011,
    baseFee: 55000,
  },
];
const DEFAULT_DATA = {
  isHomeOwner: null,
  homeOwnership: null,
  homePercentage: null,
  isSpouseOrChildrenInheriting: null,
  homeValuation: null,
  otherAssetsValuation: null,
  totalEstateValue: null,
  feesEstimate: null,
};

export default {
  name: 'AppWillExecutorFeesCalculator',
  props: {
    displayPanel: {
      type: Boolean,
      default: false,
    },
    toggleDisplayPanel: {
      type: Function,
      default: () => ({}),
    },
  },
  data: () => ({ ...DEFAULT_DATA }),
  computed: {
    isHomeFullyOrPartlyOwned() {
      return (
        this.isHomeOwner && ['fully', 'partly'].includes(this.homeOwnership)
      );
    },
    formattedTotalEstateValue() {
      return this.totalEstateValue
        ? this.totalEstateValue
            .toLocaleString('en-AU', {
              style: 'currency',
              currency: 'AUD',
              minimumFractionDigits: 2,
            })
            .replace(/[.,]0+$/, '')
        : '--';
    },
    formattedPercentageEstimate() {
      return isNumeric(this.feesEstimate)
        ? this.$t(
            'pages.will.executors.feesCalculatorPanel.estimatePercentage',
            {
              percentage: this.feesEstimate,
            }
          )
        : '--';
    },
    canCalculateEstimate() {
      if (this.isHomeOwner === false) return this.otherAssetsValuation !== null;
      if (this.isHomeOwner === true) {
        if (this.homeOwnership === 'jointly')
          return this.otherAssetsValuation !== null;
        if (
          this.homeOwnership === 'fully' ||
          (this.homeOwnership === 'partly' && this.homePercentage !== null)
        ) {
          if (this.isSpouseOrChildrenInheriting !== null)
            return (
              this.homeValuation !== null && this.otherAssetsValuation !== null
            );
        }
      }

      return false;
    },
    homeValue() {
      if (this.homeOwnership === 'partly') {
        return Number(this.homeValuation) * (this.homePercentage / 100);
      }

      return Number(this.homeValuation);
    },
    homeOwnerOptions() {
      return [
        {
          label: this.$t('forms.labels.yes'),
          value: true,
        },
        {
          label: this.$t('forms.labels.no'),
          value: false,
        },
      ];
    },
    homeOwnershipOptions() {
      return Object.entries(
        this.$t(
          'pages.will.executors.feesCalculatorPanel.questions.homeOwnership.options'
        )
      ).map(([key, value]) => ({
        label: value,
        value: key,
      }));
    },
    inheritorOptions() {
      return [
        {
          label: this.$t(
            'pages.will.executors.feesCalculatorPanel.questions.inheritor.options.spouseOrChildren'
          ),
          value: true,
        },
        {
          label: this.$t(
            'pages.will.executors.feesCalculatorPanel.questions.inheritor.options.someoneElse'
          ),
          value: false,
        },
      ];
    },
  },
  mounted() {
    this.resetData();
  },
  methods: {
    onIsHomeOwnerChange(value) {
      this.isHomeOwner = value;
      this.onHomeOwnershipChange(null);
    },
    onHomeOwnershipChange(value) {
      this.homeOwnership = value;

      if (!this.isHomeFullyOrPartlyOwned) {
        this.homePercentage = null;
        this.isSpouseOrChildrenInheriting = null;
        this.homeValuation = null;
      }
      this.onValuationChange();
    },
    onInheritorChange(value) {
      this.isSpouseOrChildrenInheriting = value;
      this.onValuationChange();
    },
    onValuationChange: debounce(300, function () {
      this.calculateEstimate();
    }),
    calculateTotalEstateValue() {
      return (
        parseFloat(this.homeValue || 0) +
        parseFloat(this.otherAssetsValuation || 0)
      );
    },
    calculateOtherEstateFee(totalEstate) {
      const fee = PROGRESSIVE_FEE.find(
        ({ min, max }) => totalEstate >= min && totalEstate <= max
      );

      return fee ? fee.percentage * (totalEstate - fee.min) + fee.baseFee : 0;
    },
    calculateTotalFee() {
      if (this.otherAssetsValuation === null) return null;

      let totalEstateValue = parseFloat(this.otherAssetsValuation);
      let homeFee = 0;

      if (this.isHomeFullyOrPartlyOwned && !this.isSpouseOrChildrenInheriting) {
        totalEstateValue += this.homeValue;
      } else if (
        this.isHomeFullyOrPartlyOwned &&
        this.isSpouseOrChildrenInheriting
      ) {
        homeFee = this.homeValue * 0.01;
      }

      return this.calculateOtherEstateFee(totalEstateValue) + homeFee;
    },
    calculateEstimate() {
      if (this.canCalculateEstimate) {
        const totalFee = this.calculateTotalFee();
        const totalEstimate = this.calculateTotalEstateValue();
        const percentageEstimate = (totalFee / totalEstimate) * 100;

        this.totalEstateValue = totalEstimate;
        this.feesEstimate =
          totalFee === null ? null : Math.round(percentageEstimate * 100) / 100;

        this.$nuxt.$emit('sendTrackingAttributes', {
          fee_calculator_home_value: parseFloat(this.homeValuation || 0),
          fee_calculator_home_percentage: parseFloat(this.homePercentage || 0),
          fee_calculator_other_estate_value: parseFloat(
            this.otherAssetsValuation || 0
          ),
          fee_calculator_owns_home: this.isHomeOwner,
        });
      } else {
        this.totalEstateValue = null;
        this.feesEstimate = null;
      }
    },
    resetData() {
      Object.entries(DEFAULT_DATA).forEach(([key, value]) => {
        this[key] = value;
      });
    },
  },
};
