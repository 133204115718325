import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=57040748"
import script from "./Form.vue?vue&type=script&lang=js"
export * from "./Form.vue?vue&type=script&lang=js"
import style0 from "./Form.vue?vue&type=style&index=0&id=57040748&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WpButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/Button.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default})
