
export default {
  name: 'AppDataTableRow',
  props: {
    to: {
      default: null,
      type: String,
    },
  },
  methods: {
    onClick() {
      if (this.to) {
        this.$router.push({
          path: this.localePath(this.to),
        });
      }
    },
  },
};
