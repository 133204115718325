import { render, staticRenderFns } from "./CouponProduct.vue?vue&type=template&id=20de107e"
import script from "./CouponProduct.vue?vue&type=script&lang=js"
export * from "./CouponProduct.vue?vue&type=script&lang=js"
import style0 from "./CouponProduct.vue?vue&type=style&index=0&id=20de107e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppFormControl: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/FormControl.vue').default,AppCard: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/Card.vue').default})
