
import VERIFY_TWO_FACTOR_CODE_QUERY from '@/graphql/queries/VerifyTwoFactorCode';
import IS_TWO_FACTOR_ENABLED_QUERY from '@/graphql/queries/IsTwoFactorEnabled';
import { parseJwtTokenAndReturnPayload, formatError } from '@/utilities';

export default {
  name: 'PagesAffiliateLogIn2faIndex',
  middleware: ['redirect-if-logged-in.middleware'],
  data() {
    return {
      twoFactorCode: '',
      errorMessage: null,
      loading: false,
    };
  },
  async mounted() {
    this.$analytics.reset();
    this.loading = true;
    const { data } = await this.$apollo.query({
      query: IS_TWO_FACTOR_ENABLED_QUERY,
      variables: {
        sessionToken: this.$route.query.sessionToken,
      },
    });
    if (!data.isTwoFactorEnabled) {
      this.$router.replace({
        path: '/log-in/2fa/setup',
        query: this.$route.query,
      });
    }
    this.loading = false;
  },
  methods: {
    async verify2faCode() {
      this.errorMessage = null;
      this.loading = true;
      const state = this.$route.query.state;
      try {
        const { data } = await this.$apollo.query({
          query: VERIFY_TWO_FACTOR_CODE_QUERY,
          variables: {
            sessionToken: this.$route.query.sessionToken,
            twoFactorCode: this.twoFactorCode,
            state,
          },
        });
        const { sessionToken: modifiedSessionToken } = data.verifyTwoFactorCode;

        try {
          const { continue_uri: continueUri } =
            parseJwtTokenAndReturnPayload(modifiedSessionToken);
          const redirectUrl =
            continueUri +
            '?' +
            new URLSearchParams({
              sessionToken: modifiedSessionToken,
              state,
            }).toString();
          window.location.href = redirectUrl;
        } catch (error) {
          throw new Error('Invalid token passed');
        }
      } catch (error) {
        this.errorMessage = formatError(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
