
import { hexToRgb } from '@/utilities';
export default {
  name: 'AppBox',
  props: {
    fit: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    tight: {
      type: Boolean,
      default: false,
    },
    sideline: {
      type: Boolean,
      default: false,
    },
    flush: {
      type: Boolean,
      default: false,
    },
    spacious: {
      type: Boolean,
      default: false,
    },
    thick: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: null,
    },
    border: {
      type: String,
      default: null,
    },
    overflow: {
      type: String,
      default: null,
    },
    dashed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    backgroundStyle() {
      if (this.background) {
        if (this.background.includes('#')) {
          return hexToRgb(this.background);
        }
        return `var(--${this.background})`;
      }
      return null;
    },
    borderColor() {
      if (this.border) {
        if (this.border.includes('#')) {
          return hexToRgb(this.border);
        }
        return `rgb(var(--${this.border}))`;
      }
      return null;
    },
    borderWidth() {
      if (this.thick) {
        return '0.125rem';
      } else if (this.border) {
        return '0.0625rem';
      }
      return null;
    },
    styles() {
      return {
        '--border-color': this.borderColor,
        '--border-width': this.borderWidth,
        '--background': this.backgroundStyle,
        '--margin-y': this.flush ? '0' : null,
        '--height': this.fit ? '100%' : null,
        '--overflow': this.overflow,
      };
    },
  },
};
