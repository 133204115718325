
import { mapActions, mapGetters } from 'vuex';
import { guardians, user, will } from '@/mixins/apollo';
import { RELATIONSHIP_TYPE, VAULT_ITEM_KEY } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateWillGuardiansPrimary',
  mixins: [guardians, user, will],
  data() {
    return {
      RELATIONSHIP_TYPE,
      VAULT_ITEM_KEY,
      selectedContacts: [],
      isHelpRequired: false,
    };
  },
  computed: {
    ...mapGetters('directory-person', ['contacts']),
    ...mapGetters('tool', ['willHelpAssessmentTool']),
    ...mapGetters('will-tiers', ['isHelpAvailable']),
    isComplete() {
      return !!(
        this.willMeta.has_primary_guardian === false ||
        this.selectedContacts.length
      );
    },
    excludedContacts() {
      return this.backupGuardians.map((guardian) => {
        return guardian.directoryPerson.id;
      });
    },
    nextStep() {
      return this.localePath(
        this.willMeta.has_primary_guardian
          ? '/will/guardians/backup'
          : '/will/guardians/your-pets'
      );
    },
    variables() {
      return {
        id: this.willId,
        meta: this.willMeta.toArray(),
      };
    },
  },
  watch: {
    primaryGuardians() {
      this.updateSelectedContact();
    },
  },
  mounted() {
    this.updateSelectedContact();
  },
  methods: {
    ...mapActions('vault-item', ['saveHelpRequiredVaultItem']),
    updateSelectedContact() {
      if (this.primaryGuardians?.length) {
        this.selectedContacts = this.contacts
          .filter((contact) => {
            return this.isPrimaryGuardian(contact);
          })
          .map((contact) => contact.id);
      }
    },
    async done() {
      const saveVaultItem = this.isHelpAvailable
        ? this.saveHelpRequiredVaultItem({
            tool: this.willHelpAssessmentTool,
            vaultItemKey: VAULT_ITEM_KEY.DECISION_MAKER_GUARDIANSHIP_CHILD,
            helpRequired: this.isHelpRequired,
          })
        : Promise.resolve();

      if (!this.willMeta.has_primary_guardian) {
        this.willMeta.has_backup_guardian = null;
        await this.updateWillMeta();
        await Promise.all([this.removeAllGuardians(), saveVaultItem]);
        this.$router.push({
          path: this.nextStep,
        });
      } else {
        const contactsToRemove = [];
        const contactsToAdd = [];
        for (const contact of this.contacts) {
          const isPrimaryGuardian = this.isPrimaryGuardian(contact);
          const isSelected = this.selectedContacts.includes(contact.id);

          if (this.willMeta.has_primary_guardian && isSelected) {
            if (!isPrimaryGuardian) {
              contactsToAdd.push(contact);
            }
          } else if (isPrimaryGuardian) {
            contactsToRemove.push(contact);
          }
        }

        try {
          await Promise.all(
            contactsToRemove.map((contact) => this.removeGuardian(contact))
          );
          await Promise.all([
            ...contactsToAdd.map((contact) =>
              this.addGuardian(contact, 'primary')
            ),
            saveVaultItem,
          ]);
          this.updateSelectedContact();
          this.$router.push({
            path: this.nextStep,
          });
        } catch (error) {
          console.error(error);
          this.$refs.form.setGenericError('assigningContacts');
        }
      }
    },
  },
};
