
export default {
  name: 'WillTierTag',
  props: {
    isWillTierTwo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    content() {
      return this.$t(
        this.isWillTierTwo
          ? 'commons.willTiers.willTierTwo'
          : 'commons.willTiers.will'
      );
    },
  },
};
