
export default {
  name: 'OrganismsContactSelection',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    contacts: {
      type: Array,
      required: true,
    },
    contactLabel: {
      type: String,
      default: 'contact',
    },
    max: {
      type: Number,
      default: Infinity,
    },
    identifierRequirements: {
      type: Array,
      default: () => [],
    },
    overEighteen: {
      type: Boolean,
      default: false,
    },
    emailRequiredForInvite: {
      type: Boolean,
      default: false,
    },
    excludedContacts: {
      type: Array,
      default: () => [],
    },
    excludedContactsLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedContactIds: this.value.map((contact) => contact.id),
      searchQuery: '',
    };
  },
  computed: {
    title() {
      return `${this.$t('components.contactListStatus.select')} ${
        this.contactLabel
      }`;
    },
    selectedContacts() {
      return this.contacts.filter((contact) =>
        this.selectedContactIds.includes(contact.id)
      );
    },
    filteredContacts() {
      if (!this.searchQuery.trim()) return this.contacts;
      return this.contacts.filter((contact) => {
        const name = `${contact.firstName} ${contact.lastName}`;
        const includeName = name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
        const includeEmail = contact.defaultEmailAddress
          ?.toLowerCase()
          .includes(this.searchQuery.toLowerCase());

        const includePhone = contact.defaultPhoneNumber?.formatted
          ?.toLowerCase()
          .includes(this.searchQuery.toLowerCase());

        const includeAddress = contact.residentialAddress?.fullAddress
          ?.toLowerCase()
          .includes(this.searchQuery.toLowerCase());

        return includeName || includeEmail || includePhone || includeAddress;
      });
    },
    saveDisabled() {
      return this.selectedContactIds.length === 0;
    },
    contactsEmptyIsVisible() {
      return (
        this.filteredContacts.length === 0 &&
        this.searchQuery.trim().length === 0
      );
    },
    contactsNotFoundIsVisible() {
      return (
        this.filteredContacts.length === 0 && this.searchQuery.trim().length > 0
      );
    },
  },
  methods: {
    selectNewContact(contact) {
      if (this.selectedContacts.length < this.max) {
        this.selectedContactIds.push(contact.id);
      }
    },
    close() {
      this.$emit('close');
    },
    removeFromSelectedContacts(contactId) {
      this.selectedContactIds = this.selectedContactIds.filter(
        (id) => id !== contactId
      );
    },
    save() {
      const contacts = this.contacts.filter((contact) =>
        this.selectedContactIds.includes(contact.id)
      );
      this.$emit('input', contacts);
      this.$emit('close');
    },
    addContact() {
      this.$emit('addContact');
    },
    editContact(contact) {
      this.$emit('editContact', contact);
    },
  },
};
