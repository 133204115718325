
export default {
  name: 'AppDataTableButton',
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        'text-teal-200': !this.disabled,
        'text-grey-300': this.disabled,
        'cursor-wait': this.loading,
      };
    },
  },
};
