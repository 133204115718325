
import { mapActions, mapGetters } from 'vuex';
import {
  queries as metaQueries,
  mutations as metaMutations,
} from '@/modules/apollo-queries/poa-meta';
import { queries as attorneysQueries } from '@/modules/apollo-queries/poa-attorneys';
import { prevStep, nextStep } from '@/modules/poa-flow/medical';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaMedicalLimitations',
  mixins: [user, will],
  apollo: {
    attorneys: {
      ...attorneysQueries.getAttorneys({ power: 'MEDICAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setAttorneys(data.poa);
      },
    },
    poaMedicalMeta: {
      ...metaQueries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setData(data.poa);
      },
    },
  },
  data() {
    return {
      attorneys: undefined,
      selectedPrimaryAttorneyPersonIds: [],
      poaMedicalMeta: {
        has_limitations: undefined,
        limitations: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('directory-person', ['userState']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
        poaMedicalMeta: this.poaMedicalMeta,
        numPrimaryAttorneysSelected:
          this.selectedPrimaryAttorneyPersonIds.length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
      });
    },
    isComplete() {
      return (
        this.poaMedicalMeta.has_limitations === false ||
        (this.poaMedicalMeta.has_limitations === true &&
          this.poaMedicalMeta.limitations?.length > 0)
      );
    },
  },
  watch: {
    attorneys(attorneys) {
      if (attorneys.length) {
        this.selectedPrimaryAttorneyPersonIds = attorneys
          .filter((attorney) => attorney.role === 'PRIMARY')
          .map((attorney) => attorney.directoryPerson.id);
      }
    },
  },
  methods: {
    ...mapActions('poa', ['setAttorneys', 'setData']),
    onChange(value) {
      if (value === false) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      await this.$apollo.mutate(
        metaMutations.updatePoaMetas(this.poaId, [
          {
            key: 'has_limitations',
            value: this.poaMedicalMeta.has_limitations ? 'true' : 'false',
            category: 'MEDICAL',
          },
          {
            key: 'limitations',
            value: this.poaMedicalMeta.has_limitations
              ? this.poaMedicalMeta.limitations
              : null,
            category: 'MEDICAL',
          },
        ])
      );
      const path = this.localePath(this.next);
      await this.$router.push({ path });
    },
  },
};
