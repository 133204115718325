import { render, staticRenderFns } from "./icons.vue?vue&type=template&id=b20a5cb8"
import script from "./icons.vue?vue&type=script&lang=js"
export * from "./icons.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,RuIcon: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Icon/index.vue').default})
