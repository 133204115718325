
import { mapActions, mapGetters } from 'vuex';
import { subscription } from '@/mixins/apollo';

export default {
  name: 'AppWillSubscriptionExpiredModal',
  mixins: [subscription],
  computed: {
    ...mapGetters('application/ui', ['subscriptionExpiredModalIsOpen']),
  },
  methods: {
    ...mapActions('application/ui', ['hideSubscriptionExpiredModal']),
    async submit() {
      this.hideSubscriptionExpiredModal();
      await this.unlockAndCheckout();
    },
  },
};
