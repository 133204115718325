
import { mapActions, mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/financial';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaFinancialLimitations',
  mixins: [user, will],
  apollo: {
    poaFinancialMeta: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setData(data.poa);
      },
    },
  },
  data() {
    return {
      poaFinancialMeta: {
        has_limitations: undefined,
        limitations: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('directory-person', ['userState']),
    previous() {
      return prevStep({
        addressState: this.userState,
        currentUrl: window.location.pathname,
        poaFinancialMeta: this.poaFinancialMeta,
        numPrimaryAttorneysSelected: this.attorneys?.filter(
          (attorney) => attorney.role === 'PRIMARY'
        ).length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
      });
    },
    isComplete() {
      return (
        this.poaFinancialMeta.has_limitations === false ||
        (this.poaFinancialMeta.has_limitations === true &&
          this.poaFinancialMeta.limitations?.length > 0)
      );
    },
  },
  methods: {
    ...mapActions('poa', ['setData']),
    onChange(value) {
      if (value === false) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMetas(this.poaId, [
          {
            key: 'has_limitations',
            value: this.poaFinancialMeta.has_limitations ? 'true' : 'false',
            category: 'FINANCIAL',
          },
          {
            key: 'limitations',
            value: this.poaFinancialMeta.has_limitations
              ? this.poaFinancialMeta.limitations
              : null,
            category: 'FINANCIAL',
          },
        ])
      );

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'complete_module',
        props: {
          poa_id: this.poaId,
          module_name: 'Financial',
        },
      });

      const path = this.localePath(this.next.path);
      this.$router.push({ path });
    },
  },
};
