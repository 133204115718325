
import { mapGetters } from 'vuex';

export default {
  name: 'AppAffiliateLogo',
  computed: {
    ...mapGetters('application/affiliate', ['branding']),
    logo() {
      return this.$makePartnerLogoUrl(this.branding.logo);
    },
  },
};
