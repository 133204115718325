
import { mapGetters } from 'vuex';

export default {
  name: 'AppSnowflakesNotices',
  data() {
    return {
      showMaintenanceAlert: false,
    };
  },
  computed: {
    ...mapGetters('admin/masquerade', ['isMasquerading']),
    showNotices() {
      return (
        this.isMasquerading || this.$nuxt.isOffline || this.showMaintenanceAlert
      );
    },
  },
};
