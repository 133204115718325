
export default {
  name: 'AppBaseButton',
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    focusable: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    to: {
      default: null,
      type: String,
    },
    type: {
      default: 'button',
      type: String,
    },
  },
  computed: {
    classes() {
      return {
        'btn-disabled': this.disabled && !this.loading,
        'btn-focus': this.focusable,
        'cursor-wait': this.loading,
      };
    },
  },
  methods: {
    click() {
      if (!this.to && !this.loading) {
        this.$emit('click');
      }
    },
  },
};
