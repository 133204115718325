
import { mapGetters } from 'vuex';
import { will } from '@/mixins/apollo';

export default {
  name: 'AppAccountSentGifts',
  mixins: [will],
  computed: {
    ...mapGetters('invites', ['invites']),
    paidInvites() {
      return this.invites.filter((invite) => invite.type === 'PAID').reverse();
    },
  },
};
