
import { ValidationProvider } from 'vee-validate';
import { formatCurrency, isNullish } from '@/utilities';

export default {
  name: 'AppFormControl',
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      default: '',
      type: [String, Boolean, Number],
    },
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [String, Number],
      default: null,
    },
    max: {
      type: [String, Number],
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    pattern: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    option: {
      type: [String, Boolean],
      default: '',
    },
    options: {
      type: Array,
      default: null,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    allowOther: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      default: null,
      type: String,
    },
    customError: {
      default: null,
      type: String,
    },
    prefix: {
      default: null,
      type: String,
    },
    suffix: {
      default: null,
      type: String,
    },
    debounce: {
      default: 0,
      type: Number,
    },
    showError: {
      default: true,
      type: Boolean,
    },
    mode: {
      type: String,
      default: 'decimal',
    },
    minFractionDigits: {
      type: Number,
      default: null,
    },
    maxFractionDigits: {
      type: Number,
      default: null,
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      identifier: '',
      modelValue: this.value,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.input,
        blur: this.blur,
        change: this.change,
      };
    },
    isRequired() {
      if (this.rules) {
        return this.rules.includes('required');
      }
      return this.required;
    },
    selectOptions() {
      let options = [];
      if (typeof this.options[0] === 'string') {
        options = this.options.map((option) => {
          return {
            value: option,
            text: option,
          };
        });
      } else {
        options = this.options.map((option) => {
          return {
            value: option.value,
            text: this.$t(option.text),
            disabled: option.disabled,
          };
        });
      }
      if (!this.required && options[0] && options[0].value) {
        options.unshift({
          value: null,
          text: null,
        });
      }
      if (this.placeholder) {
        options.unshift({
          value: '',
          text: this.placeholder,
          disabled: true,
        });
      }
      if (this.allowOther) {
        options.push({
          value: 'OTHER',
          text: this.$t('components.formControl.other'),
        });
      }
      return options;
    },
    hasAddon() {
      return !!this.prefix || !!this.suffix;
    },
    classes() {
      return [
        { '--inline': ['radio', 'checkbox'].includes(this.type) },
        { '--required': this.isRequired },
        { '--uppercase': this.format === 'uppercase' },
        { '--horizontal': this.type === 'select-button' },
        { '--has-value': !isNullish(this.value) },
        { '--inline': ['radio', 'checkbox'].includes(this.type) },
      ];
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.modelValue = value;
        if (this.type === 'date' && this.$refs.provider) {
          this.$refs.provider.validate();
        }
      },
    },
  },
  created() {
    this.identifier = this.id + '__' + Math.random().toString(36).slice(2);
  },
  mounted() {
    if (this.type === 'select-button') {
      const children = this.$refs?.formControlSelectButton?.$el?.children || [];
      for (let i = 0; i < children.length; i++) {
        children[i].id = `${this.id}__${this.selectOptions[i].value}`;
      }
    }
  },
  methods: {
    async manuallyValidate($event) {
      await this.$nextTick();
      this.$refs.provider.validate();
      this.input($event);
    },
    blur($event) {
      this.$emit('blur', $event);
    },
    input($event) {
      if ($event instanceof Event) {
        this.$emit('input', $event.target.value);
      } else {
        this.$emit('input', $event);
      }
    },
    change($event) {
      switch (this.format) {
        case 'number':
          this.modelValue = parseInt($event.target.value);
          break;
        case 'currency':
          this.modelValue = formatCurrency($event.target.value);
          break;
        case 'boolean':
          if (this.type === 'checkbox') {
            this.modelValue = $event.target.checked;
            break;
          }
          this.modelValue = $event.target.value === 'true';
          break;
        case 'date':
          this.modelValue = $event.target.value || null;
          break;
        case 'uppercase':
          this.modelValue = $event.target.value.trim().toUpperCase();
          break;
        default:
          this.modelValue = $event.target.value.trim();
      }
    },
    reset() {
      this.modelValue = '';
    },
    getError(errors) {
      const lastErrorKey = errors[errors.length - 1];
      if (!lastErrorKey) {
        return false;
      } else if (lastErrorKey.split(':').length > 1) {
        const [key, args] = lastErrorKey.split(':');
        return this.$t(key, { args });
      } else {
        return this.$t(lastErrorKey);
      }
    },
  },
};
