
import { mapGetters } from 'vuex';
import CREDIT_CARD_CHECKOUT_MUTATION from '@/graphql/mutations/CreditCardCheckout';
import UPDATE_CREDIT_CARD_DETAILS from '@/graphql/mutations/UpdateCreditCardDetails';
import { subscription, user, will } from '@/mixins/apollo';
import { formatError } from '@/utilities';

export default {
  name: 'AppCheckoutStripePayment',
  mixins: [subscription, user, will],
  props: {
    items: {
      required: true,
      type: Array,
    },
    discountCode: {
      default: null,
      type: String,
    },
    productsWithCustomPricesToken: {
      type: String,
      default: null,
    },
    actionType: {
      type: String,
      default: 'checkout',
    },
    expectedCostInCents: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      cardCvc: null,
      cardNumber: null,
      cardExpiry: null,
      elements: null,
      stripe: null,
      stripeClasses: {
        base: 'app:form__input-text',
        focus: 'input-focus',
        invalid: 'input-error',
      },
      stripeStyles: {
        base: {
          fontFamily: 'Larsseit, Helvetica, Arial',
          fontSize: '16px',
          fontWeight: '400',
          fontSmoothing: 'antialiased',
          lineHeight: '1.25',
          '::-ms-clear': {
            display: 'none',
          },
        },
      },
      errorMessage: null,
      paymentIsProcessing: false,
    };
  },
  computed: {
    ...mapGetters(['userId']),
  },
  watch: {
    paymentIsProcessing(value) {
      this.$emit('paymentProcessingChanged', value);
    },
  },
  async mounted() {
    if (typeof window !== 'undefined') {
      this.stripe = await this.$stripe.import();
      this.createStripeElements();
    }
  },
  methods: {
    createStripeElements() {
      this.elements = this.stripe.elements();
      this.cardNumber = this.elements.create('cardNumber', {
        classes: this.stripeClasses,
        style: this.stripeStyles,
        placeholder: '0000 0000 0000 0000',
        showIcon: true,
      });
      this.cardNumber.mount('#card-number');
      this.$emit('change', this.cardNumber);
      this.cardExpiry = this.elements.create('cardExpiry', {
        classes: this.stripeClasses,
        style: this.stripeStyles,
      });
      this.cardExpiry.mount('#card-expiry');
      this.cardCvc = this.elements.create('cardCvc', {
        classes: this.stripeClasses,
        style: this.stripeStyles,
      });
      this.cardCvc.mount('#card-cvc');
    },
    disabledStripeElements(disabled) {
      if (this.cardNumber) {
        this.cardNumber.update({ disabled });
      }
      if (this.cardExpiry) {
        this.cardExpiry.update({ disabled });
      }
      if (this.cardCvc) {
        this.cardCvc.update({ disabled });
      }
    },
    async submitPayment() {
      try {
        this.paymentIsProcessing = true;
        this.disabledStripeElements(true);
        const { token, error } = await this.stripe.createToken(this.cardNumber);
        if (error) {
          throw error;
        } else if (token) {
          await this.processOrder(token);
          this.errorMessage = null;
          this.$emit('paymentSucceeded');
        }
      } catch (error) {
        this.errorMessage = formatError(error.message);
      } finally {
        this.paymentIsProcessing = false;
        this.disabledStripeElements(false);
      }
    },
    async processOrder(token) {
      if (this.actionType === 'checkout') {
        const {
          data: {
            creditCardCheckout: { success, message },
          },
        } = await this.$apollo.mutate({
          mutation: CREDIT_CARD_CHECKOUT_MUTATION,
          variables: {
            userId: this.userId,
            products:
              this.productsWithCustomPricesToken === null
                ? this.items.map(({ product }) => product)
                : [],
            expectedCostInCents: this.expectedCostInCents,
            cardToken: token.id,
            discountCode: this.discountCode,
            productsWithCustomPricesToken: this.productsWithCustomPricesToken,
          },
        });
        if (!success) {
          throw new Error(message);
        }
      } else {
        await this.$apollo.mutate({
          mutation: UPDATE_CREDIT_CARD_DETAILS,
          variables: {
            userId: this.userId,
            stripeCreditCardToken: token.id,
          },
        });
      }
    },
  },
};
