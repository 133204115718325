
export default {
  name: 'AppProductCard',
  props: {
    id: {
      type: String,
      required: true,
    },
    features: {
      type: Array,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    alwaysShowTag: {
      type: Boolean,
      default: false,
    },
    isFeaturesOpen: {
      type: Boolean,
      default: false,
    },
    howItWorks: {
      type: Object,
      default: null,
    },
  },
};
