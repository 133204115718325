
import { debounce } from 'throttle-debounce';
import { mapGetters } from 'vuex';
import { PortalTarget } from 'portal-vue';
import { isModeratorOrHigher } from '@/utilities';

export default {
  name: 'AdminLayout',
  components: {
    PortalTarget,
  },
  data() {
    return {
      portalObserver: null,
      hasScrolled: false,
    };
  },
  computed: {
    ...mapGetters(['role', 'token']),
    authenticated() {
      return this.token && isModeratorOrHigher(this.role);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    document.documentElement.classList.add('app:admin-html');
    this.observePortal();
  },
  beforeDestroy() {
    if (this.portalObserver) {
      this.portalObserver.disconnect();
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
    document.documentElement.classList.remove('app:admin-html');
  },
  methods: {
    observePortal() {
      const callback = () => {
        this.$nextTick(() => {
          const hasOverlay = !!this.$refs.stack.$el.querySelector(
            '.-\\-screen-overlay'
          );
          document.documentElement.classList.toggle(
            '--stack-has-overlay',
            hasOverlay
          );
          this.$refs.stack.$el.classList.toggle('--has-overlay', hasOverlay);
        });
      };
      const portalObserver = new MutationObserver(callback);
      portalObserver.observe(this.$refs.stack.$el, { childList: true });
      this.portalObserver = portalObserver;
    },
    onScroll: debounce(100, function () {
      this.hasScrolled = window.scrollY > 0;
    }),
  },
};
