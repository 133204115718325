
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PagesAffiliateAdminEndOfLifeCategoriesIndex',
  layout: 'admin',
  data() {
    return {
      columns: ['Name', 'Parent Category', 'Created at', 'Updated at'],
      pageSize: 10,
      endOfLifeCategoryModalIsVisible: false,
    };
  },
  computed: {
    ...mapGetters('admin/end-of-life-categories', [
      'endOfLifeCategoriesPageIndex',
    ]),
    ...mapGetters('application/end-of-life', [
      'endOfLifeCategories',
      'endOfLifeCategoryTree',
    ]),
    pageOfEndOfLifeCategoryTree() {
      const offset = this.endOfLifeCategoriesPageIndex * this.pageSize;

      const flattenTree = (tree) => {
        const flatten = (category) => {
          const { subcategories, ...rest } = category;
          return [
            rest,
            ...(subcategories ? subcategories.flatMap(flatten) : []),
          ];
        };
        return tree.flatMap(flatten);
      };

      const endOfLifeCategoryTreeFlattened = flattenTree(
        this.endOfLifeCategoryTree
      );

      return endOfLifeCategoryTreeFlattened.length
        ? endOfLifeCategoryTreeFlattened.slice(offset, offset + this.pageSize)
        : [];
    },
  },
  async created() {
    await this.getEndOfLifeCategories();
  },
  methods: {
    ...mapActions('application/end-of-life', ['getEndOfLifeCategories']),
    ...mapActions('admin/end-of-life-categories', [
      'nextEndOfLifeCategoriesPage',
      'prevEndOfLifeCategoriesPage',
    ]),
    setShowNewEndOfLifeCategoryModal(isVisible) {
      this.endOfLifeCategoryModalIsVisible = isVisible;
    },
    getParentCategoryNameFromId(id) {
      const parentCategory = this.endOfLifeCategories.find(
        (category) => category.id === id
      );
      return parentCategory ? parentCategory.name : null;
    },
  },
};
