
import { mapGetters } from 'vuex';
export default {
  name: 'AppRecommendationProgress',
  computed: {
    ...mapGetters('user-onboarding', ['selectedVaultItemsCount']),
    progress() {
      let key;
      switch (true) {
        case this.selectedVaultItemsCount === 0:
          key = '0ComplexNeeds';
          break;
        case this.selectedVaultItemsCount === 1:
          key = '1ComplexNeeds';
          break;
        default:
          key = '2AndMoreComplexNeeds';
          break;
      }
      return this.$t(`components.recommendationProgress.${key}`);
    },
    icon() {
      return `/img/introduction/${this.progress.icon}`;
    },
    style() {
      return {
        '--progress': this.progress.percentage,
      };
    },
  },
};
