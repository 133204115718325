
import copy from 'copy-to-clipboard';

export default {
  name: 'AppAccountInviteLink',
  props: {
    hidden: {
      default: false,
      type: Boolean,
    },
    link: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      copied: false,
      disabled: true,
    };
  },
  methods: {
    copyLink() {
      this.sendTracking('link');
      copy(this.link);

      this.$nuxt.$emit('snackbar', {
        icon: 'success',
        type: 'success',
        text: this.$t('components.inviteLink.copied'),
      });
    },
    popup(url, title, w, h) {
      const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
      const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
      return window.open(
        url,
        title,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
      );
    },
    sendTracking(medium) {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'share_invite',
        props: {
          share_medium: medium,
        },
      });
    },
    shareWhatsApp() {
      this.sendTracking('whatsapp');
      this.popup(
        `https://api.whatsapp.com/send?text=${this.link.toLowerCase()}`,
        this.$t('components.inviteLink.shareOnWhatsApp'),
        600,
        600
      );
    },
    shareFacebook() {
      this.sendTracking('facebook');
      this.popup(
        `https://www.facebook.com/sharer/sharer.php?u=${this.link.toLowerCase()}`,
        this.$t('components.inviteLink.shareOnFacebook'),
        600,
        600
      );
    },
  },
};
