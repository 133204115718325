
import { hexToRgb } from '@/utilities';
export default {
  name: 'AppBadge',
  props: {
    background: {
      default: null,
      type: String,
    },
    solid: {
      default: false,
      type: Boolean,
    },
    color: {
      default: null,
      type: String,
    },
    size: {
      default: null,
      type: String,
    },
    inline: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    backgroundStyle() {
      if (this.background) {
        if (this.background.includes('#')) {
          return hexToRgb(this.background);
        }
        return `var(--${this.background})`;
      }
      return `var(--lighter)`;
    },
    colorStyle() {
      if (this.color) {
        if (this.color.includes('#')) {
          return hexToRgb(this.color);
        }
        return `var(--${this.color})`;
      }
      return `var(--darker)`;
    },
    dimensionStyle() {
      if (this.size === 'huge') {
        return 2.375;
      } else if (this.size === 'large') {
        return 1.2;
      } else if (this.size === 'small') {
        return 0.5;
      }
      return null;
    },
    displayStyle() {
      return this.inline ? 'inline-flex' : null;
    },
    styles() {
      return {
        '--background': this.backgroundStyle,
        '--color': this.colorStyle,
        '--dimensions': this.dimensionStyle,
        '--badge-display': this.displayStyle,
      };
    },
  },
};
