
import { mapGetters, mapActions } from 'vuex';
import cookies from 'js-cookie';
import { charityBackgrounds } from '@/modules/charityData';
import { sanitizeSignUpRedirect } from '@/utilities/allowed-redirects';
import {
  retrieveUtmQueryParameters,
  saveAnonymousProfile,
  getAnonymousProfile,
} from '@/utilities';

import tracking from '@/mixins/tracking';

import { GET_STARTED_VARIATION, COUNTRY } from '@/utilities/constants';
export default {
  name: 'PagesAffiliateGetStarted',
  mixins: [tracking],
  layout: 'minimal',
  middleware: ['maintenance', 'redirect-if-logged-in.middleware'],
  data() {
    return {
      isCharityBackgroundAvailable: false,
    };
  },
  computed: {
    ...mapGetters('application/affiliate', ['slug']),
    getStartedComponent() {
      if (
        this.$i18n.locale.toUpperCase() !== COUNTRY.AU ||
        this.$ff.getStartedVariation() === GET_STARTED_VARIATION.CONTROL ||
        this.slug !== 'sw' ||
        this.isCharityBackgroundAvailable
      ) {
        return 'AppGetStartedDefault';
      }

      return 'AppGetStartedExperiment';
    },
  },
  mounted() {
    // When triggering analytics from a mounted hook, it is important to ensure that the analytics library is ready before sending the event.
    this.$analytics.ready(() => {
      this.sendViewGetStartedEvent();
    });
    if (Object.keys(this.$route.query).length) {
      if (this.$route.query.charity) {
        const referralCharity = this.$route.query.charity;
        cookies.set('referral_charity', referralCharity);
        saveAnonymousProfile({ referralCharity });

        const charityBackground =
          charityBackgrounds[referralCharity.toLowerCase()];
        if (charityBackground) {
          this.isCharityBackgroundAvailable = true;
        }
      }

      if (this.$route.query.coupon) {
        const couponCode = this.$route.query.coupon;
        cookies.set('coupon', couponCode);
        saveAnonymousProfile({ couponCode });
      }

      if (this.$route.query.hasPartner) {
        cookies.set('has_partner', true);
      }

      if (this.$route.query.affiliateUserId) {
        const affiliateUserId = this.$route.query.affiliateUserId;
        cookies.set('affiliate_user_id', affiliateUserId);
      }

      if (this.$route.query.redirect) {
        const where = sanitizeSignUpRedirect(
          String(this.$route.query.redirect)
        );

        if (where) {
          cookies.set('signup_redirect', where);
        }
      }
      this.$router.replace({ path: this.localePath(this.$route.path) });
    } else {
      const referralCharity = cookies.get('referral_charity');
      const charityBackground = referralCharity
        ? charityBackgrounds[referralCharity.toLowerCase()]
        : null;
      if (charityBackground) {
        this.isCharityBackgroundAvailable = true;
      }
    }

    const utmParams =
      retrieveUtmQueryParameters(this.$route.query) ??
      getAnonymousProfile()?.utm;
    const hasUtmCookie = cookies.get('has_utm');

    if (utmParams) {
      saveAnonymousProfile({
        utm: utmParams,
      });
      cookies.set('has_utm', 'true');
      this.$auth.$storage.setUniversal(
        'redirect',
        `/?${new URLSearchParams(utmParams).toString()}`
      );
    } else if (!hasUtmCookie) {
      saveAnonymousProfile({ utm: {} });
    }

    this.setNavigationVariant('none');
  },
  methods: {
    ...mapActions('ruach', ['setNavigationVariant']),
    next() {
      this.$auth.loginWith('auth0', { params: { screen_hint: 'signup' } });
    },
    sendViewGetStartedEvent() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'view_get_started',
        props: {
          page_variant: this.$ff.getStartedVariation(),
        },
      });
    },
  },
};
