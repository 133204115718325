
export default {
  name: 'WpSelectButton',
  props: {
    options: {
      type: Array,
      required: true,
    },
    column: {
      default: false,
      type: Boolean,
    },
    id: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: [Boolean, String],
    },
  },
  data() {
    const selected =
      this.value !== null
        ? this.options.find((option) => option.value === this.value)
        : null;
    return {
      selected,
    };
  },
  computed: {
    modelValue: {
      get() {
        return this.selected;
      },
      set(value) {
        this.selected = value;
        this.$emit('input', value.value);
      },
    },
  },
  watch: {
    value(value) {
      if (this.checked) return;
      this.selected = this.options.find((option) => option.value === value);
    },
  },
  mounted() {
    const children = this.$refs?.selectButton?.$el?.children || [];
    for (let i = 0; i < children.length; i++) {
      children[i].id = `${this.id}__${this.options[i].value}`;
    }
  },
};
