
export default {
  name: 'AppProgressCircle',
  props: {
    progress: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      default: 40,
    },
    strokeMultiplier: {
      type: Number,
      default: 1,
    },
    dashed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const started = this.progress > 0;
    const radius = this.size / 2;
    const circumference = radius * 2 * Math.PI;
    return {
      started,
      radius,
      circumference,
    };
  },
  computed: {
    strokeDashoffset() {
      return Math.max(
        0,
        this.circumference - this.progress * this.circumference
      );
    },
  },
};
