
import { formatPrice } from '@/utilities';
export default {
  name: 'AppCheckoutItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    selectionCount: {
      type: Number,
      default: 0,
    },
    useCart: {
      type: Boolean,
      default: false,
    },
    useUpgradeCta: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    i18nBasePath() {
      if (this.$te(`cart.products.${this.item.product}.checkoutItem`)) {
        return `cart.products.${this.item.product}.checkoutItem`;
      }
      return `cart.products.${this.item.product}`;
    },
    isSelected() {
      return !!this.selectionCount;
    },
    isPayLater() {
      return this.item?.payLaterPrice > 0;
    },
    payLaterPrice() {
      return this.item?.payLaterPrice
        ? formatPrice(this.item.payLaterPrice)
        : 0;
    },
  },
  methods: {
    formatPrice,
  },
};
