
import { mapActions, mapGetters } from 'vuex';
import {
  queries as metaQueries,
  mutations as metaMutations,
} from '@/modules/apollo-queries/poa-meta';
import { queries as attorneysQueries } from '@/modules/apollo-queries/poa-attorneys';
import { prevStep, nextStep } from '@/modules/poa-flow/medical';

import SET_ATTORNEYS from '@/graphql/mutations/SetAttorneys';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaMedicalSubstitutes',
  mixins: [user, will],
  apollo: {
    attorneys: {
      ...attorneysQueries.getAttorneys({ power: 'MEDICAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setAttorneys(data.poa);
      },
    },
    poaMedicalMeta: {
      ...metaQueries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setData(data.poa);
      },
    },
  },
  data() {
    return {
      attorneys: undefined,
      selectedPrimaryAttorneyPersonIds: [],
      poaMedicalMeta: {
        has_substitute: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('directory-person', ['userState']),
    multipleSubstituteOptions() {
      return [
        {
          label: this.$t('pages.poa.medical.substitutes.options.yes', {
            substitute: this.canHaveMultipleSubstitutes
              ? this.$t('texts.sentences.substituteDecisionMakers')
              : this.$t('texts.sentences.aSubstituteDecisionMaker'),
          }),
          value: true,
        },
        {
          label: this.$t('pages.poa.medical.substitutes.options.no', {
            substitute: this.canHaveMultipleSubstitutes
              ? this.$t('texts.sentences.anySubstituteDecisionMakers')
              : this.$t('texts.sentences.aSubstituteDecisionMaker'),
          }),
          value: false,
        },
      ];
    },
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
        numPrimaryAttorneysSelected:
          this.selectedPrimaryAttorneyPersonIds.length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
        poaMedicalMeta: this.poaMedicalMeta,
        numPrimaryAttorneysSelected:
          this.selectedPrimaryAttorneyPersonIds.length,
      });
    },
    canHaveMultipleSubstitutes() {
      return ['act'].includes(this.userState);
    },
    primaryAttorneyPeopleIds() {
      return this.attorneys
        ?.filter((attorney) => attorney.role === 'PRIMARY')
        ?.map((attorney) => attorney.directoryPerson.id);
    },
    isComplete() {
      return (
        this.poaMedicalMeta &&
        (this.poaMedicalMeta.has_substitute === true ||
          this.poaMedicalMeta.has_substitute === false)
      );
    },
  },
  watch: {
    attorneys(attorneys) {
      if (attorneys.length) {
        this.selectedPrimaryAttorneyPersonIds = attorneys
          .filter((attorney) => attorney.role === 'PRIMARY')
          .map((attorney) => attorney.directoryPerson.id);
      }
    },
  },
  methods: {
    ...mapActions('poa', ['setAttorneys', 'setData']),
    async onSubmit() {
      await this.$apollo.mutate(
        metaMutations.updatePoaMeta(this.poaId, {
          key: 'has_substitute',
          value: this.poaMedicalMeta.has_substitute ? 'true' : 'false',
          category: 'MEDICAL',
        })
      );
      if (!this.poaMedicalMeta.has_substitute) {
        await this.$apollo.mutate({
          mutation: SET_ATTORNEYS,
          variables: {
            directoryPersonIds: [],
            power: 'MEDICAL',
            role: 'SECONDARY',
            poaId: this.poaId,
          },
        });
      }
      const path = this.localePath(this.next);
      this.$router.push({ path });
    },
  },
};
