
import { mapGetters } from 'vuex';
import { executors, user } from '@/mixins/apollo';
import { RELATIONSHIP_TYPE } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateWillExecutorsPrimary',
  mixins: [executors, user],
  data() {
    return {
      RELATIONSHIP_TYPE,
      selectedContacts: [],
    };
  },
  computed: {
    ...mapGetters('directory-person', ['userIsAustralian', 'contacts']),
    isComplete() {
      return !!this.selectedContacts.length;
    },
    excludedContacts() {
      return this.backupExecutors.map((executor) => {
        return executor.directoryPerson.id;
      });
    },
    previousStep() {
      return this.userIsAustralian
        ? `/will/executors/options`
        : `/will/executors`;
    },
  },
  watch: {
    primaryExecutors() {
      this.updateSelectedPrimaryExecutors();
    },
  },
  created() {
    this.updateSelectedPrimaryExecutors();
  },
  methods: {
    updateSelectedPrimaryExecutors() {
      this.selectedContacts = this.contacts
        .filter((contact) => {
          return this.primaryExecutors.find(
            (executor) => contact.id === executor.directoryPerson.id
          );
        })
        .map((contact) => contact.id);
    },
    async submit() {
      try {
        await Promise.all(
          this.contacts.map((contact) => {
            const isPrimaryExecutor = this.isPrimaryExecutor(contact);
            const isSelected = this.selectedContacts.includes(contact.id);
            if (isSelected) {
              if (!isPrimaryExecutor) {
                return this.addExecutor(contact.id, 'primary');
              }
            } else if (isPrimaryExecutor) {
              return this.removeExecutor(contact);
            }
            return Promise.resolve();
          })
        );
        this.$router.push({
          path: this.localePath('/will/executors/backup'),
        });
      } catch (error) {
        console.error(error);
        this.$refs.form.setGenericError('addingContacts');
      }
    },
  },
};
