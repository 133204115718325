
export default {
  name: 'AppCheckoutBreadcrumbs',
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    steps() {
      return this.crumbs.map((crumb) => crumb.text);
    },
    activeStep() {
      return this.crumbs.findIndex((crumb) => crumb.state === 'active');
    },
  },
};
