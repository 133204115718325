
import { mapGetters } from 'vuex';
import { assets, user, will } from '@/mixins/apollo';
import { metaArrayToObject, objectToMetaArray } from '@/utilities';

export default {
  name: 'PagesAffiliateWillAssetsIndex',
  mixins: [assets, user, will],
  data() {
    return {
      defaultAsset: {},
      selectedAssets: [],
    };
  },
  computed: {
    ...mapGetters('directory-person', ['userIsAustralian']),
    isComplete() {
      return !!(
        this.willMeta.has_assets === false || this.selectedAssets.length
      );
    },
  },
  watch: {
    assets(assets) {
      if (assets.length && !this.selectedAssets.length) {
        this.selectedAssets = assets.map(({ id, meta }) => {
          return {
            id,
            meta: metaArrayToObject(meta),
          };
        });
      }
    },
  },
  methods: {
    addFinancialInput() {
      this.selectedAssets.push({
        meta: {
          description: '',
          type: '',
        },
      });
    },
    async removeFinancialInput(asset) {
      if (this.isAsset(asset)) {
        await this.removeAsset(asset);
      }

      this.selectedAssets.splice(this.selectedAssets.indexOf(asset), 1);
    },
    async done() {
      for (const asset of this.selectedAssets) {
        const isAsset = this.isAsset(asset);
        const meta = objectToMetaArray(asset.meta);

        if (this.willMeta.has_assets) {
          if (isAsset) {
            await this.updateAsset(asset, meta);
          } else {
            await this.addAsset(meta);
          }
        } else if (isAsset) {
          await this.removeAsset(asset);
        }
      }

      this.$router.push({
        path: this.localePath('/will/assets/liabilities'),
      });
    },
  },
};
