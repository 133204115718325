
export default {
  name: 'OrganismsContactListStatus',
  props: {
    max: {
      type: Number,
      default: Infinity,
    },
    empty: {
      type: Boolean,
      default: false,
    },
    contactLabel: {
      type: String,
      default: 'contact',
    },
    buttonVerbage: {
      type: String,
      default: 'select',
    },
    limitReached: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    text() {
      if (this.empty) {
        return this.$t('components.contactListStatus.noneSelected');
      }
      return `${this.$t('components.contactListStatus.add')} ${
        this.contactLabel
      }`;
    },
  },
};
