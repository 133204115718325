
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppWillSubmitHelperModal',
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    ...mapGetters('application/ui', ['submitHelperModalIsOpen']),
  },
  methods: {
    ...mapActions('application/ui', ['hideSubmitHelperModal']),
  },
};
