
import UNIQUE_EMAIL_QUERY from '@/graphql/queries/UniqueEmail';
import DUPLICATE_WILL from '@/graphql/mutations/DuplicateWill';

export default {
  name: 'AppPartnerModal',
  props: {
    showModal: {
      type: Boolean,
    },
    willId: {
      type: String,
      required: true,
    },
    originalUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: this.showModal,
      user: {
        email: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
      },
      contacts: [],
      charities: [],
      emailIsUnique: true,
      modalTitle: 'Partner details',
      duplicatingSuccessful: false,
      duplicatingWill: false,
      duplicatingStep: '',
      errors: [],
    };
  },
  watch: {
    emailIsUnique(isUnique) {
      if (!isUnique) {
        this.$refs.form.$refs.observer.setErrors({
          email: [this.$t('components.adminPartnerModal.emailAlreadyTaken')],
        });
      }
    },
  },
  mounted() {
    this.initUserDetail();
    this.checkEmailIsUnique(this.user.email);
  },
  methods: {
    close() {
      this.$emit('close:partnerModal');
    },
    initUserDetail() {
      this.user = this.originalUser;
    },
    clearUserDetails() {
      Object.assign(this.user, {
        email: this.originalUser.email,
        firstName: '',
        lastName: '',
        dateOfBirth: '',
      });
    },
    async checkEmailIsUnique(email) {
      try {
        const response = await this.$apollo.query({
          query: UNIQUE_EMAIL_QUERY,
          variables: { email },
        });

        this.emailIsUnique = response.data.uniqueEmail.isUnique;
      } catch (e) {
        console.error(e.message);
      }
    },
    async onSubmit() {
      const observer = this.$refs.form.$refs.observer;
      const isValid = observer && (await observer.validate());

      if (isValid) {
        this.modalTitle = this.$t(
          'components.adminPartnerModal.duplicatingWill'
        );

        this.duplicatingWill = true;
        let response;

        try {
          response = await this.$apollo.mutate({
            mutation: DUPLICATE_WILL,
            variables: {
              willId: this.willId,
              ...this.user,
            },
          });
        } catch (err) {
          console.error(err);
          this.errors.push(
            this.$t('components.adminPartnerModal.errorMessages.getWillByEmail')
          );
          this.duplicatingSuccessful = false;
          return;
        }

        // Finish duplicating
        this.duplicatingStep = this.$t(
          'components.adminPartnerModal.willHasBeenCreated'
        );
        this.duplicatingSuccessful = true;
        this.clearUserDetails();

        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'clone_will',
          props: {
            will_id: this.willIid,
            clone_will_id: response?.id,
          },
        });
      }
    },
  },
};
