
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import UPDATE_END_OF_LIFE_CATEGORY from '@/graphql/mutations/UpdateEndOfLifeCategory';
export default {
  name: 'PagesAffiliateAdminEndOfLifeCategoriesId',
  components: {
    ValidationObserver,
  },
  layout: 'admin',
  data() {
    return {
      loading: false,
      endOfLifeCategory: null,
    };
  },
  computed: {
    ...mapGetters('application/end-of-life', ['endOfLifeCategories']),
    parentCategoryOptions() {
      return (
        Object.keys(this.endOfLifeCategories)
          .map((category) => ({
            text: this.endOfLifeCategories[category].name,
            value: this.endOfLifeCategories[category].id,
          }))
          .sort((a, b) => {
            if (a.text < b.text) return -1;
            if (a.text > b.text) return 1;
            return 0;
          }) || []
      );
    },
  },
  async created() {
    await this.getEndOfLifeCategories();
    this.endOfLifeCategory = {
      ...this.endOfLifeCategories[this.$route.params.id],
    };
  },
  methods: {
    ...mapActions('application/end-of-life', ['getEndOfLifeCategories']),
    ...mapActions('admin/end-of-life-categories', ['updateEndOfLifeCategory']),
    async updateCategory() {
      this.loading = true;
      const isValid =
        this.$refs.observer && (await this.$refs.observer.validate());
      if (!isValid) {
        this.loading = false;
        return;
      }
      this.$apollo
        .mutate({
          mutation: UPDATE_END_OF_LIFE_CATEGORY,
          variables: {
            ...this.endOfLifeCategory,
          },
        })
        .then(() => {
          this.loading = false;
          this.getEndOfLifeCategories();
        })
        .catch((e) => {
          console.error(e.message);
        });
    },
  },
};
