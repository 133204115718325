
import { debounce } from 'throttle-debounce';

export default {
  name: 'AppWillExecutorComparison',
  props: {
    forceSmall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableIsOffscreen: false,
      executorFeesPanelIsVisible: false,
    };
  },
  mounted() {
    this.measureAndAdjust();
    window.addEventListener('scroll', this.measureAndAdjust);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.measureAndAdjust);
  },
  methods: {
    toggleExecutorFeesPanel(event) {
      const id = event?.target.id;
      if (id) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'click',
          props: {
            element_id: `button_${id}`,
            page_path: this.$route.path,
          },
        });
      }
      this.executorFeesPanelIsVisible = !this.executorFeesPanelIsVisible;
    },
    measureAndAdjust: debounce(100, function () {
      if (this.$refs.options) {
        const bottomOfTable =
          this.$el.getBoundingClientRect().top +
          this.$el.getBoundingClientRect().height -
          this.$refs.options.getBoundingClientRect().top;
        const bottomOfHeaders =
          this.$refs.options.getBoundingClientRect().top +
          this.$refs.options.getBoundingClientRect().height;
        this.tableIsOffscreen = bottomOfTable < bottomOfHeaders;
      }
    }),
  },
};
