
import { mapActions, mapGetters } from 'vuex';
import { queries as attorneysQueries } from '@/modules/apollo-queries/poa-attorneys';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/financial';

import SET_ATTORNEYS from '@/graphql/mutations/SetAttorneys';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaFinancialSubstitutes',
  mixins: [user, will],
  apollo: {
    attorneys: {
      ...attorneysQueries.getAttorneys({ power: 'FINANCIAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setAttorneys(data.poa);
      },
    },
    poaFinancialMeta: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setData(data.poa);
      },
    },
  },
  data() {
    return {
      attorneys: undefined,
      poaFinancialMeta: {
        has_substitute: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('directory-person', ['userState']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
        numPrimaryAttorneysSelected: this.attorneys?.filter(
          (attorney) => attorney.role === 'PRIMARY'
        ).length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        poaFinancialMeta: this.poaFinancialMeta,
        addressState: this.userState,
      });
    },
    canHaveMultipleSubstitutes() {
      return ['act'].includes(this.userState);
    },
    isComplete() {
      return (
        this.poaFinancialMeta &&
        (this.poaFinancialMeta.has_substitute === true ||
          this.poaFinancialMeta.has_substitute === false)
      );
    },
  },
  methods: {
    ...mapActions('poa', ['setAttorneys', 'setData']),
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMeta(this.poaId, {
          key: 'has_substitute',
          value: this.poaFinancialMeta.has_substitute ? 'true' : 'false',
          category: 'FINANCIAL',
        })
      );
      if (!this.poaFinancialMeta.has_substitute) {
        await this.$apollo.mutate({
          mutation: SET_ATTORNEYS,
          variables: {
            directoryPersonIds: [],
            power: 'FINANCIAL',
            role: 'SECONDARY',
            poaId: this.poaId,
          },
        });
      }
      const path = this.localePath(this.next.path);
      this.$router.push({ path });
    },
  },
};
