
import { mapGetters } from 'vuex';

export default {
  name: 'PagesAffiliateOnboardingUser',
  layout: 'ruach',
  computed: {
    ...mapGetters('user-onboarding', [
      'currentStep',
      'currentStepData',
      'totalStepsCount',
      'progress',
      'progressBarIsVisible',
    ]),
  },
  mounted() {
    // Feature flags need to be refreshed as the values obtained on page load differ from those obtained after the loginUser call is completed.
    // Further explanation can be found on DEV-6056
    this.$ff.refreshFeatureFlags();
  },
};
