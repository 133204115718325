import { render, staticRenderFns } from "./ExecutorFeesCalculator.vue?vue&type=template&id=0e2b5937"
import script from "./ExecutorFeesCalculator.vue?vue&type=script&lang=js"
export * from "./ExecutorFeesCalculator.vue?vue&type=script&lang=js"
import style0 from "./ExecutorFeesCalculator.vue?vue&type=style&index=0&id=0e2b5937&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,WpVerticalSelectButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/VerticalSelectButton.vue').default,AppFormControl: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/FormControl.vue').default,AppBox: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Box.vue').default,AppWillExecutorFeesTable: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/ExecutorFeesTable.vue').default,AppTip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Tip.vue').default,AppAccordionPanel: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/AccordionPanel.vue').default})
