
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppEndOfLifeItemList',
  props: {
    layout: {
      type: String,
      default: 'DEFAULT',
    },
    institutionType: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('institutions', ['institutionsByType']),
  },
  async mounted() {
    if (this.institutionType) {
      await this.getInstitutionsByType(this.institutionType);
    }
  },
  methods: {
    ...mapActions('institutions', ['getInstitutionsByType']),
    getInstitutionNameById(institutionId) {
      const institution = this.institutionsByType[this.institutionType]?.find(
        ({ id }) => id === institutionId
      );
      return institution ? institution.name : '';
    },
    itemIntro(item) {
      if (item.id?.includes('fq_id')) {
        return item.data.hint;
      }
      const layoutToItemIntroTextMapping = {
        BIRTH_CERTIFICATE: item.data.registrationNumber,
        RESIDENCE: item.data.country,
        GOVERNMENT_CONCESSION: item.data.type,
        LICENCE: item.data.type,
        MEDICARE: item.data.cardNumber,
        PASSPORT: item.data.passportNumber,
        RELATIONSHIP: item.data.relationshipType,
        TAXATION: item.data.tfn,
        GUARDIANSHIP: item.data.notes,
        POWER_OF_ATTORNEY: item.data.type,
        WILL: item.data.provider,
        BANK_ACCOUNT: item.data.type,
        BUSINESS: item.data.type,
        FINANCIAL_ASSETS: item.data.type,
        INSURANCE: item.data.type,
        PROPERTY: item.data.type,
        SUPERANNUATION:
          this.getInstitutionNameById(item.data.institutionId) ||
          item.data.otherInstitutionName,
        TRUST: item.data.trustName,
        FINANCIAL_VALUABLE: item.data.item,
        VEHICLE: item.data.makeOrModel,
        EMPLOYMENT: item.data.employer,
        GOVERNMENT_BENEFIT: item.data.type,
        CREDIT_CARD: item.data.accountName,
        LOAN: item.data.type,
        DONATION:
          this.getInstitutionNameById(item.data.institutionId) ||
          item.data.otherInstitutionName,
        SUBSCRIPTION:
          this.getInstitutionNameById(item.data.institutionId) ||
          item.data.otherInstitutionName,
        UTILITY: item.data.type,
        MEDICAL_DEVICE: item.data.notes,
        ORGAN_DONATION: item.data.notes,
        FUNERAL_PREFERENCE: item.data.value || 'Not specified',
        FUNERAL_PREPAID_POLICY: item.data.value || 'Not specified',
        EMOTIONAL_MESSAGE: item.data.recipientName,
        EMOTIONAL_STORY: item.data.story,
        CONTACT_LAYOUT: item.data.contact,
      };
      return layoutToItemIntroTextMapping[this.layout] || '';
    },
    truncateText(text, maxLength) {
      let newText = text.substring(0, maxLength);
      if (text.length > maxLength) {
        newText = newText.substring(0, text.lastIndexOf(' ')) + '…';
      }
      return newText;
    },
  },
};
