
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'AppBaseInput',
  components: {
    ValidationProvider,
  },
  props: {
    classes: {
      default: null,
      type: String,
    },
    compact: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    ignoreDisabled: {
      default: false,
      type: Boolean,
    },
    error: {
      default: false,
      type: Boolean,
    },
    showError: {
      default: true,
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    max: {
      default: null,
      type: Number,
    },
    min: {
      default: null,
      type: Number,
    },
    maxlength: {
      default: null,
      type: Number,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      default: '',
      type: String,
    },
    rules: {
      default: null,
      type: String,
    },
    step: {
      default: null,
      type: Number,
    },
    type: {
      default: 'text',
      type: String,
    },
    inputmode: {
      default: null,
      type: String,
    },
    value: {
      default: '',
      type: [String, Number],
    },
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  computed: {
    states() {
      return {
        'input-compact': this.compact,
        'input-disabled': this.disabled && !this.ignoreDisabled,
        'input-filled': this.filled,
      };
    },
  },
  watch: {
    value(newValue) {
      this.modelValue = newValue;
    },
  },
  mounted() {
    this.$nuxt.$on(
      'resetInputs',
      (id) => id === this.id && (this.modelValue = '')
    );
  },
  methods: {
    customInteraction({ errors }) {
      if (errors.length) {
        return {
          on: ['input'],
        };
      }
      return {
        on: ['blur', 'input'],
      };
    },
    input() {
      if (this.inputmode === 'decimal') {
        this.modelValue = this.modelValue.replace(/[^\d.]/g, '');
      }
      this.$emit('input', this.modelValue);
    },
    getErrorMessage(errors) {
      const lastErrorKey = errors[errors.length - 1];
      if (lastErrorKey.split(':').length > 1) {
        const [key, args] = lastErrorKey.split(':');
        return this.$t(key, { args });
      } else {
        return this.$t(lastErrorKey);
      }
    },
  },
};
