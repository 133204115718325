
import { mapActions, mapGetters } from 'vuex';
import { formatPrice } from '@/utilities';

export default {
  name: 'AppWillPartnerWillAddOn',
  props: {
    pageRequirementsMet: {
      type: Boolean,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalIsVisible: false,
      productHasBeenAddedToCart: false,
      faqs: this.$t('components.partnerWillAddOn.modal.faqs'),
    };
  },
  computed: {
    ...mapGetters('cart', ['cartItemCodes', 'isInCart', 'canAddToCart']),
    ...mapGetters('directory-person', ['userIsAustralian']),
    ...mapGetters('application/affiliate', ['features']),
    ...mapGetters('product-prices', ['partnerWillPrice']),
    ...mapGetters('will-tiers', [
      'hasPurchasedWillProduct',
      'isWillProductInCart',
    ]),
    price() {
      return this.formatPrice(this.partnerWillPrice);
    },
    percentagePrice() {
      const calculatedPercentage = 100 - (this.partnerWillPrice / 16000) * 100;
      return `${Math.floor(calculatedPercentage)}%`;
    },
    isFree() {
      return this.partnerWillPrice === 0;
    },
    descriptors() {
      if (this.isFree) {
        return {
          headline: this.$t(
            `components.partnerWillAddOn.pages.${this.page}.freeHeadline`
          ),
          description: this.$t(
            `components.partnerWillAddOn.pages.${this.page}.freeDescription`
          ),
        };
      }
      return {
        headline: this.$t(
          `components.partnerWillAddOn.pages.${this.page}.headline`,
          {
            price: this.percentagePrice,
          }
        ),
        description: this.$t(
          `components.partnerWillAddOn.pages.${this.page}.description`,
          {
            price: this.percentagePrice,
          }
        ),
      };
    },
    productIsInCart() {
      return this.isInCart('PARTNER_WILL');
    },
    addonIsVisible() {
      return (
        this.userIsAustralian &&
        (this.canAddToCart('PARTNER_WILL') || this.productIsInCart) &&
        (this.pageRequirementsMet || this.productIsInCart)
      );
    },
  },
  methods: {
    formatPrice,
    ...mapActions('cart', ['addToCart']),
    async addPartnerWillToCartFromModal() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'add_on_actioned',
        props: {
          page_path: this.$route.path,
          product_code: 'PARTNER_WILL',
        },
      });
      await this.addPartnerWillToCart();
    },
    async addPartnerWillToCart() {
      if (!this.hasPurchasedWillProduct && !this.isWillProductInCart) {
        await this.addToCart({ codes: ['WILL'], showSnackbar: false });
      }
      await this.addToCart({ codes: ['PARTNER_WILL'], showSnackbar: false });
      this.modalIsVisible = false;
      this.productHasBeenAddedToCart = true;
    },
    showPartnerWillModal() {
      this.modalIsVisible = true;
    },
  },
};
