
import { debounce } from 'throttle-debounce';
export default {
  name: 'AppProductCards',
  data() {
    return {
      descriptionHeight: 0,
    };
  },
  mounted() {
    this.measureAndAdjust();
    window.addEventListener('resize', this.measureAndAdjust);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.measureAndAdjust);
  },
  methods: {
    measureAndAdjust: debounce(100, function () {
      this.descriptionHeight = 0;
      const descriptionHeights = [];
      this.$children.forEach(($child) => {
        descriptionHeights.push($child.$refs.description.clientHeight);
      });
      this.descriptionHeight = Math.max(...descriptionHeights);
    }),
  },
};
