
export default {
  name: 'AppBigNumber',
  props: {
    number: {
      required: true,
      type: String,
    },
    scale: {
      default: false,
      type: Boolean,
    },
  },
};
