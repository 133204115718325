
export default {
  name: 'AppCard',
  props: {
    title: {
      default: null,
      type: String,
    },
    tight: {
      default: false,
      type: Boolean,
    },
  },
};
