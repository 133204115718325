import { render, staticRenderFns } from "./UserHistoricalWills.vue?vue&type=template&id=9880a036"
import script from "./UserHistoricalWills.vue?vue&type=script&lang=js"
export * from "./UserHistoricalWills.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppDataTableCell: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/DataTableCell.vue').default,AppStatusChip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/StatusChip.vue').default,AppDataTableRow: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/DataTableRow.vue').default,AppDataTable: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/DataTable.vue').default})
