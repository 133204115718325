import { render, staticRenderFns } from "./ModuleCard.vue?vue&type=template&id=7c1ac61c"
import script from "./ModuleCard.vue?vue&type=script&lang=js"
export * from "./ModuleCard.vue?vue&type=script&lang=js"
import style0 from "./ModuleCard.vue?vue&type=style&index=0&id=7c1ac61c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,AppBadge: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Badge.vue').default,AppProgressCircle: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/ProgressCircle.vue').default,AppTooltip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Tooltip.vue').default,RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,WpButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/Button.vue').default,AppBox: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Box.vue').default})
