import { render, staticRenderFns } from "./phone.vue?vue&type=template&id=27a86c7e"
import script from "./phone.vue?vue&type=script&lang=js"
export * from "./phone.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppTip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Tip.vue').default,AppPhoneControl: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/PhoneControl.vue').default,AppForm: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Form.vue').default,AppModuleStep: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/ModuleStep.vue').default})
