
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillAboutYourselfName',
  mixins: [user, will],
  computed: {
    ...mapGetters('directory-person', ['userDetails']),
    ...mapFields('directory-person', [
      'user.firstName',
      'user.lastName',
      'user.middleName',
      'user.altFirstName',
      'user.altLastName',
      'user.altMiddleName',
    ]),
    isComplete() {
      return !!(
        this.firstName &&
        this.lastName &&
        (this.willMeta.has_alt_name === false ||
          (this.altFirstName && this.altLastName))
      );
    },
  },
  watch: {
    'willMeta.show_welcome_modal'(value) {
      if (value === true) {
        this.willMeta.show_welcome_modal = false;
        this.updateWillMeta();
      }
    },
  },
  methods: {
    ...mapActions('directory-person', ['updateDirectoryPerson']),
    async updateUserName() {
      this.updateWillMeta();
      if (!this.willMeta.has_alt_name) {
        this.altFirstName = null;
        this.altLastName = null;
        this.altMiddleName = null;
      }
      await this.updateDirectoryPerson({
        id: this.userDetails.id,
        firstName: this.firstName,
        lastName: this.lastName,
        middleName: this.middleName,
        altFirstName: this.altFirstName,
        altLastName: this.altLastName,
        altMiddleName: this.altMiddleName,
      });
      this.$nuxt.$emit('sendTrackingAttributes', {
        name: this.userDetails.fullName,
      });

      if (this.willMeta.has_alt_name) {
        const fullAltName = [
          this.userDetails.altFirstName?.trim(),
          this.userDetails.altMiddleName?.trim(),
          this.userDetails.altLastName?.trim(),
        ]
          .filter((name) => name)
          .join(' ');
        this.$nuxt.$emit('sendTrackingAttributes', {
          alternative_name: fullAltName,
        });
      }

      this.$router.push({
        path: this.localePath('/will/about-yourself/date-of-birth'),
      });
    },
  },
};
