import { render, staticRenderFns } from "./GiftWizard.vue?vue&type=template&id=3679dea4"
import script from "./GiftWizard.vue?vue&type=script&lang=js"
export * from "./GiftWizard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,WpButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/Button.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppTip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Tip.vue').default,AppFormControl: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/FormControl.vue').default,AppContactSelector: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/ContactSelector.vue').default,AppWillCharitySuggester: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/CharitySuggester.vue').default,AppWillCharitySelector: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/CharitySelector.vue').default,AppCharityPanel: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/CharityPanel.vue').default,AppWillTierTwoAddOn: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/WillTierTwoAddOn.vue').default,AppForm: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Form.vue').default,AppModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Modal.vue').default})
