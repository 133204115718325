import { render, staticRenderFns } from "./NavigationAdmin.vue?vue&type=template&id=25028a0e"
import script from "./NavigationAdmin.vue?vue&type=script&lang=js"
export * from "./NavigationAdmin.vue?vue&type=script&lang=js"
import style0 from "./NavigationAdmin.vue?vue&type=style&index=0&id=25028a0e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLogo: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Logo.vue').default,RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppNavigationItem: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/snowflakes/NavigationItem.vue').default})
