
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AppSnowflakesMasqueradeAlert',
  computed: {
    ...mapGetters(['email']),
  },
  methods: {
    ...mapActions('admin/masquerade', ['exitMasquerade']),
  },
};
