
export default {
  name: 'PagesAffiliateDevIcons',
  layout: 'ruach',
  data() {
    return {
      appIds: [
        'blank',
        'success',
        'error',
        'info',
        'info-i',
        'warning',
        'assets',
        'liabilities',
        'recurring',
        'end-of-life',
        'will',
        'arrow-left',
        'arrow-right',
        'arrow-right-circle',
        'arrow-up',
        'arrow-down',
        'funeral',
        'key-contacts',
        'pencil',
        'chevron-down',
        'chevron-up',
        'chevron-left',
        'chevron-right',
        'question',
        'phone',
        'home',
        'quill',
        'user',
        'users',
        'logout',
        'invite',
        'urn',
        'poa',
        'shield',
        'close',
        'add',
        'minus',
        'upload',
        'check',
        'dashboard',
        'estate-planning',
        'menu',
        'goto',
        'facebook',
        'whatsapp',
        'file-pdf',
        'file-image',
        'file-zip',
        'file',
        'download',
        'delete',
        'invites',
        'email',
        'copy',
        'gift',
        'feather',
        'money',
        'legal',
        'funeral',
        'medical',
        'personal',
        'videocam',
        'unlink',
        'completed',
        'glasses',
        'card',
        'chat',
        'tag',
        'security',
        'caret-down',
        'caret-up',
        'circle',
        'incomplete-pie',
        'incomplete-circle',
        'lock',
        'wallet',
        'cancel',
        'cart',
        'cog',
        'flag',
        'pet',
        'child',
        'ellipsis',
        'eye-open',
        'eye-closed',
        'charity',
        'discount',
        'loading',
        'clock',
        'magnifier',
        'external',
        'star',
        'inventory',
        'fact-check',
        'tea-cup',
        'filled-circle',
        'remove-i',
        'undo',
        'support',
        'verified',
      ],
      ruIds: [
        'blank',
        'success',
        'error',
        'info',
        'info-i',
        'warning',
        'assets',
        'liabilities',
        'recurring',
        'will',
        'arrow-left',
        'arrow-right',
        'arrow-up',
        'arrow-down',
        'funeral',
        'key-contacts',
        'pencil',
        'chevron-down',
        'chevron-up',
        'chevron-left',
        'chevron-right',
        'question',
        'phone',
        'home',
        'quill',
        'user',
        'users',
        'logout',
        'invite',
        'urn',
        'poa',
        'shield',
        'close',
        'add',
        'minus',
        'upload',
        'check',
        'dashboard',
        'estate-planning',
        'menu',
        'goto',
        'facebook',
        'whatsapp',
        'file-pdf',
        'file-image',
        'file-zip',
        'file',
        'download',
        'delete',
        'invites',
        'email',
        'copy',
        'gift',
        'feather',
        'money',
        'legal',
        'medical',
        'personal',
        'videocam',
        'unlink',
        'completed',
        'glasses',
        'card',
        'chat',
        'tag',
        'security',
        'caret-down',
        'caret-up',
        'circle',
        'incomplete-pie',
        'incomplete-circle',
        'lock',
        'wallet',
        'cancel',
        'cart',
        'cog',
        'flag',
        'pet',
        'child',
        'ellipsis',
        'eye-open',
        'eye-closed',
        'charity',
        'discount',
        'loading',
        'clock',
        'magnifier',
        'external',
        'star',
        'inventory',
        'fact-check',
        'tea-cup',
        'filled-circle',
        'shapes',
        'remove-user',
        'credit-card',
        'talk',
        'twofer',
        'time',
        'route',
        'sign',
        'file-add',
        'checkbox',
        'checkbox-checked',
        'radio',
        'radio-checked',
        'remove-i',
        'email-i',
        'lock-i',
        'event',
        'house',
      ],
    };
  },
};
