
export default {
  name: 'AppDataTableCell',
  props: {
    full: {
      default: false,
      type: Boolean,
    },
    truncate: {
      default: false,
      type: Boolean,
    },
    to: {
      default: null,
      type: String,
    },
  },
  computed: {
    classes() {
      return {
        'max-w-2xs': !this.full,
        truncate: this.truncate,
      };
    },
  },
  methods: {
    onClick() {
      if (this.to) {
        this.$router.push({
          path: this.localePath(this.to),
        });
      }
    },
  },
};
