
import { mapGetters, mapActions } from 'vuex';
import { toggleObjectInArray } from '@/utilities';

export default {
  name: 'AppCheckoutItemSelector',
  props: {
    items: {
      required: true,
      type: Array,
    },
    value: {
      default: () => [],
      type: Array,
    },
    useCart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      willTierTwoUpgradeModalIsVisible: false,
    };
  },
  computed: {
    ...mapGetters('cart', ['cartItemCodes', 'isInCart']),
  },
  methods: {
    ...mapActions('will-tiers', ['addWillTierTwoToCart', 'addWillToCart']),
    ...mapActions('cart', ['addToCart', 'removeFromCart']),
    isUpgradeable(item) {
      return item.product === 'WILL_TIER_TWO';
    },
    async addItem(item) {
      if (this.useCart) {
        await this.addToCart({ codes: [item.product], showSnackbar: true });
      } else {
        this.$emit('input', [...this.value, item]);
      }
    },
    async removeItem(item) {
      const productIndex = this.value.findIndex(
        ({ product }) => item.product === product
      );
      if (this.useCart) {
        await this.removeFromCart(item.product);
      } else {
        // Remove first instance of product from array
        this.$emit(
          'input',
          this.value.filter((_item, index) => index !== productIndex)
        );
      }
    },
    async toggleItem(item) {
      if (!this.useCart) {
        this.$emit(
          'input',
          toggleObjectInArray(item, [...this.value], 'product')
        );
      } else if (this.isInCart(item.product)) {
        await this.removeFromCart(item.product);
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'click',
          props: {
            element_id: `checkout__remove-${item.product}`,
            page_path: this.$route.path,
          },
        });
      } else {
        await this.addToCart({ codes: [item.product], showSnackbar: true });
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'click',
          props: {
            element_id: `checkout__add-${item.product}`,
            page_path: this.$route.path,
          },
        });
      }
    },
    async updateSelection(item, updateType) {
      try {
        switch (updateType) {
          case 'add':
            await this.addItem(item);
            break;
          case 'remove':
            await this.removeItem(item);
            break;
          case 'toggle':
          default:
            if (item.product === 'WILL_TIER_TWO') {
              if (this.isInCart('WILL_TIER_TWO')) {
                this.$nuxt.$emit('sendTrackingEvent', {
                  event: 'click',
                  props: {
                    element_id: 'checkout__WILL-TIER-TWO--remove',
                    page_path: this.$route.path,
                  },
                });
                this.addWillToCart();
              } else {
                this.$nuxt.$emit('sendTrackingEvent', {
                  event: 'click',
                  props: {
                    element_id: 'checkout__WILL-TIER-TWO--add',
                    page_path: this.$route.path,
                  },
                });
                this.addWillTierTwoToCart();
              }
              break;
            }
            await this.toggleItem(item);
            break;
        }
      } catch (error) {
        console.error(error);
        this.$nuxt.$emit('snackbar', {
          type: 'error',
          placement: 'top-right',
          relative: 'cart',
          text: this.$t('texts.sentences.somethingWentWrong'),
        });
      }
    },
    itemSelectionCount(itemProduct) {
      return this.useCart
        ? this.cartItemCodes.filter((code) => code === itemProduct).length
        : this.value.filter(({ product }) => product === itemProduct).length;
    },
    showWillTierTwoUpgradeModal() {
      this.willTierTwoUpgradeModalIsVisible = true;
    },
    closeWillTierTwoUpgradeModal() {
      this.willTierTwoUpgradeModalIsVisible = false;
    },
  },
};
