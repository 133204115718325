
import tracking from '@/mixins/tracking';

export default {
  name: 'LayoutOnboarding',
  mixins: [tracking],
  middleware: [
    'redirect-if-not-logged-in.middleware',
    'redirect-if-admin',
    'userOnboardingPageRequired',
  ],
};
