
import { mapActions } from 'vuex';
import CREATE_END_OF_LIFE_CATEGORY_MUTATION from '@/graphql/mutations/CreateEndOfLifeCategory';
export default {
  name: 'AppNewEndOfLifeCategoryModal',
  data: () => ({
    CREATE_END_OF_LIFE_CATEGORY_MUTATION,
    formData: {
      name: null,
      description: null,
      parentCategoryId: null,
      slug: null,
      explanation: null,
    },
  }),
  computed: {
    variables() {
      return {
        ...this.formData,
      };
    },
  },
  methods: {
    ...mapActions('application/end-of-life', ['getEndOfLifeCategories']),
    async update(store, res) {
      await this.getEndOfLifeCategories();
      await this.$router.push(
        this.localePath(
          `/admin/end-of-life-categories/${res.data.createEndOfLifeCategory.endOfLifeCategory.id}`
        )
      );
    },
  },
};
