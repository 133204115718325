
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AppWillWillTierTwoDowngradeModal',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('will-tiers', ['isLoading']),
  },
  methods: {
    ...mapActions('will-tiers', ['addWillToCart']),
    async downgradeWillTier() {
      await this.addWillToCart();
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
