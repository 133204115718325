
import { mapActions } from 'vuex';

export default {
  name: 'AppGlossaryTooltip',
  props: {
    term: {
      type: String,
      required: true,
    },
  },
  computed: {
    tooltipContent() {
      return `<div class="app:glossary-tooltip__tooltip">${this.$t('glossary')[this.term].desc}<div class="app:glossary-tooltip__tooltip-link">${this.$t('texts.sentences.clickToOpenGlossary')}</div></div>`;
    },
    popperOptions() {
      return {
        modifiers: {
          computeStyle: {
            gpuAcceleration: false,
          },
        },
      };
    },
  },
  mounted() {
    this.$el.addEventListener('mouseenter', () => {
      const tooltipArrow = window.document.querySelector('.p-tooltip-arrow');
      const tooltipText = window.document.querySelector('.p-tooltip-text');
      tooltipText.innerHTML = this.tooltipContent;

      tooltipText.style.backgroundColor = 'white';
      tooltipText.style.color = 'var(--text-color-secondary)';
      tooltipText.style.minWidth = '21rem';
      tooltipArrow.style.borderColor = 'transparent';
    });
  },
  destroyed() {
    this.$el.removeEventListener('mouseenter', () => {});
  },
  methods: {
    ...mapActions('application/ui', ['showGlossaryPanel']),
  },
};
