import { render, staticRenderFns } from "./Accordion.vue?vue&type=template&id=64a75ca0"
import script from "./Accordion.vue?vue&type=script&lang=js"
export * from "./Accordion.vue?vue&type=script&lang=js"
import style0 from "./Accordion.vue?vue&type=style&index=0&id=64a75ca0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,AppBox: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Box.vue').default})
