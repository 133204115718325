
export default {
  name: 'AppImage',
  props: {
    align: {
      default: null,
      type: String,
    },
    flush: {
      default: null,
      type: Boolean,
    },
    src: {
      required: true,
      type: String,
    },
    width: {
      default: null,
      type: Number,
    },
    height: {
      default: null,
      type: Number,
    },
  },
};
