
import { ValidationObserver } from 'vee-validate';
import { mapGetters } from 'vuex';
import { formatError, getSubordinateRoleOptionsByRole } from '@/utilities';
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';
import UPDATE_WILL_MUTATION from '@/graphql/mutations/UpdateWill';

export default {
  name: 'AppTransferWillModal',
  components: {
    ValidationObserver,
  },
  props: {
    willData: {
      required: true,
      type: Object,
    },
  },
  emits: ['submit', 'close'],
  data: () => ({
    transferAffiliateId: null,
    isLoading: false,
    error: null,
  }),
  apollo: {
    partnerships: {
      query: GET_PARTNERSHIPS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          filters: {
            affiliateEnabled: true,
          },
        };
      },
      update: (data) => data.getPartnerships,
    },
  },
  computed: {
    ...mapGetters(['role']),
    partnershipOptions() {
      if (!this.partnerships) return [];

      const partnershipOptions = this.partnerships.map((partnership) => {
        return {
          text: partnership.name,
          value: partnership.id,
        };
      });

      const nullOption = {
        text: 'None',
        value: null,
      };

      return [...partnershipOptions, nullOption];
    },
  },
  mounted() {
    this.transferAffiliateId = this.willData.transferAffiliateId;
  },
  methods: {
    getSubordinateRoleOptionsByRole,
    async submit() {
      this.error = null;
      const isValid =
        this.$refs.transferWillModalObserver &&
        (await this.$refs.transferWillModalObserver.validate());
      if (!isValid) {
        return;
      }

      try {
        this.isLoading = true;
        await this.$apollo.mutate({
          mutation: UPDATE_WILL_MUTATION,
          variables: {
            id: this.willData.id,
            meta: [],
            transferAffiliateId: this.transferAffiliateId,
          },
        });
        this.$emit('submit', this.transferAffiliateId);
        this.close();
      } catch (e) {
        this.error = formatError(e.message);
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.user = {};
      this.$refs.transferWillModalObserver.reset();
      this.$emit('close');
    },
  },
};
