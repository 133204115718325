
import { mapActions } from 'vuex';
import { will } from '@/mixins/apollo';

export default {
  name: 'AppWillDownloadFlaggedWillModal',
  mixins: [will],
  methods: {
    ...mapActions('will', {
      updateWillStatus: 'updateWillStatus',
      downloadWillAction: 'downloadWill',
    }),
    close() {
      this.$emit('closeModal');
    },
    async downloadWill() {
      await Promise.all([
        this.updateWillStatus('APPROVED'),
        this.refetchWill(),
      ]);

      await this.downloadWillAction();
      this.close();
    },
  },
};
