
export default {
  name: 'AtomsNameBadge',
  props: {
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    alert: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    initials() {
      return `${this.firstName[0]}${this.lastName[0]}`;
    },
    style() {
      return {
        '--background-color': this.alert
          ? 'rgb(var(--red))'
          : this.stringToColour(this.firstName + this.lastName),
      };
    },
  },
  methods: {
    stringToColour(str) {
      const colors = [
        '#98d4ff',
        '#93cbdc',
        '#bce6e1',
        '#91ddad',
        '#ffd58f',
        '#ffba9f',
        '#faa4a4',
        '#e79ab4',
        '#e199c9',
        '#d39ede',
      ];
      let hash = 0;
      str.split('').forEach((char) => {
        hash += char.charCodeAt(0);
      });
      return colors[hash % colors.length];
    },
    stringToHex(str) {
      let hash = 0;
      str.split('').forEach((char) => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash);
      });
      let colour = '#';
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        colour += value.toString(16).padStart(2, '0');
      }
      return colour;
    },
  },
};
