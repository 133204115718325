
import { ValidationObserver } from 'vee-validate';
import { mapGetters } from 'vuex';
import { formatError, getSubordinateRoleOptionsByRole } from '@/utilities';
import CREATE_USER_MUTATION from '@/graphql/mutations/CreateUser';
import CREATE_TOKENISED_USER_MUTATION from '@/graphql/mutations/CreateTokenisedUser';
import UNIQUE_EMAIL_QUERY from '@/graphql/queries/UniqueEmail';

export default {
  name: 'AppUserModal',
  components: {
    ValidationObserver,
  },
  props: {
    showAddUserModal: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    user: {
      email: '',
      firstName: '',
      lastName: '',
      role: 'CONSUMER',
      country: 'AU',
      dateOfBirth: null,
    },
    isLoading: false,
    error: null,
  }),
  computed: {
    ...mapGetters(['role']),
    roleIsConsumer() {
      return this.user.role === 'CONSUMER';
    },
  },
  methods: {
    getSubordinateRoleOptionsByRole,
    async submit() {
      this.error = null;
      const isValid =
        this.$refs.observer && (await this.$refs.observer.validate());
      if (!isValid) {
        return;
      }
      try {
        const isUniqueResponse = await this.$apollo.query({
          query: UNIQUE_EMAIL_QUERY,
          variables: { email: this.user.email },
        });

        if (isUniqueResponse.data.uniqueEmail.isUnique) {
          throw new Error('Email already exists');
        }

        this.isLoading = true;

        if (this.$ff.tokenisePersonData()) {
          const {
            data: { createTokenisedUser: user },
          } = await this.$apollo.mutate({
            mutation: CREATE_TOKENISED_USER_MUTATION,
            variables: {
              email: this.user.email,
              role: this.user.role,
              country: this.user.country,
              dateOfBirth: this.user.dateOfBirth,
            },
          });

          await this.$sensitive.DirectoryPerson.update(
            user.id,
            user.person_id,
            {
              input: {
                id: user.person_id,
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                partnershipConsent: false,
              },
              notify: false,
            }
          );

          this.$emit('done', user.id);
        } else {
          const {
            data: { createUser },
          } = await this.$apollo.mutate({
            mutation: CREATE_USER_MUTATION,
            variables: {
              email: this.user.email,
              firstName: this.user.firstName,
              lastName: this.user.lastName,
              role: this.user.role,
              country: this.user.country,
              dateOfBirth: this.user.dateOfBirth,
            },
          });

          if (createUser.success) {
            this.$emit('done', createUser.user.id);
          }
        }
      } catch (e) {
        this.error = formatError(e.message);
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.user = {};
      this.$refs.observer.reset();
      this.$emit('setShowAddUserModal', false);
    },
  },
};
