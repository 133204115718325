
export default {
  name: 'AppDecoratedText',
  props: {
    decoration: {
      default: 'underline',
      type: String,
    },
  },
  computed: {
    element() {
      if (this.decoration === 'circle') {
        return 'mark';
      }
      return 'u';
    },
  },
};
