
import { mapGetters } from 'vuex';
export default {
  name: 'AppSnowflakesNavigationItem',
  props: {
    to: {
      default: null,
      type: String,
    },
    icon: {
      default: null,
      type: String,
    },
    iconTheme: {
      default: null,
      type: String,
    },
    text: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      default: null,
      type: String,
    },
  },
  computed: {
    ...mapGetters('application/affiliate', ['slug']),
    element() {
      if (this.to) {
        return 'nuxt-link';
      } else {
        return 'button';
      }
    },
    activeClass() {
      const link = this.to;
      const linkWithAffiliateSlug = `/${this.slug}${link}`;

      let isActive = false;
      if (link === '/') {
        isActive = this.localePath(linkWithAffiliateSlug) === this.$route.path;
      } else {
        isActive = this.$route.path.includes(linkWithAffiliateSlug);
      }
      return isActive ? '--active' : '';
    },
    disabledClass() {
      return this.disabled ? '--disabled' : '';
    },
  },
};
