
import { mapActions, mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/financial';

export default {
  name: 'PagesAffiliatePoaFinancialIndex',
  apollo: {
    poaFinancialMeta: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setData(data.poa);
      },
    },
  },
  data() {
    return {
      loading: false,
      poaFinancialMeta: {
        has_attorney: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        poaFinancialMeta: this.poaFinancialMeta,
      });
    },
    isComplete() {
      return (
        this.poaFinancialMeta &&
        (this.poaFinancialMeta.has_attorney === true ||
          this.poaFinancialMeta.has_attorney === false)
      );
    },
  },
  methods: {
    ...mapActions('poa', ['setData']),
    async onSubmit() {
      this.loading = true;
      await this.$apollo.mutate(
        mutations.updatePoaMeta(this.poaId, {
          key: 'has_attorney',
          value: this.poaFinancialMeta.has_attorney ? 'true' : 'false',
          category: 'FINANCIAL',
        })
      );

      if (!this.poaFinancialMeta.has_attorney) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'skip_module',
          props: {
            poa_id: this.poaId,
            module_name: 'Financial',
          },
        });
      }

      const path = this.localePath(this.next.path);
      this.$router.push({ path });
    },
  },
};
