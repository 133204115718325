import { render, staticRenderFns } from "./thank-you.vue?vue&type=template&id=eb4fd05c"
import script from "./thank-you.vue?vue&type=script&lang=js"
export * from "./thank-you.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppAccountPurchasedProducts: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/account/PurchasedProducts.vue').default,RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,WpButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/Button.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppBox: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Box.vue').default,AppDashboardWillGateway: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/dashboard/WillGateway.vue').default,AppDashboardPowerOfAttorneyGateway: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/dashboard/PowerOfAttorneyGateway.vue').default,AppDashboardVaultGateway: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/dashboard/VaultGateway.vue').default,AppAccountInviteFriendsAndFamily: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/account/InviteFriendsAndFamily.vue').default,AppModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Modal.vue').default})
