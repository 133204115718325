
export default {
  name: 'AppWillExecutorFeesPanel',
  props: {
    displayPanel: {
      type: Boolean,
      default: false,
    },
    toggleDisplayPanel: {
      type: Function,
      default: () => ({}),
    },
  },
};
