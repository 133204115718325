
export default {
  name: 'AppAuthPanel',
  props: {
    showCta: {
      default: false,
      type: Boolean,
    },
  },
};
