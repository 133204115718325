
export default {
  name: 'AppSnowflakesLoadingMark',
  props: {
    height: {
      default: null,
      type: String,
    },
  },
};
