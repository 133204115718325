
import { mapGetters } from 'vuex';
import { beneficiaries, guardians, user, will } from '@/mixins/apollo';
import { RELATIONSHIP_TYPE } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateWillGuardiansYourChildren',
  mixins: [beneficiaries, guardians, user, will],
  data() {
    return {
      RELATIONSHIP_TYPE,
      selectedContacts: [],
      powerOfAttorneyAddOnIsVisible: false,
    };
  },
  computed: {
    ...mapGetters('directory-person', [
      'userDetails',
      'contactsWithoutRelationships',
    ]),
    ...mapGetters('directory-person', {
      currentChildren: 'userChildren',
    }),
    contacts() {
      return [...this.currentChildren, ...this.contactsWithoutRelationships];
    },
    isComplete() {
      return !!(
        this.willMeta.has_children === false || this.selectedContacts.length
      );
    },
    hasUnderEighteenChildren() {
      return this.currentChildren.some((child) => !child.over18);
    },
    nextStep() {
      return this.localePath(
        this.willMeta.has_children && this.hasUnderEighteenChildren
          ? '/will/guardians/primary'
          : '/will/guardians/your-pets'
      );
    },
  },
  watch: {
    'willMeta.has_children'(value) {
      if (value) {
        this.initSelectedChildren();
      }
    },
  },
  mounted() {
    this.unWatchSelectedChildren = this.$watch(
      () => {
        return this.selectedContacts;
      },
      this.setPowerOfAttorneyVisibility,
      { immediate: true }
    );
    this.initSelectedChildren();
  },
  methods: {
    setPowerOfAttorneyVisibility() {
      if (this.selectedContacts.length) {
        this.powerOfAttorneyAddOnIsVisible = true;
        if (this.unWatchSelectedChildren) {
          this.unWatchSelectedChildren();
        }
      }
    },
    initSelectedChildren() {
      this.selectedContacts = this.currentChildren.map((child) => child.id);
    },
    async submit() {
      const childrenUpdated = false;
      const hasChildren = this.willMeta.has_children;

      if (
        this.willMeta.estate_split === 'children' &&
        (hasChildren === false || childrenUpdated)
      ) {
        this.willMeta.estate_split = null;

        await this.removeAllBeneficiaries();
        await this.updateWillMeta();
      }

      if (!hasChildren || !this.hasUnderEighteenChildren) {
        this.willMeta.has_primary_guardian = null;
        this.willMeta.has_backup_guardian = null;
        await this.updateWillMeta();
        await this.removeAllGuardians();
      }

      this.$nuxt.$emit('sendTrackingAttributes', {
        has_children: hasChildren,
      });

      this.$router.push({
        path: this.nextStep,
      });
    },
  },
};
