
import { mapGetters } from 'vuex';
import VDismiss from 'vue-dismiss';
import { Portal } from 'portal-vue';

export default {
  name: 'AppCartPanel',
  components: {
    Portal,
  },
  mixins: [VDismiss],
  props: {
    rightOffset: {
      default: 0,
      type: Number,
    },
  },
  computed: {
    ...mapGetters('cart', ['productRemoval']),
  },
  methods: {
    closeCartPanel() {
      this.$emit('closeCartPanel');
    },
  },
};
