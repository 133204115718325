
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';

export default {
  name: 'PagesAffiliateAdminInstitutionsIndex',
  data() {
    return {
      newInstitutionModalIsVisible: false,
      searchQuery: '',
      columns: {
        Name: {
          order: 1,
          field: 'NAME',
        },
        Type: {
          order: 2,
          field: 'TYPE',
        },
        'Created At': {
          order: 3,
          field: 'CREATED_AT',
        },
        'Updated At': {
          order: 4,
          field: 'UPDATED_AT',
        },
      },
    };
  },
  computed: {
    ...mapGetters('admin/institutions', [
      'institutions',
      'institutionsLoading',
      'institutionsPageIndex',
      'institutionsPageSize',
      'institutionsSortDirection',
      'institutionsSortField',
    ]),
    columnHeaders() {
      return Object.keys(this.columns).sort(
        (a, b) => this.columns[a].order - this.columns[b].order
      );
    },
    sortableColumns() {
      return Object.keys(this.columns).filter(
        (column) => !!this.columns[column].field
      );
    },
    currentSortingColumn() {
      return [
        Object.keys(this.columns).find(
          (column) => this.columns[column].field === this.institutionsSortField
        ),
        this.institutionsSortDirection,
      ];
    },
  },
  mounted() {
    this.getInstitutions();
  },
  methods: {
    ...mapActions('admin/institutions', [
      'getInstitutions',
      'nextInstitutionsPage',
      'previousInstitutionsPage',
      'resetInstitutionsPageIndex',
      'setInstitutionsSearchQuery',
      'setInstutionsSort',
    ]),
    openNewInstitutionModal() {
      this.newInstitutionModalIsVisible = true;
    },
    closeNewInstitutionModal() {
      this.newInstitutionModalIsVisible = false;
    },
    sortColumns(column) {
      this.setInstutionsSort(this.columns[column].field);
    },
    reloadInstitutions(createdInstitution) {
      this.$router.push(
        this.localePath(`/admin/institutions/${createdInstitution.id}`)
      );
    },
    search: debounce(300, function (event) {
      this.setInstitutionsSearchQuery(event.target.value.trim());
    }),
  },
};
