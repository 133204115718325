
import GET_COMMENTS from '@/graphql/queries/GetComments';
import ADD_COMMENT from '@/graphql/mutations/AddComment';

export default {
  name: 'AppComments',
  props: {
    willId: {
      type: String,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      text: '',
      loading: false,
    };
  },
  computed: {
    getCommentsQuery() {
      return {
        query: GET_COMMENTS,
        variables: {
          willId: this.willId,
          key: this.moduleName,
        },
      };
    },
  },
  apollo: {
    comments: {
      query: GET_COMMENTS,
      variables() {
        return {
          willId: this.willId,
          key: this.moduleName,
        };
      },
      update: (data) => data.getComments,
    },
  },
  methods: {
    async submit() {
      try {
        await this.$apollo.mutate({
          mutation: ADD_COMMENT,
          variables: {
            willId: this.willId,
            key: this.moduleName,
            text: this.text,
          },
          update: (store, { data: { addComment } }) => {
            const data = store.readQuery(this.getCommentsQuery);
            data.getComments.push(addComment);
            store.writeQuery({
              ...this.getCommentsQuery,
              data,
            });

            this.text = '';
          },
        });
      } catch (e) {
        this.error = e.message;
      }
    },
  },
};
