
export default {
  name: 'ContactRemove',
  props: {
    contact: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    contactName() {
      return this.$contactNameOrDefault(
        this.contact,
        this.$t('components.deletePartnerAndChild.theContact')
      );
    },
    contactRelationship() {
      return this.$t(
        `commons.relationships.${this.contact.relationshipWithUser}`
      )?.toLocaleLowerCase();
    },
    title() {
      return `${this.$t('components.deletePartnerAndChild.title', {
        name: this.contactName,
      })} ${this.contactRelationship}`;
    },
  },
};
