
import { mapGetters } from 'vuex';
import files from '@/mixins/files';
import { user } from '@/mixins/apollo';

import GET_SIGNED_WILL from '@/graphql/queries/GetSignedWill';
import UPDATE_SIGNED_WILL from '@/graphql/mutations/UpdateSignedWill';

export default {
  name: 'PagesAffiliateDashboardFiles',
  mixins: [user, files],
  data() {
    return {
      GET_SIGNED_WILL,
      UPDATE_SIGNED_WILL,
      uploadedFiles: {
        SIGNED_WILL: {
          fileId: null,
          fileName: null,
          fileStatus: null,
          executorHasPermission: null,
          loading: null,
          alert: {
            type: null,
            message: null,
          },
          unlinkable: true,
        },
        all: {
          files: [],
          loading: null,
          alert: {
            message: null,
          },
        },
      },
      deleteFileModalIsVisible: false,
      selectedDeleteFileId: null,
    };
  },
  computed: {
    ...mapGetters('will', ['executors']),
    hasExecutorWithEmail() {
      return !!this.executors.some(
        (executor) => !!executor.directoryPerson.emails.length
      );
    },
  },
  mounted() {
    this.getAllUploadedFiles();
    this.getSignedWill();
  },
  methods: {
    async getAllUploadedFiles() {
      this.uploadedFiles.all.loading = true;
      this.uploadedFiles.all.alert.message = null;
      try {
        this.uploadedFiles.all.files = [
          ...(await this.getFileList()),
        ].reverse();
      } catch {
        this.uploadedFiles.all.alert.message = this.$t(
          'texts.sentences.uploadedFilesFetchFailed'
        );
      } finally {
        this.uploadedFiles.all.loading = false;
      }
    },
    async getSignedWill() {
      this.uploadedFiles.SIGNED_WILL.loading = true;
      this.uploadedFiles.SIGNED_WILL.errorMessage = null;
      this.uploadedFiles.SIGNED_WILL.fileId = null;
      this.uploadedFiles.SIGNED_WILL.fileName = null;
      this.uploadedFiles.SIGNED_WILL.fileStatus = null;
      try {
        const { data } = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: GET_SIGNED_WILL,
          variables: {
            id: this.willId,
          },
        });
        if (data.getSignedWill) {
          const signedWill = data.getSignedWill;
          this.uploadedFiles.SIGNED_WILL.fileId = signedWill.fileId;
          this.uploadedFiles.SIGNED_WILL.fileName = signedWill.fileName;
          this.uploadedFiles.SIGNED_WILL.fileStatus = signedWill.fileStatus;
        }
      } catch {
        this.uploadedFiles.SIGNED_WILL.alert.type = 'warn';
        this.uploadedFiles.SIGNED_WILL.alert.message =
          this.uploadedFiles.all.alert.message = this.$t(
            'texts.sentences.somethingWentWrong'
          );
      } finally {
        this.uploadedFiles.SIGNED_WILL.loading = false;
      }
    },
    openDeleteFileModal(fileId) {
      this.selectedDeleteFileId = fileId;
      this.deleteFileModalIsVisible = true;
    },
    closeDeleteFileModal() {
      this.deleteFileModalIsVisible = false;
    },
    async uploadFiles(files, fileType) {
      let uploadedFiles = this.uploadedFiles[fileType];
      if (!uploadedFiles) {
        uploadedFiles = this.uploadedFiles.all;
      }
      uploadedFiles.alert.type = 'info';
      uploadedFiles.alert.message = this.$t('pages.files.processingYourUpload');
      const { success, message } = await this.processFileUpload(
        files,
        fileType
      );
      if (success) {
        uploadedFiles.alert.message = null;
        this.$nuxt.$emit('snackbar', {
          icon: 'success',
          type: 'success',
          text: this.$t('texts.sentences.successfullyUploaded'),
        });
        this.getAllUploadedFiles();
        this.getSignedWill();
      } else {
        uploadedFiles.alert.type = 'error';
        uploadedFiles.alert.message = message;
      }
    },
    async unlinkSignedWill() {
      this.uploadedFiles.SIGNED_WILL.alert.type = 'info';
      this.uploadedFiles.SIGNED_WILL.alert.message = this.$t(
        'pages.files.unlinkYourSingedWill'
      );
      const { data } = await this.$apollo.mutate({
        mutation: UPDATE_SIGNED_WILL,
        variables: {
          id: this.willId,
          isDelete: true,
        },
      });
      if (
        data.updateSignedWill?.errors?.length === 0 &&
        data.updateSignedWill === null
      ) {
        this.uploadedFiles.SIGNED_WILL.alert.message = null;
        this.$nuxt.$emit('snackbar', {
          icon: 'success',
          type: 'success',
          text: this.$t('texts.sentences.successfullyUnlinked'),
        });
        this.getAllUploadedFiles();
        this.getSignedWill();
      }
    },
    async deleteFileFromList() {
      this.uploadedFiles.all.loading = true;
      try {
        await this.deleteFile(this.selectedDeleteFileId);
        this.$nuxt.$emit('snackbar', {
          icon: 'success',
          type: 'success',
          text: this.$t('texts.sentences.successfullyDeleted'),
        });
        this.getAllUploadedFiles();
        this.getSignedWill();
      } catch {
        this.uploadedFiles.all.alert.message =
          this.uploadedFiles.all.alert.message = this.$t(
            'texts.sentences.somethingWentWrong'
          );
      } finally {
        this.uploadedFiles.all.loading = false;
        this.selectedDeleteFileId = null;
        this.closeDeleteFileModal();
      }
    },
    async downloadSignedWill() {
      await this.$downloadFile(this.uploadedFiles.SIGNED_WILL.fileId);
    },
  },
};
