
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'throttle-debounce';
import isFunction from 'lodash/isFunction';
import { liabilities, user, will } from '@/mixins/apollo';
import { metaArrayToObject, objectToMetaArray } from '@/utilities';

export default {
  name: 'PagesAffiliateWillAssetsLiabilities',
  mixins: [liabilities, user, will],
  data() {
    return {
      selectedLiabilities: [],
      hasLifeInsuranceUpsellShown: false,
    };
  },
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    isComplete() {
      const hasAddedLiabilities = this.selectedLiabilities.length;
      const allLiabilitiesHaveIds = this.selectedLiabilities.every(
        (liability) => !!liability.id
      );
      return !!(
        this.willMeta.has_liabilities === false ||
        (hasAddedLiabilities && allLiabilitiesHaveIds)
      );
    },
    submitLabel() {
      if (
        (this.willMeta.has_liabilities || this.willMeta.has_assets) &&
        (!this.isFreeWillWeek || this.willMeta.asset_note)
      ) {
        return this.$t('forms.labels.next');
      } else {
        return this.$t('forms.labels.continueToExecutors');
      }
    },
    lifeInsuranceUpsellIsVisible() {
      return (
        this.$ff.lifeInsuranceWillFlowUpsell() &&
        !this.hasLifeInsuranceUpsellShown
      );
    },
  },
  watch: {
    liabilities(liabilities) {
      if (liabilities.length && !this.selectedLiabilities.length) {
        this.selectedLiabilities = liabilities.map(({ id, meta }) => {
          return {
            id,
            meta: metaArrayToObject(meta),
          };
        });
      }
    },
  },
  async created() {
    this.hasLifeInsuranceUpsellShown = !!this.willMeta.life_insurance_upsell;
    await this.getProducts();
  },
  methods: {
    ...mapActions('product', ['getProducts']),
    addFinancialInput() {
      this.selectedLiabilities.push({
        meta: {
          description: '',
          type: '',
        },
      });
    },
    async removeFinancialInput(liability) {
      if (this.isLiability(liability)) {
        await this.removeLiability(liability);
      }

      this.selectedLiabilities.splice(
        this.selectedLiabilities.indexOf(liability),
        1
      );
    },
    async saveFinancialInput(liability) {
      let newLiability;
      const meta = objectToMetaArray(liability.meta);
      const isValidMeta =
        meta.length === 2 && meta.every((m) => m.value.trim());

      if (!this.willMeta.has_liabilities || !isValidMeta) {
        return;
      }

      if (this.isLiability(liability)) {
        newLiability = await this.updateLiability(liability, meta);
      } else {
        newLiability = await this.addLiability(meta);
      }

      this.selectedLiabilities.splice(
        this.selectedLiabilities.indexOf(liability),
        1,
        {
          id: newLiability.id,
          ...liability,
        }
      );
    },
    save: debounce(300, async function (validate, value = null) {
      const isValid = isFunction(validate) ? await validate() : false;

      if (!isValid) {
        return;
      }

      if (this.willMeta.has_liabilities && value) {
        await this.saveFinancialInput(value);
      } else if (this.willMeta.has_liabilities) {
        // Handles the case where the user toggles the liabilities switch - YES
        await this.updateWillMeta();
        await this.getProducts();
      } else if (!this.willMeta.has_liabilities) {
        // Handles the case where the user toggles the liabilities switch - NO
        await this.updateWillMeta();
        await this.removeFinancialInputs();
        await this.getProducts();
      }
    }),
    async removeFinancialInputs() {
      if (!this.willMeta.has_liabilities) {
        await Promise.all(
          this.selectedLiabilities.map((liability) => {
            if (this.isLiability(liability)) {
              return this.removeLiability(liability);
            } else {
              return Promise.resolve();
            }
          })
        );

        this.selectedLiabilities = [];
      }
    },
    done() {
      if (
        (this.willMeta.has_liabilities || this.willMeta.has_assets) &&
        (!this.isFreeWillWeek || this.willMeta.asset_note)
      ) {
        this.$router.push({
          path: this.localePath('/will/assets/notes'),
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'complete_module',
          props: {
            will_id: this.willId,
            module_name: 'Assets',
          },
        });

        this.$router.push({
          path: this.localePath('/will/executors'),
        });
      }
    },
  },
};
