
import { mapGetters } from 'vuex';
import { MODULE_TO_HELP_ASSESSMENT_VAULT_ITEMS } from '@/utilities/constants';
export default {
  name: 'AppAdminModuleHelpRequired',
  props: {
    module: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('vault-item', ['willHelpAssessmentVaultItems']),
    isModuleNeedingHelp() {
      const vaultItems = MODULE_TO_HELP_ASSESSMENT_VAULT_ITEMS[this.module];
      if (!vaultItems) {
        return false;
      }

      const itemsRelatedToModule = this.willHelpAssessmentVaultItems.filter(
        (item) =>
          vaultItems.some(
            (vaultItem) =>
              item.key === vaultItem.KEY &&
              (!vaultItem.TYPE || item.type === vaultItem.TYPE)
          )
      );
      return itemsRelatedToModule.some(({ helpRequired }) => helpRequired);
    },
  },
};
