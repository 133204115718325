
import { encodePayloadForUrl, getBaseUrl } from '@/utilities';

export default {
  name: 'PagesAffiliateAdminGenericCheckoutIndex',
  layout: 'admin',
  middleware: ['adminAuthenticated'],
  data() {
    return {
      allCustomProducts: [
        {
          value: 'LEGAL_ADVICE',
          text: 'forms.labels.legalAdvice',
        },
      ],
      pricedProduct: {
        name: null,
        price: 0,
      },
      selectedProducts: [{ ...this.pricedProduct }],
      generatedLink: null,
    };
  },
  methods: {
    addProduct() {
      this.selectedProducts = [
        ...this.selectedProducts,
        { ...this.pricedProduct },
      ];
    },
    setProduct(index, event) {
      this.selectedProducts[index].product = event.target.value;
    },
    setProductPrice(index, event) {
      this.selectedProducts[index].price = event.target.value;
    },
    generatePaymentLink() {
      this.generatedLink = null;
      const allFieldsPresent = this.selectedProducts.reduce(
        (previous, current) => {
          return previous && !!current.name && !!current.price;
        },
        true
      );
      if (!allFieldsPresent) {
        this.$nuxt.$emit('snackbar', {
          placement: 'top-right',
          type: 'error',
          text: 'All fields are required to generate link',
        });
        return;
      }

      const link = encodePayloadForUrl({
        customProducts: this.selectedProducts.map(({ name, price }) => ({
          name,
          price: price * 100,
        })),
      });
      this.generatedLink =
        getBaseUrl() + `/checkout/generic?checkoutData=${link}`;
    },
    async copyLink() {
      await navigator.clipboard.writeText(this.generatedLink);
      this.$nuxt.$emit('snackbar', {
        placement: 'top-right',
        type: 'success',
        text: 'Link copied to clipboard',
      });
    },
  },
};
