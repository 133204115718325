
export default {
  name: 'AppContactSearch',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    },
  },
};
