
import copy from 'copy-to-clipboard';

export default {
  name: 'AppSimpleTable',
  props: {
    rows: {
      type: Array,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    copyCellContent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    copyContent(content) {
      copy(content);
      this.$nuxt.$emit('snackbar', {
        placement: 'top-right',
        type: 'success',
        text: 'Copied to clipboard',
      });
    },
  },
};
