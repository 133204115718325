
import VDismiss from 'vue-dismiss';
import { Portal } from 'portal-vue';

export default {
  name: 'AppModal',
  components: {
    Portal,
  },
  mixins: [VDismiss],
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
    isDismissable: {
      type: Boolean,
      default: true,
    },
    isCloseable: {
      type: Boolean,
      default: true,
    },
    watch: {
      type: Boolean,
      default: true,
    },
    size: {
      default: 'small',
      type: String,
      validator(value) {
        return ['small', 'medium', 'large'].includes(value);
      },
    },
    id: {
      default: '',
      type: String,
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    onAfterLeave() {
      this.$emit('modalHasClosed');
    },
  },
};
