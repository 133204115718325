
import { ValidationObserver } from 'vee-validate';
import { extractErrorMessages } from '@/utilities';

export default {
  name: 'AppForm',
  components: {
    ValidationObserver,
  },
  props: {
    autoFocus: {
      default: true,
      type: Boolean,
    },
    customError: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    autoSubmit: {
      default: false,
      type: Boolean,
    },
    mutation: {
      default: null,
      type: Object,
    },
    submitCompact: {
      default: false,
      type: Boolean,
    },
    submitId: {
      default: null,
      type: String,
    },
    submitClasses: {
      default: '',
      type: String,
    },
    submitInline: {
      default: false,
      type: Boolean,
    },
    submitLabel: {
      default: null,
      type: String,
    },
    submitWide: {
      default: false,
      type: Boolean,
    },
    variables: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      isDone: false,
      isLoading: false,
      error: null,
    };
  },
  computed: {
    apollo() {
      return this.mutation && this.variables;
    },
    loading() {
      return this.isDone || this.isLoading;
    },
  },
  mounted() {
    this.$nuxt.$on('unlockForm', () => {
      this.isDone = false;
      this.isLoading = false;
    });
    this.$nuxt.$on('clearErrors', () => {
      this.$refs.observer?.reset();
    });
    if (this.autoFocus) {
      this.$el.querySelector('input,select,textarea')?.focus();
    }
  },
  methods: {
    resetError() {
      this.error = null;
    },
    setError(error) {
      this.error = extractErrorMessages(error)
        .map((e) => this.$t(e))
        .join(', ');
      this.isLoading = false;
    },
    setGenericError(error) {
      this.error = this.$t('forms.errors.generic', {
        error: this.$t(`forms.errors.${error}`),
      });
      this.isDone = false;
      this.isLoading = false;
    },
    async submit($event) {
      const isValid =
        this.$refs.observer && (await this.$refs.observer.validate());
      if (isValid) {
        this.isLoading = true;
        this.error = null;
        if (this.apollo) {
          this.$emit('requestStarted');
          await this.$apollo
            .mutate({
              mutation: this.mutation,
              variables: this.variables,
              update: (store, res) => {
                this.$emit('update', store, res);
                this.isDone = true;
                this.$emit('done', res);
              },
            })
            .then(({ errors }) => {
              if (errors) {
                this.isLoading = false;
                this.setError(errors[0]);
              }
            })
            .catch((e) => {
              this.isLoading = false;
              this.setError(e);
            });
        } else {
          this.$emit('submit', $event);
          this.isLoading = true;
        }
      } else {
        this.error = this.$t('components.form.missingRequiredFieldsError');
        this.$refs.observer.$el.parentElement
          .querySelector('.--invalid')
          ?.scrollIntoView();
      }
    },
  },
};
