
import { Portal } from 'portal-vue';

export default {
  name: 'AppSnackbar',
  components: {
    Portal,
  },
  data() {
    return {
      snackbars: [],
    };
  },
  computed: {
    snackbarsByPlacement() {
      return this.snackbars.reduce(
        (acc, snackbar) => ({
          ...acc,
          [snackbar.placement]: [...(acc[snackbar.placement] ?? []), snackbar],
        }),
        {}
      );
    },
  },
  mounted() {
    this.$nuxt.$on('snackbar', this.addSnackbar);
  },
  methods: {
    addSnackbar({
      type = 'default',
      placement = 'bottom-center',
      relative = 'app',
      icon = null,
      text,
      duration = 4000,
      iconPlacement = 'center',
    }) {
      this.snackbars.push({
        id: `snackbar__${new Date().getTime()}`,
        icon,
        text,
        type,
        placement: `${relative}-${placement}`,
        duration,
        iconPlacement,
      });
    },
    removeSnackbar(snackbarId) {
      this.snackbars = this.snackbars.filter(
        (snackbar) => snackbar.id !== snackbarId
      );
    },
    onAfterEnter(snackbarId) {
      const snackbarDuration = this.snackbars.find(
        (snackbar) => snackbar.id === snackbarId
      ).duration;
      setTimeout(() => {
        this.removeSnackbar(snackbarId);
      }, snackbarDuration);
    },
  },
};
