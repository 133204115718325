
import { mapGetters } from 'vuex';
import shuffle from 'lodash/shuffle';
import { will } from '@/mixins/apollo';

export default {
  name: 'AppWillCharitySuggester',
  mixins: [will],
  props: {
    label: {
      default: false,
      type: Boolean,
    },
    single: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      layout: {
        primary: 'w-full',
        secondary: 'w-full lg:w-1/2',
      },
      selectedCharityIds: [],
    };
  },
  computed: {
    ...mapGetters(['charityParents', 'featuredFive']),
    ...mapGetters('directory-person', ['userState']),
    modelValue: {
      get() {
        return this.selectedCharityIds;
      },
      set(newValue) {
        this.selectedCharityIds = newValue;
        this.$emit(
          'input',
          this.charityData.filter((charity) => {
            return this.selectedCharityIds.includes(charity.id);
          })
        );
      },
    },
    charityData() {
      const regionalFeaturedFive = this.featuredFive.map((partnership) => {
        if (
          this.charityParents[partnership.id] &&
          this.userState &&
          this.charityParents[partnership.id][this.userState]
        ) {
          return this.charityParents[partnership.id][this.userState];
        }
        return partnership;
      });
      return shuffle(regionalFeaturedFive)
        .slice(0, 5)
        .map((charity, index) => {
          return {
            ...charity,
            suggestionLocation: window.location.pathname,
            suggestionSlot: index,
          };
        });
    },
  },
  methods: {
    isSelected(charityId) {
      return this.selectedCharityIds.includes(charityId);
    },
  },
};
