
export default {
  name: 'AppWillCauseSelector',
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: Array,
      required: true,
    },
    displayedCauses: {
      type: Array,
      required: true,
    },
    forceSmall: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
