
export default {
  name: 'AppGetStartedExperiment',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    pageContent() {
      return this.$t(
        `pages.getStartedExperiment.${this.$ff.getStartedVariation()}`
      );
    },
  },
  methods: {
    next() {
      this.loading = true;
      this.$emit('next');
    },
  },
};
