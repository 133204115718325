
import { mapActions } from 'vuex';
import { decode } from 'jsonwebtoken';

import RESEND_WELCOME_EMAIL from '@/graphql/mutations/ResendWelcomeEmail';
import VERIFY_MUTATION from '@/graphql/mutations/Verify';

export default {
  name: 'PagesAffiliateVerify',
  data() {
    return {
      RESEND_WELCOME_EMAIL,
      failed: false,
      email: null,
    };
  },
  fetch({ redirect, route }) {
    if (!route.query.e && !route.query.t) redirect('/');
  },
  computed: {
    classes() {
      return {
        invisible: !this.failed,
      };
    },
    variables() {
      return {
        email: this.email,
      };
    },
  },
  mounted() {
    const { t } = this.$route.query;
    const tokenPayload = decode(t);
    this.email = tokenPayload?.email;

    this.$apollo
      .mutate({
        mutation: VERIFY_MUTATION,
        variables: {
          token: t,
          email: tokenPayload?.email,
        },
      })
      .then(({ data }) => {
        if (data.verify && data.verify.success) {
          this.setVerified(true);

          this.$router.push({
            path: this.localePath('/'),
          });
        } else {
          this.failed = true;
        }
      })
      .catch(() => {
        this.failed = true;
      });
  },
  methods: {
    ...mapActions(['setVerified']),
  },
};
