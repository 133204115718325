
export default {
  name: 'AppCardRow',
  props: {
    id: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    icon: {
      default: null,
      type: String,
    },
    options: {
      default: () => [],
      type: Array,
    },
    rules: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    value: {
      default: '',
      type: [String, Boolean, Number],
    },
    vertical: {
      default: false,
      type: Boolean,
    },
    pattern: {
      default: null,
      type: String,
    },
    title: {
      default: null,
      type: String,
    },
    format: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  computed: {
    inputIsValid() {
      if (this.pattern && this.modelValue) {
        const regex = new RegExp(this.pattern);
        return regex.test(this.modelValue);
      }
      return true;
    },
    errorClass() {
      return this.inputIsValid ? '' : '--invalid';
    },
    isWide() {
      return this.vertical || this.type === 'button';
    },
    labelClass() {
      return !this.isWide ? 'p-2 w-2/5 text-right' : 'w-full px-2 py-1';
    },
    fieldClass() {
      return !this.isWide ? 'p-2 w-3/5' : 'w-full px-2 py-1';
    },
    rowClass() {
      return !this.isWide ? 'flex items-center' : 'py-2';
    },
    changeClass() {
      return this.originalValue !== this.value
        ? '--updated shadow-inset text-orange-400'
        : '';
    },
    selectOptions() {
      let options = [];
      if (typeof this.options[0] === 'string') {
        options = this.options.map((option) => {
          return {
            value: option,
            text: option,
          };
        });
      } else {
        options = this.options;
      }

      if (options[0]?.value && this.rules !== 'required') {
        options.unshift({
          value: null,
          text: null,
        });
      }
      return options;
    },
  },
  created() {
    this.originalValue = this.value;
  },
  methods: {
    update() {
      if ([this.type, this.format].includes('number')) {
        this.$emit('input', parseInt(this.modelValue));
      } else if (this.type === 'date') {
        this.$emit('input', this.modelValue || null);
      } else {
        this.$emit('input', this.modelValue);
      }
    },
  },
};
