
import { mapActions, mapGetters } from 'vuex';
import GET_PRODUCT_PRICES_QUERY from '@/graphql/queries/getProductPrices';
import GET_PURCHASED_PRODUCTS_QUERY from '@/graphql/queries/GetPurchasedProducts';
import CURRENT_USER_QUERY from '@/graphql/queries/CurrentUser';
import GET_ORDERS from '@/graphql/queries/GetOrders';
import { user, will, subscription } from '@/mixins/apollo';
import { pollUntilTruthy, formatError } from '@/utilities';

export default {
  name: 'AppCheckout',
  mixins: [user, will, subscription],
  props: {
    checkoutItems: {
      type: Array,
      default: () => [],
    },
    isGeneric: {
      type: Boolean,
      default: false,
    },
    productsWithCustomPricesToken: {
      type: String,
      default: null,
    },
    customProducts: {
      type: Array,
      default: () => [],
    },
    isUpdateCardDetails: {
      type: Boolean,
      default: false,
    },
    isUnlockAccount: {
      type: Boolean,
      default: false,
    },
    breadcrumbs: {
      type: Object,
      default: null,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    useCart: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['openExitModal', 'orderCreated'],
  data() {
    return {
      acts: [
        'LOADING',
        'PRODUCT_SELECTION',
        'SUMMARY',
        'PAYMENT',
        'PROCESSING',
        'ERROR',
        'CHECKOUT',
      ],
      currentAct: null,
      currentScene: null,
      selectedItems: [],
      allItemsWithPrices: [],
      paymentProcessing: false,
      discountCode: null,
      discountData: null,
      paymentMethod: null,
      couponAllowed: false,
      stripeActionType: '',
      defaultItems: [],
      suggestedItems: [],
      partnerItems: [],
      giftItems: [],
      purchasedProducts: [],
      subscriptionOptOutModalIsVisible: false,
      isWillTierTwoUpgradeModalVisible: false,
      isWillTierTwoDowngradeModalVisible: false,
    };
  },
  apollo: {
    getProductPrices: {
      query: GET_PRODUCT_PRICES_QUERY,
      variables() {
        return {
          userId: this.userId,
          productCodes: Array.from(
            new Set([
              ...this.checkoutItems.map((product) => product.product),
              ...this.cartItemCodes,
            ])
          ),
          discountCode: this.discountCode,
        };
      },
      result({ data, error }) {
        if (error) {
          this.setCurrentAct('ERROR');
        } else {
          this.allItemsWithPrices = data.getProductPrices.map((item) => ({
            ...item,
            product: item.productCode,
            finalPrice: item.discountedPrice,
          }));
        }
      },
      skip() {
        return this.isGeneric || this.isUpdateCardDetails;
      },
    },
    purchasedProducts: {
      query: GET_PURCHASED_PRODUCTS_QUERY,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          userId: this.userId,
        };
      },
      skip() {
        return (
          !this.defaultItems.length ||
          Boolean(this.hasAfterpayPaymentIntentClientSecret)
        );
      },
      update(data) {
        const purchasedProducts = data?.getPurchasedProducts?.products || [];

        const hasAlreadyPurchasedAllDefaultItems = this.defaultItems.every(
          (defaultItem) => {
            return purchasedProducts.includes(defaultItem.product);
          }
        );
        if (
          !this.hasAfterpayPaymentIntentClientSecret &&
          !this.isUnlockAccount &&
          hasAlreadyPurchasedAllDefaultItems
        ) {
          console.error('User has already purchased all default items.');
          this.setCurrentAct('ERROR');
        }

        return purchasedProducts;
      },
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
      result({ data }) {
        this.discountCode = data.currentUser.user.latest_referral_coupon;
      },
    },
  },
  computed: {
    ...mapGetters('directory-person', ['userDetails', 'userIsAustralian']),
    ...mapGetters('coupon', ['couponLatestReferral']),
    ...mapGetters('orders', ['latestOrder']),
    ...mapGetters('cart', [
      'isCartEmpty',
      'cartItemPrices',
      'cartItemCodes',
      'canAddToCart',
      'isInCart',
      'isFreeCart',
      'subscriptionAutoRenewal',
      'showSubscriptionAutoRenewalInCheckout',
    ]),
    ...mapGetters('will-tiers', ['hasPurchasedWillProduct']),
    ...mapGetters('user-onboarding', ['selectedVaultItemsCount']),
    selectedItemsAndCart() {
      return this.useCart ? this.cartItemPrices : [...this.selectedItems];
    },
    showProductSelection() {
      return (
        this.showProductSuggestions ||
        this.showPartnerSelection ||
        this.showGiftSelection
      );
    },
    showProductSuggestions() {
      return this.suggestedItems.length > 0;
    },
    showPartnerSelection() {
      return this.partnerItems.length > 0;
    },
    showGiftSelection() {
      return this.giftItems.length > 0;
    },
    selectedItemsWithPrices() {
      if (this.useCart) {
        return this.cartItemPrices;
      }

      return this.allItemsWithPrices
        ? this.selectedItemsAndCart.map(
            (selectedItem) =>
              this.allItemsWithPrices.find(
                (pricedItem) => pricedItem.product === selectedItem.product
              ) ?? selectedItem
          )
        : [];
    },
    defaultItemsWithPrices() {
      return this.allItemsWithPrices
        ? this.defaultItems.map((defaultItem) => ({
            ...this.allItemsWithPrices.find(
              (item) => item.product === defaultItem.product
            ),
            ...defaultItem,
          }))
        : [];
    },
    suggestedItemsWithPrices() {
      return this.allItemsWithPrices
        ? this.suggestedItems
            .filter((suggestedItem) => {
              if (suggestedItem.product === 'WILL') {
                return (
                  !this.hasPurchasedWillProduct &&
                  !this.isInCart('WILL_TIER_TWO')
                );
              }

              if (suggestedItem.product === 'WILL_TIER_TWO') {
                return (
                  this.$ff.willTiersSliceTwoOneVariation().control ||
                  this.selectedVaultItemsCount === 0
                );
              }
              return !this.purchasedProducts.includes(suggestedItem.product);
            })
            .map((suggestedItem) => {
              return {
                ...this.allItemsWithPrices.find((pricedItem) => {
                  return pricedItem.product === suggestedItem.product;
                }),
                ...suggestedItem,
              };
            })
        : [];
    },
    partnerItemsWithPrices() {
      return this.allItemsWithPrices
        ? this.partnerItems
            .filter(
              (partnerItem) =>
                !this.purchasedProducts.includes(partnerItem.product)
            )
            .map((partnerItem) => {
              return {
                ...this.allItemsWithPrices.find((pricedItem) => {
                  return pricedItem.product === partnerItem.product;
                }),
                ...partnerItem,
              };
            })
        : [];
    },
    giftItemsWithPrices() {
      return this.allItemsWithPrices
        ? this.giftItems.map((giftItem) => {
            return {
              ...this.allItemsWithPrices.find((pricedItem) => {
                return pricedItem.product === giftItem.product;
              }),
              ...giftItem,
            };
          })
        : [];
    },
    checkoutItemsWithPrices() {
      return [...this.defaultItemsWithPrices, ...this.selectedItemsWithPrices];
    },
    expectedCostInCents() {
      return this.checkoutItemsWithPrices.reduce(
        (total, current) => total + current.finalPrice,
        0
      );
    },
    showCouponAlert() {
      return Boolean(
        this.discountCode && !this.$apollo.queries.getProductPrices.loading
      );
    },
    couponIsValid() {
      return !!this.allItemsWithPrices.some(
        (item) => item.basePrice !== item.finalPrice
      );
    },
    couponAlertMessage() {
      return this.couponIsValid
        ? this.$t('components.checkout.couponCodeApplied', {
            code: this.discountCode?.toUpperCase(),
          })
        : this.$t('components.checkout.invalidCouponCode', {
            code: this.discountCode?.toUpperCase(),
          });
    },
    billingAddress() {
      return this.userDetails?.residentialAddress
        ? {
            street: this.userDetails.residentialAddress?.streetAddress,
            suburb: this.userDetails.residentialAddress?.locality,
            state: this.userDetails.residentialAddress?.region,
            postcode: this.userDetails.residentialAddress?.postcode,
            country: this.userDetails.residentialAddress?.country,
          }
        : {};
    },
    afterpayPaymentIntent() {
      return this.$route.query.payment_intent;
    },
    isReturningFromAfterpay() {
      return !!this.afterpayPaymentIntent;
    },
    showAfterpay() {
      if (this.isReturningFromAfterpay) {
        return true;
      } else if (
        this.isUpdateCardDetails ||
        !this.userIsAustralian ||
        this.isShowingOnlySubscription
      ) {
        return false;
      } else {
        return this.expectedCostInCents / 100 >= 40;
      }
    },
    submitDisabled() {
      return !this.paymentMethod || this.paymentProcessing;
    },
    isBackButtonVisible() {
      return (
        (this.isCurrentScene('PRODUCTS_GIFT') && this.showProductSuggestions) ||
        (this.isCurrentAct('SUMMARY') && this.showProductSelection) ||
        (this.isCurrentAct('PAYMENT') && this.showSummary) ||
        (this.isCurrentAct('PAYMENT') && this.showProductSelection)
      );
    },
    isShowingOnlySubscription() {
      return this.showSubscriptionAutoRenewalInCheckout && this.isCartEmpty;
    },
    isZeroDollarCart() {
      return (
        this.$ff.cardlessCheckout() &&
        (this.isFreeCart || this.isShowingOnlySubscription)
      );
    },
    isCardlessCheckout() {
      const hasSubscription = this.isInCart('SUBSCRIPTION');
      const showingSubscription =
        hasSubscription || this.showSubscriptionAutoRenewalInCheckout;
      const doesNotRequireCard =
        !showingSubscription ||
        (showingSubscription && !this.subscriptionAutoRenewal);
      return this.isZeroDollarCart && doesNotRequireCard;
    },
    crumbs() {
      const crumbStates = [];
      if (!this.showSummary) {
        crumbStates.push({
          text: this.breadcrumbs.start,
          state: 'complete',
        });
      }
      if (!this.isUnlockAccount) {
        crumbStates.push({
          text: this.$t('components.checkout.addOns'),
          state: this.breadcrumbState('PRODUCT_SELECTION'),
        });
      }
      if (this.showSummary) {
        crumbStates.push({
          text: this.$t('components.checkout.summary'),
          state: this.breadcrumbState('SUMMARY'),
        });
      }
      if (!this.isCardlessCheckout) {
        crumbStates.push({
          text: this.$t('components.checkout.payment'),
          state: this.breadcrumbState('PAYMENT'),
        });
      }
      return crumbStates;
    },
  },
  watch: {
    currentAct(nextAct) {
      if (nextAct === 'PAYMENT' && !this.showAfterpay) {
        this.paymentMethod = 'stripe';
      }
    },
    discountCode(newDiscountCode) {
      this.setDiscountCode(newDiscountCode);
    },
  },
  async created() {
    await Promise.all([
      this.getCartItems(),
      this.refetchWill(),
      this.loadToolAndVaultItems(),
    ]);

    let filteredCheckoutItems;
    if (this.useCart) {
      filteredCheckoutItems = this.checkoutItems.filter(({ product }) =>
        this.canAddToCart(product)
      );
    } else {
      filteredCheckoutItems = this.checkoutItems;
      this.selectedItems = this.checkoutItems.filter(
        ({ type, product }) => type !== 'DEFAULT' && this.isInCart(product)
      );
    }

    const defaultItemsList = [];
    const giftedOnlyItemsList = [];
    const partnerItemsList = [];
    const giftItemsList = [];
    const suggestedItemsList = [];
    filteredCheckoutItems.forEach((checkoutItem) => {
      switch (checkoutItem.type) {
        case 'DEFAULT':
          defaultItemsList.push(checkoutItem);
          break;
        case 'GIFTED_ONLY':
          giftedOnlyItemsList.push(checkoutItem);
          break;
        case 'SUGGESTED':
          if (
            checkoutItem.product !== 'WILL' ||
            (checkoutItem.product === 'WILL' && !this.isInCart('WILL_TIER_TWO'))
          ) {
            suggestedItemsList.push({ ...checkoutItem, isGifted: false });
          }
          break;
        case 'PARTNER':
          partnerItemsList.push(checkoutItem);
          break;
        case 'GIFT':
          giftItemsList.push(checkoutItem);
          break;
      }
    });

    this.currentAct = 'LOADING';
    this.defaultItems = defaultItemsList;
    this.giftedOnlyItems = giftedOnlyItemsList;
    this.partnerItems = partnerItemsList;
    this.giftItems = giftItemsList;
    this.suggestedItems = suggestedItemsList;
    this.couponAllowed =
      !this.isGeneric && !this.isUpdateCardDetails && !this.isUnlockAccount;
    this.configureCheckoutType();
    this.hasAfterpayPaymentIntentClientSecret =
      !!this.$route.query.payment_intent_client_secret;

    if (this.hasAfterpayPaymentIntentClientSecret) {
      this.setCurrentAct('PAYMENT');
      this.paymentMethod = 'afterpay';
    } else if (this.showProductSelection) {
      this.setCurrentAct('PRODUCT_SELECTION');
      if (this.showProductSuggestions || this.showPartnerSelection) {
        this.setCurrentScene('PRODUCTS_SUGGESTED');
      } else if (this.showGiftSelection) {
        this.setCurrentScene('PRODUCTS_GIFT');
      }
    } else {
      this.setCurrentAct(this.showSummary ? 'SUMMARY' : 'PAYMENT');
      if (
        this.isUpdateCardDetails ||
        this.isUnlockAccount ||
        this.isShowingOnlySubscription
      ) {
        this.paymentMethod = 'stripe';
      }
    }
  },
  methods: {
    ...mapActions('cart', [
      'getCartItems',
      'setDiscountCode',
      'cardlessPayment',
    ]),
    ...mapActions('orders', ['getOrders', 'checkPurchasedItems']),
    ...mapActions('subscription', ['setAutoRenew']),
    ...mapActions('user-onboarding', ['loadToolAndVaultItems']),
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    setCurrentAct(act) {
      this.currentAct = act;
    },
    isCurrentAct(act) {
      return this.currentAct === act;
    },
    productSelectionNext(currentScene) {
      switch (currentScene) {
        case 'PRODUCTS_SUGGESTED': {
          if (this.showGiftSelection) {
            this.setCurrentScene('PRODUCTS_GIFT');
          } else if (this.showSummary) {
            this.currentAct = 'SUMMARY';
          } else {
            this.currentAct = 'PAYMENT';
          }
          break;
        }
        case 'PRODUCTS_GIFT': {
          if (this.showSummary) {
            this.currentAct = 'SUMMARY';
          } else {
            this.currentAct = 'PAYMENT';
          }
          break;
        }
      }
    },
    setCurrentScene(scene) {
      this.currentScene = scene;
    },
    isCurrentScene(scene) {
      return this.currentScene === scene;
    },
    applyCoupon(couponCode) {
      this.discountCode = couponCode;
    },
    breadcrumbState(act) {
      const actIndex = this.acts.indexOf(act);
      const currentActIndex = this.acts.indexOf(this.currentAct);
      if (actIndex === currentActIndex) {
        return 'active';
      } else if (actIndex < currentActIndex) {
        return 'complete';
      }
      return null;
    },
    selectPaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod;
    },
    isSelectedTab(paymentMethod) {
      return this.paymentMethod === paymentMethod;
    },
    tabAttributes(paymentMethod) {
      return {
        level: this.isSelectedTab(paymentMethod) ? 'primary' : 'secondary',
      };
    },
    setPaymentProcessing(isProcessing) {
      this.paymentProcessing = isProcessing;
    },
    submitPayment() {
      switch (this.paymentMethod) {
        case 'stripe': {
          this.$refs.stripeComponent.submitPayment();
          break;
        }
        case 'afterpay': {
          this.$refs.afterpayComponent.submitPayment();
          break;
        }
      }
    },
    pollForAfterpayOrder() {
      return pollUntilTruthy(async () => {
        await this.getOrders();
        return this.latestOrder.externalPaymentId === this.afterpayPaymentIntent
          ? this.latestOrder
          : false;
      });
    },
    async createOrder() {
      this.setCurrentAct('PROCESSING');
      if (!this.isUpdateCardDetails) {
        const isOrderCreationSuccessful = await (this.isReturningFromAfterpay
          ? this.pollForAfterpayOrder()
          : this.checkPurchasedItems(
              this.checkoutItemsWithPrices.map((item) => item.product)
            ));
        if (isOrderCreationSuccessful) {
          await Promise.all([
            this.sendPurchasedProductsTrackingEvents(),
            this.setDiscountCode(null),
            this.orderSetupSubscriptionAutoRenew(),
          ]);
        }
      } else if (this.isShowingOnlySubscription) {
        await this.setAutoRenew(true);
      }
      this.$emit('orderCreated');
    },
    async orderSetupSubscriptionAutoRenew() {
      if (this.isZeroDollarCart && this.subscriptionAutoRenewal) {
        await this.setAutoRenew(true);
      }
    },
    async getLatestOrder() {
      const userId = this.userId;

      const { data } = await this.$apollo.query({
        query: GET_ORDERS,
        fetchPolicy: 'no-cache',
        variables: {
          userId,
        },
        skip() {
          return !userId;
        },
      });

      return data.getOrders.sort(function (x, y) {
        return Number(y.createdAt) - Number(x.createdAt);
      })[0];
    },
    async sendPurchasedProductsTrackingEvents() {
      const latestOrder = await this.getLatestOrder();
      const items = [];
      const currency = latestOrder.currency.toUpperCase();
      const additionalProps = {};
      for (const orderItem of latestOrder.orderItems) {
        items.push({
          item_id: orderItem.product,
          price: orderItem.valueInCents / 100,
          quantity: 1,
        });
        additionalProps[`${orderItem.product.toLowerCase()}_purchased_for`] =
          orderItem.valueInCents / 100;
      }
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'purchase',
        props: {
          order_id: latestOrder.id,
          currency,
          value: latestOrder.valueInCents / 100,
          items,
          ...additionalProps,
        },
      });
    },
    setPreviousScene() {
      if (this.isCurrentAct('SUMMARY') && this.showProductSelection) {
        this.setCurrentAct('PRODUCT_SELECTION');
      } else if (this.isCurrentAct('PAYMENT')) {
        if (this.showSummary) {
          this.setCurrentAct('SUMMARY');
        } else if (this.showProductSelection) {
          this.setCurrentAct('PRODUCT_SELECTION');
        }
      } else if (
        this.isCurrentScene('PRODUCTS_GIFT') &&
        this.showProductSuggestions
      ) {
        this.setCurrentScene('PRODUCTS_SUGGESTED');
      }
    },
    configureCheckoutType() {
      if (this.isUpdateCardDetails || this.isShowingOnlySubscription) {
        this.stripeActionType = 'update';
      } else {
        this.stripeActionType = 'checkout';
      }
    },
    confirmCheckout() {
      this.configureCheckoutType();
      this.setCurrentAct('PAYMENT');
      this.emitSubscriptionAutoRenewalEvent();
    },
    async confirmCardlessCheckout() {
      if (
        this.showSubscriptionAutoRenewalInCheckout ||
        this.isInCart('SUBSCRIPTION')
      ) {
        this.subscriptionOptOutModalIsVisible = true;
        this.emitSubscriptionAutoRenewalEvent();
      } else {
        await this.submitCardlessPayment();
      }
    },
    emitSubscriptionAutoRenewalEvent() {
      if (
        (this.isInCart('SUBSCRIPTION') ||
          this.showSubscriptionAutoRenewalInCheckout) &&
        this.isZeroDollarCart
      ) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'click',
          props: {
            element_id: 'checkbox_subscription-auto-renewal',
            page_path: this.$route.path,
            click_value: this.subscriptionAutoRenewal,
          },
        });
      }
    },
    async submitCardlessPayment() {
      if (this.isShowingOnlySubscription && !this.subscriptionAutoRenewal) {
        this.$router.push({
          path: this.localePath(`/checkout/cart/thank-you`),
        });
        return;
      }
      try {
        this.setPaymentProcessing(true);
        await this.cardlessPayment({
          productsWithCustomPricesToken: this.productsWithCustomPricesToken,
        });
        await this.createOrder();
      } catch (error) {
        this.$nuxt.$emit('snackbar', {
          icon: 'error',
          type: 'error',
          placement: 'top-right',
          relative: 'cart',
          text: formatError(error.message),
        });
        await this.getCartItems();
      } finally {
        this.setPaymentProcessing(false);
      }
    },
    openWillTierTwoUpgradeModal() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'click',
        props: {
          element_id: `checkout-summary__open-upgrade-modal`,
          page_path: this.$route.path,
        },
      });
      this.isWillTierTwoUpgradeModalVisible = true;
    },
    openWillTierTwoDowngradeModal() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'click',
        props: {
          element_id: `checkout-summary__close-upgrade-modal`,
          page_path: this.$route.path,
        },
      });
      this.isWillTierTwoDowngradeModalVisible = true;
    },
  },
};
