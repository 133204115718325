
export default {
  name: 'AppConfirm',
  data() {
    return {
      title: '',
      message: '',
      callback: null,
      visible: false,
    };
  },
  methods: {
    close(action) {
      this.visible = false;
      this.callback(action);
    },
  },
};
