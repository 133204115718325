
import { mapGetters, mapActions } from 'vuex';
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';
import confirm from '@/mixins/confirm';
import { DISCOUNT_OPTIONS } from '~/utilities/constants';

export default {
  name: 'PagesAffiliateAdminCouponsId',
  mixins: [confirm],
  layout: 'admin',
  apollo: {
    partnerships: {
      query: GET_PARTNERSHIPS_QUERY,
      fetchPolicy: 'network-only',
      update: (data) => data?.getPartnerships,
    },
  },
  data() {
    return {
      loading: false,
      coupon: null,
    };
  },
  computed: {
    ...mapGetters('product', ['products']),
    discountOptions() {
      return DISCOUNT_OPTIONS.map((option) => ({
        value: option,
        text: this.$t(`commons.discountType.${option}`),
      }));
    },
    partnershipsOptions() {
      return (this.partnerships || []).map((partnership) => {
        return {
          text: partnership.name,
          value: partnership.id,
        };
      });
    },
    couponId() {
      return this.$route.params.id;
    },
    couponRows() {
      return [
        ['ID', this.coupon?.id],
        ['Code', this.coupon?.code],
        ['Single Use', this.coupon?.singleUse],
      ];
    },
    discountProducts() {
      const allDiscountProductKey = [
        { code: 'WILL', label: 'Will' },
        { code: 'WILL_TIER_TWO', label: 'Will Tier Two' },
        { code: 'POA', label: 'POA' },
        { code: 'PARTNER_WILL', label: 'Partner Will' },
      ];

      const discountProductCodes = new Set(
        allDiscountProductKey.map((product) => product.code)
      );

      const allDiscountProducts = this.products
        .filter((product) => discountProductCodes.has(product.code))
        .map((product) => {
          const { label } = allDiscountProductKey.find(
            (p) => p.code === product.code
          );
          return { id: product.id, code: product.code, label };
        });

      return allDiscountProductKey.map((productKey) => {
        const product = allDiscountProducts.find(
          (p) => p.code === productKey.code
        );
        const productHasDiscount = this.coupon.discountProducts.find(
          (discountProduct) => discountProduct.productId === product?.id
        );

        return {
          code: productKey.code,
          label: product?.label,
          enabled: !!productHasDiscount,
          type: productHasDiscount?.discountType || 'PERCENTAGE',
          factor:
            productHasDiscount?.discountType === 'FIXED'
              ? productHasDiscount?.discountFactor / 100 || 0
              : productHasDiscount?.discountFactor || 0,
        };
      });
    },
  },
  async mounted() {
    this.coupon = await this.getCouponById(this.couponId);
  },
  methods: {
    ...mapActions('admin/coupons', [
      'getCouponById',
      'updateCoupon',
      'archiveCoupon',
    ]),
    setCouponActive(coupon) {
      this.$confirm(
        {
          title: `${
            coupon.active ? 'Activate' : 'Deactivate'
          } ${coupon.code.toUpperCase()}?`,
          message: `Are you sure you want to ${
            coupon.active ? 'activate' : 'deactivate'
          } this coupon?`,
        },
        (action) => {
          if (action) {
            this.updateCoupon(coupon);
          } else {
            coupon.active = !coupon.active;
          }
        }
      );
    },
    showArchiveCouponModal() {
      this.$confirm(
        {
          title: `Archive ${this.coupon.code.toUpperCase()}?`,
          message: 'Are you sure you want to archive this coupon?',
        },
        (action) => {
          if (action) {
            this.archiveCoupon(this.coupon.id);
          }
        }
      );
    },
    updateProductSelection(key, value) {
      this.products[key] = value;
      const productEntries = Object.entries(this.products);
      this.coupon.products = productEntries
        .filter((el) => el[1])
        .map((el) => el[0]);
    },
    async updateCouponData() {
      this.loading = true;
      await this.updateCoupon(this.coupon);
      this.loading = false;
    },
  },
};
