
import { mapActions, mapGetters } from 'vuex';
import { user, will, error } from '@/mixins/apollo';

import SUBMIT_POA from '@/graphql/queries/SubmitPoa';

export default {
  name: 'PagesAffiliateCheckoutCartIndex',
  mixins: [user, will],
  beforeRouteLeave(to, _from, next) {
    if (
      !this.confirmLeavingCheckout &&
      !to.path.includes('/checkout/cart') &&
      !this.showSubscriptionAutoRenewalInCheckout
    ) {
      this.openExitModal();
      next(false);
    } else {
      next();
    }
  },
  data() {
    return {
      loaded: false,
      showExitModal: false,
      confirmLeavingCheckout: false,
    };
  },
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    ...mapGetters('application/affiliate', ['features']),
    ...mapGetters('directory-person', [
      'userDetails',
      'userIsAustralian',
      'userPartner',
    ]),
    ...mapGetters('cart', [
      'cartItemCodes',
      'isInCart',
      'showSubscriptionAutoRenewalInCheckout',
    ]),
    ...mapGetters('orders', ['isLatestProductPurchased']),
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('progress', { willProgress: 'will', poaProgress: 'poa' }),
    isUnlockAccountCheckout() {
      return this.isInCart('UNLOCK') || this.isInCart('RECENT_UNLOCK');
    },
    partnerName() {
      return (
        this.userPartner?.fullName ?? this.$t('texts.sentences.yourPartner')
      );
    },
    productSuggestedHeader() {
      if (this.$ff.combinedFreeWillCheckoutSubmission()) {
        return this.$t('pages.checkout.firstNameOptions', {
          firstName: this.userDetails.firstName,
        });
      }
      return this.$t('pages.checkout.firstNameOpportunity', {
        firstName: this.userDetails.firstName,
      });
    },
    partnerProductHeader() {
      if (this.showSubscriptionAutoRenewalInCheckout) {
        return this.$t('pages.checkout.protectYourPartner', {
          firstName: this.userDetails.firstName,
        });
      }
      return this.$t('pages.checkout.partnerEstatePlan');
    },
    checkoutItems() {
      if (this.isUnlockAccountCheckout) {
        return [];
      }
      const items = [
        {
          product: 'WILL',
          type: 'SUGGESTED',
        },
      ];
      if (this.userIsAustralian) {
        if (this.features.powerOfAttorneyEnabled) {
          items.push({
            product: 'POA',
            type: 'SUGGESTED',
          });
          if (this.willMeta.has_partner == null || this.willMeta.has_partner) {
            items.push({
              product: 'PARTNER_POA',
              type: 'PARTNER',
            });
          }
        }
        if (
          this.features.willTierTwoEnabled &&
          this.cartItemCodes.includes('WILL')
        ) {
          items.push({
            product: 'WILL_TIER_TWO',
            type: 'SUGGESTED',
          });
        }
      }
      if (this.willMeta.has_partner) {
        items.push({
          product: 'PARTNER_WILL',
          type: 'PARTNER',
        });
      }
      return items;
    },
  },
  watch: {
    willMeta() {
      this.loaded = true;
    },
  },
  mounted() {
    this.setIsProcessingOrder(false);
  },
  methods: {
    ...mapActions('application/affiliate', ['resetAffiliate']),
    ...mapActions('poa', { getPoaStatus: 'getStatus' }),
    ...mapActions('will', { submitWillAction: 'submitWill' }),
    ...mapActions('product-prices', ['getProductPrices']),
    ...mapActions('orders', ['setIsProcessingOrder']),
    async submitProductsWithSubmission() {
      await Promise.all([
        this.submitWill(),
        this.submitPoa(),
        this.getProductPrices(),
      ]);
      this.goToThanks();
    },
    async submitWill() {
      if (
        (this.isLatestProductPurchased('WILL') ||
          this.isLatestProductPurchased('WILL_TIER_TWO')) &&
        this.willStatus === 'IN_PROGRESS' &&
        this.willProgress.complete
      ) {
        try {
          await this.submitWillAction();
          await this.updateWillMeta();
          await this.refetchWill();
        } catch (error) {
          console.error(
            `Order was successful but will submission failed: ${error}`
          );
        }
      }
    },
    async submitPoa() {
      if (this.isLatestProductPurchased('POA') && this.poaProgress.complete) {
        try {
          await this.$apollo.mutate({
            mutation: SUBMIT_POA,
            variables: {
              id: this.poaId,
            },
            result() {
              this.getPoaStatus();
            },
            error,
          });
        } catch (error) {
          console.error(
            `Order was successful but POA submission failed: ${error}`
          );
        }
      }
    },
    openExitModal() {
      this.showExitModal = true;
    },
    closeExitModal() {
      this.showExitModal = false;
    },
    confirmLeave() {
      this.confirmLeavingCheckout = true;
    },
    skipExitModal(path) {
      this.confirmLeave();
      this.$router.push({
        path,
      });
    },
    goToThanks() {
      this.resetAffiliate();
      this.$router.push({
        path: this.localePath(`/checkout/cart/thank-you`),
      });
    },
  },
};
