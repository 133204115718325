
export default {
  props: {
    value: {
      default: () => ({ type: '', description: '' }),
      type: Object,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    placeholders: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    autoSave: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type: {
      get() {
        return this.value.type;
      },
      set(newValue) {
        this.$emit('input', { ...this.value, type: newValue });
      },
    },
    description: {
      get() {
        return this.value.description;
      },
      set(newValue) {
        this.$emit('input', { ...this.value, description: newValue });
      },
    },
  },
  methods: {
    save(validate) {
      if (this.autoSave) {
        this.$emit('save', validate);
      }
    },
  },
};
