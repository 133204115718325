
const EVENTS = [
  'play',
  'pause',
  'ended',
  'loadeddata',
  'timeupdate',
  'fullscreenchange',
];

export default {
  name: 'AppVideoPlayer',
  props: {
    value: {
      default: null,
      type: Object,
    },
    width: {
      default: '100%',
      type: String,
    },
    height: {
      default: '100%',
      type: String,
    },
    videoName: {
      default: null,
      type: String,
    },
    videoUrl: {
      required: true,
      type: String,
    },
    subtitleUrl: {
      default: null,
      type: String,
    },
    thumbnailUrl: {
      default: null,
      type: String,
    },
    showControls: {
      default: true,
      type: Boolean,
    },
    startingVolume: {
      default: 1,
      type: Number,
    },
    autoplay: {
      default: false,
      type: Boolean,
    },
    loop: {
      default: false,
      type: Boolean,
    },
    onPlay: {
      default: null,
      type: Function,
    },
    onPause: {
      default: null,
      type: Function,
    },
    onExpand: {
      default: null,
      type: Function,
    },
    onCompletion: {
      default: null,
      type: Function,
    },
  },
  data() {
    return {
      modelValue: this.value || {
        currentTime: 0,
        duration: 0,
        currentTimeString: '0',
        durationString: '0',
        expanded: false,
        played: false,
      },
    };
  },
  mounted() {
    this.$refs.videoPlayer.volume = this.startingVolume;

    this.bindVideoEvents();
  },
  methods: {
    bindVideoEvents() {
      EVENTS.forEach((event) => this.bindVideoEvent(event));
    },
    bindVideoEvent(eventName) {
      const videoPlayer = this.$refs.videoPlayer;
      videoPlayer.addEventListener(
        eventName,
        (event) => {
          switch (eventName) {
            case 'loadeddata':
              this.modelValue.duration = videoPlayer.duration;
              this.modelValue.durationString = this.convertTimeToDurationString(
                this.modelValue.duration
              );
              break;
            case 'play':
              this.modelValue.isPlayed = true;
              this.emitVideoTrackingEvent('play_video');
              this.$emit('onPlay', event);
              break;
            case 'pause':
              this.emitVideoTrackingEvent('pause_video');
              this.$emit('onPause', event);
              break;
            case 'ended':
              this.emitVideoTrackingEvent('complete_video');
              this.$emit('onCompletion', event);
              break;
            case 'fullscreenchange':
              this.modelValue.isExpanded =
                document.fullscreenElement?.nodeName === 'VIDEO';

              if (this.modelValue.isExpanded) {
                this.emitVideoTrackingEvent('expand_video');
                this.$emit('onExpand', event);
              }
              break;
            case 'timeupdate':
              this.modelValue.currentTime = event.target.currentTime;
              this.modelValue.currentTimeString =
                this.convertTimeToDurationString(this.modelValue.currentTime);
              break;
            default:
              break;
          }
          this.$emit('input', this.modelValue);
        },
        true
      );
    },
    convertTimeToDurationString(seconds) {
      return [parseInt((seconds / 60) % 60, 10), parseInt(seconds % 60, 10)]
        .join(':')
        .replace(/\b(\d)\b/g, '0$1');
    },
    emitVideoTrackingEvent(eventName) {
      if (this.videoName) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: eventName,
          props: {
            video_name: this.videoName,
            page_path: this.$route.path,
            ...(eventName === 'pause_video' && {
              duration: this.modelValue.currentTimeString,
            }),
          },
        });
      }
    },
  },
};
