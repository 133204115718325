
export default {
  name: 'AppWillProductAddOn',
  props: {
    actionButtonIsDisabled: {
      default: null,
      type: Boolean,
    },
    product: {
      default: null,
      type: String,
    },
    buttonText: {
      default: null,
      type: String,
    },
    informationModalText: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      addonIsVisible: true,
    };
  },
  methods: {
    hideAddon() {
      this.addonIsVisible = false;
      this.$emit('addonClosed');
    },
  },
};
