
export default {
  name: 'WpSteps',
  props: {
    steps: {
      type: Array,
      default: null,
    },
    activeStep: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    isActive(step) {
      return this.activeStep === step;
    },
    getItemClass(index) {
      return [
        'p-steps-item',
        {
          'p-highlight p-steps-current': this.isActive(index),
        },
      ];
    },
    stepClass(routerProps) {
      return [
        'p-menuitem-link',
        {
          'router-link-active': routerProps && routerProps.isActive,
          'router-link-active-exact':
            this.exact && routerProps && routerProps.isExactActive,
        },
      ];
    },
  },
};
