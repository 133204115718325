import { render, staticRenderFns } from "./WillTierTwoUpgradeModal.vue?vue&type=template&id=1482b2c9"
import script from "./WillTierTwoUpgradeModal.vue?vue&type=script&lang=js"
export * from "./WillTierTwoUpgradeModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppBox: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Box.vue').default,AppAccordionPanel: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/AccordionPanel.vue').default,WpButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/Button.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,WpClickTracker: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/ClickTracker.vue').default,AppModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Modal.vue').default})
