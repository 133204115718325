
import { mapActions } from 'vuex';
import ADD_COUPON_MUTATION from '@/graphql/mutations/AddCoupon';
import CREATE_BULK_COUPON_MUTATION from '@/graphql/mutations/CreateBulkCoupon';
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';

export default {
  name: 'AppAdminCouponModal',
  props: {
    bulkCreate: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    partnerships: {
      query: GET_PARTNERSHIPS_QUERY,
      fetchPolicy: 'network-only',
      update: (data) => data?.getPartnerships,
    },
  },
  data() {
    return {
      ADD_COUPON_MUTATION,
      CREATE_BULK_COUPON_MUTATION,
      loading: false,
      code: '',
      description: '',
      countryCode: 'AU',
      singleUse: false,
      quantity: 1,
      partnershipSlug: null,
      products: {
        WILL: {
          code: 'WILL',
          enabled: false,
          label: 'Will',
          type: 'PERCENTAGE',
          factor: 0,
        },
        WILL_TIER_TWO: {
          code: 'WILL_TIER_TWO',
          enabled: false,
          label: 'Will Tier Two',
          type: 'PERCENTAGE',
          factor: 0,
        },
        POA: {
          code: 'POA',
          enabled: false,
          label: 'POA',
          type: 'PERCENTAGE',
          factor: 0,
        },
        PARTNER_WILL: {
          code: 'PARTNER_WILL',
          enabled: false,
          label: 'Partner Will',
          type: 'PERCENTAGE',
          factor: 0,
        },
      },
    };
  },
  computed: {
    partnershipsOptions() {
      return (this.partnerships || []).map((partnership) => {
        return {
          text: partnership.name,
          value: partnership.slug,
        };
      });
    },
    title() {
      return this.bulkCreate
        ? this.$t('components.couponModal.bulkCreateCoupons')
        : this.$t('components.couponModal.addACoupon');
    },
    variables() {
      const discountProducts = Object.values(this.products)
        .filter((product) => product.enabled)
        .map((product) => ({
          productCode: product.code,
          discountType: product.type,
          discountFactor:
            product.type === 'FIXED' ? product.factor * 100 : product.factor,
        }));
      return {
        code: this.bulkCreate ? undefined : this.code,
        description: this.description,
        countryCode: this.bulkCreate ? this.countryCode : undefined,
        singleUse: this.bulkCreate ? this.singleUse : undefined,
        quantity: this.bulkCreate ? Number.parseInt(this.quantity) : undefined,
        partnershipSlug: this.bulkCreate ? this.partnershipSlug : undefined,
        discountProducts,
      };
    },
  },
  methods: {
    ...mapActions('admin/coupons', ['refetchCoupons']),
    close() {
      this.$emit('closeModal');
    },
    update() {
      this.refetchCoupons();
    },
  },
};
