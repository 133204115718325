
import { countryCallingCodes } from '@/utilities/constants';
export default {
  name: 'AppPhoneControl',
  props: {
    disabled: {
      default: () => [],
      type: Array,
    },
    value: {
      default: null,
      type: Object,
    },
    required: {
      default: false,
      type: Boolean,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    rules() {
      const rules = [];
      if (this.required) {
        rules.push('required');
      }
      if (['+61', '+64'].includes(this.countryCode)) {
        rules.push('phone');
      } else {
        rules.push('phoneInternational');
      }
      return rules.join('|');
    },
    countryOptions() {
      return countryCallingCodes.map((countryCallingCode) => {
        return {
          value: countryCallingCode.code,
          text: `${countryCallingCode.code} ${countryCallingCode.flags}`,
          flags: countryCallingCode.flags,
          code: countryCallingCode.code,
          countries: countryCallingCode.countries.join(', '),
        };
      });
    },
    countryCode: {
      get() {
        if (!this.value) return null;
        return this.value.prefix;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, prefix: value });
      },
    },
    localNumber: {
      get() {
        if (!this.value) return null;
        return this.value.value;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, value });
      },
    },
  },
};
