
import { mapActions, mapGetters } from 'vuex';
import CREATE_INSTITUTION_MUTATION from '@/graphql/mutations/CreateInstitution';

export default {
  name: 'AppNewInstitutionModal',
  data: () => ({
    CREATE_INSTITUTION_MUTATION,
    formData: {
      name: null,
      type: null,
    },
  }),
  computed: {
    ...mapGetters('admin/institutions', ['institutionsTypes']),
    variables() {
      return {
        name: this.formData.name,
        type: this.type,
        archived: false,
      };
    },
    typeSelectedIsOther() {
      return this.formData.type === 'Other';
    },
    type() {
      return this.typeSelectedIsOther
        ? this.formData.typeOther
        : this.formData.type;
    },
  },
  mounted() {
    this.getInstitutionsTypes();
  },
  methods: {
    ...mapActions('admin/institutions', ['getInstitutionsTypes']),
    close() {
      this.$emit('close');
    },
    update(store, res) {
      const createdInstitution = res.data.createInstitution;
      this.$emit('institutionAdded', createdInstitution);
    },
  },
};
