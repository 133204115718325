
export default {
  name: 'AppFileControl',
  props: {
    accept: {
      type: String,
      default: null,
    },
    filesType: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    filesChange() {
      this.$emit('filesChange', this.$refs.fileControl.files, this.filesType);
    },
  },
};
